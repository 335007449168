/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Heading, SimpleGrid } from 'pouncejs';
import without from 'lodash/without';
import { LogTypeAccessKind, Permission } from 'Generated/schema';
import { useFormikContext } from 'formik';
import map from 'lodash/map';
import { PermissionMapping } from 'Source/constants';
import groupBy from 'lodash/groupBy';
import { expandPermissions } from 'Helpers/utils';
import PermissionItem from './PermissionItem';
import LogTypeAccessPanel from './LogTypeAccessPanel';

interface PermissionGridProps {
  permissionMapping: Partial<PermissionMapping>;
  withLogTypeAccessPanel?: boolean;
}

const PermissionGrid: React.FC<PermissionGridProps> = ({
  withLogTypeAccessPanel = false,
  permissionMapping,
}) => {
  const {
    setFieldValue,
    values: { permissions },
  } = useFormikContext<{ permissions: Permission[] }>();
  const permissionsByGrouping = React.useMemo(
    () => groupBy(Object.values(permissionMapping), p => p.grouping),
    [permissionMapping]
  );

  React.useEffect(() => {
    const expandedPermissions = expandPermissions(permissions);
    if (expandedPermissions.length > permissions.length) {
      setFieldValue('permissions', expandedPermissions);
    }
  }, [permissions, setFieldValue]);

  const handlePermissionSelection = React.useCallback(
    (id: Permission) =>
      setFieldValue(
        'permissions',
        permissions.includes(id) ? without(permissions, id) : [...permissions, id]
      ),
    [setFieldValue, permissions]
  );

  const handleDataReadPermissionSelection = React.useCallback(
    (id: Permission) => {
      if (permissions.includes(Permission.DataAnalyticsModify)) {
        return;
      }
      // Deny/allow access to all log types when the data read permission is toggled
      setFieldValue(
        'logTypeAccessKind',
        permissions.includes(id) ? LogTypeAccessKind.DenyAll : LogTypeAccessKind.AllowAll
      );
      handlePermissionSelection(id);
    },
    [permissions, setFieldValue, handlePermissionSelection]
  );

  return (
    <Box as="section" mt={10}>
      {map(permissionsByGrouping, (permissionGrouping, groupingName) => (
        <Box borderBottom="1px solid" borderColor="navyblue-300" mb={6} key={groupingName}>
          <Heading as="h2" mb={5}>
            {groupingName}
          </Heading>
          <SimpleGrid columns={2} rowGap={8} mb={8}>
            {permissionGrouping.map(permission => {
              const isSelected = permissions.includes(permission.id);
              if (permission.id === Permission.DataAnalyticsRead && withLogTypeAccessPanel) {
                return (
                  <Box key={permission.id} gridColumn={isSelected ? 'span 2' : 'auto'}>
                    <PermissionItem
                      id={permission.id}
                      description={permission.description}
                      title={permission.title}
                      checked={isSelected}
                      onSelect={handleDataReadPermissionSelection}
                    >
                      {isSelected && (
                        <Box mt={6}>
                          <LogTypeAccessPanel />
                        </Box>
                      )}
                    </PermissionItem>
                  </Box>
                );
              }
              return (
                <PermissionItem
                  key={permission.id}
                  id={permission.id}
                  description={permission.description}
                  title={permission.title}
                  checked={isSelected}
                  onSelect={handlePermissionSelection}
                />
              );
            })}
          </SimpleGrid>
        </Box>
      ))}
    </Box>
  );
};

export default PermissionGrid;
