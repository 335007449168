/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import {
  Permission,
  Severity,
  DestinationTypeEnum,
  DetectionTypeEnum,
  DataLakeQueryStatus,
  AlertType,
  LogPullerTypeEnum,
} from 'Generated/schema';
import slackLogo from 'Assets/slack-minimal-logo.svg';
import msTeamsLogo from 'Assets/ms-teams-minimal-logo.svg';
import opsgenieLogo from 'Assets/opsgenie-minimal-logo.svg';
import jiraLogo from 'Assets/jira-minimal-logo.svg';
import githubLogo from 'Assets/github-minimal-logo.svg';
import pagerDutyLogo from 'Assets/pagerduty-minimal-logo.svg';
import snsLogo from 'Assets/aws-sns-minimal-logo.svg';
import sqsLogo from 'Assets/aws-sqs-minimal-logo.svg';
import asanaLogo from 'Assets/asana-minimal-logo.svg';
import customWebhook from 'Assets/custom-webhook-minimal-logo.svg';
import { Theme } from 'pouncejs';

// read the NodeJS-injected configuration and parse it back from JSON -> JS object. During development,
// the NodeJS server doesn't contribute in that at all, but instead the HtmlWebpackLoader injects
// them directly. There is a long-run inconsistency with EJS templates and webpack's ejs-loader,
// where the "symbol" for unescaped entities is different when using ejs-loader (development) and
// when you are just using EJS templates without a webpack loader (production). Just because we
// don't know whether we arrived here from development or production, we are doing the `&quot;`
// replace you see to make sure we convert an escaped entity back to its original value
export const PANTHER_CONFIG = Object.freeze(
  JSON.parse(
    document
      .getElementById('__PANTHER_CONFIG__')
      .innerHTML.toString()
      .replace(/&quot;/g, '"')
  )
);

export const __DEV__ = process.env.NODE_ENV !== 'production';

export const ENABLE_DATA_EXPLORER_TRUNCATION =
  PANTHER_CONFIG.ENABLE_DATA_EXPLORER_TRUNCATION === 'true';

export const ENABLE_REPLAYS = PANTHER_CONFIG.ENABLE_REPLAYS === 'true';
export const ENABLE_REPORTS = PANTHER_CONFIG.ENABLE_REPORTS === 'true';
export const ENABLE_DETECTION_FILTERS = PANTHER_CONFIG.ENABLE_DETECTION_FILTERS === 'true';
export const ENABLE_ALERTS_GSI_LIST = PANTHER_CONFIG.ALERTS_GSI_LIST_ENABLED === 'true';
export const ENABLE_WORKDAY_PULLER = PANTHER_CONFIG.ENABLE_WORKDAY_PULLER === 'true';
export const ENABLE_HOLDING_TANK = PANTHER_CONFIG.ENABLE_HOLDING_TANK === 'true';

export enum LogTransportMethodsEnum {
  's3' = 'aws-s3',
  'eventbridge' = 'amazon-eventbridge',
  'logPulling' = 'log-pulling',
  'sqs' = 'aws-sqs',
  'cloudwatch' = 'aws-cloudwatch-logs',
  'gcs' = 'gcp-gcs',
}

export type LogSourceType =
  | Exclude<LogTransportMethodsEnum, LogTransportMethodsEnum.logPulling>
  | LogPullerTypeEnum;

export const AWS_ACCOUNT_ID_REGEX = new RegExp('^\\d{12}$');

// Matches a full s3 object path, including the file name and extension.
// ex. 's3://bucket/path/to/file.txt'
export const S3_OBJECT_PATH_REGEX = new RegExp('^s3://([^/]+)/(.+\\.([^/]+))$');

export const S3_BUCKET_NAME_REGEX = new RegExp(
  '(?=^.{3,63}$)(^(([a-z0-9]|[a-z0-9][a-z0-9\\-]*[a-z0-9])\\.)*([a-z0-9]|[a-z0-9][a-z0-9\\-]*[a-z0-9])$)'
);

export const INCLUDE_DIGITS_REGEX = new RegExp('(?=.*[0-9])');

export const INCLUDE_LOWERCASE_REGEX = new RegExp('(?=.*[a-z])');

export const SOURCE_LABEL_REGEX = new RegExp('^[a-zA-Z0-9- ]+$');

export const BUS_NAME_REGEX = new RegExp('^[A-Za-z0-9_.,/\\-]+$');

export const ROLE_ARN_REGEX = new RegExp('^arn:(aws|aws-cn|aws-us-gov):iam::\\d{12}:role\\/\\S+$');

export const INCLUDE_UPPERCASE_REGEX = new RegExp('(?=.*[A-Z])');

export const INCLUDE_SPECIAL_CHAR_REGEX = new RegExp('[^\\d\\sA-Za-z]');

export const CHECK_IF_HASH_REGEX = new RegExp('[a-f0-9]{32}');

export const ARN_REGEX = new RegExp('arn:aws:\\S+:\\d+:\\w+/\\S+');

export const DOMAIN_REGEX = new RegExp('^[a-z0-9]+([-.]{1}[a-z0-9]*)+[.[a-z]{2,5}]?$');

export const SUBDOMAIN_REGEX = new RegExp('^[a-z0-9\\-]+$');

// Allows any string that DOESN'T contain the following characters: < > & "
export const NON_HTML_ENTITY_REGEX = new RegExp('^[^<>&"]+$');

export const DEFAULT_POLICY_FUNCTION =
  'def policy(resource):\n    # Return False if the resource is non-compliant, which will trigger alerts.\n    return True';

export const DEFAULT_RULE_FUNCTION =
  'def rule(event):\n    # Return True to match the log event and trigger an alert.\n    return False';

export const SCHEDULED_RULE_FUNCTION =
  'def rule(event):\n    # (Optional) Return True to match the log event and trigger an alert.\n    # If not declared, this defaults to True and creates alerts on all events in the query result.\n    return True';

export const DEFAULT_TITLE_FUNCTION =
  "def title(event):\n    # (Optional) Return a string which will be shown as the alert title.\n    # If no 'dedup' function is defined, the return value of this method will act as deduplication string.\n    return ''";

export const DEFAULT_DEDUP_FUNCTION =
  "# def dedup(event):\n    #  (Optional) Return a string which will be used to deduplicate similar alerts.\n    # return ''";

export const DEFAULT_ALERT_CONTEXT_FUNCTION =
  "# def alert_context(event):\n    #  (Optional) Return a dictionary with additional data to be included in the alert sent to the SNS/SQS/Webhook destination\n    # return {'key':'value'}";

export const RESOURCE_TYPES = [
  'AWS.ACM.Certificate',
  'AWS.CloudFormation.Stack',
  'AWS.CloudTrail',
  'AWS.CloudTrail.Meta',
  'AWS.CloudWatch.LogGroup',
  'AWS.Config.Recorder',
  'AWS.Config.Recorder.Meta',
  'AWS.DynamoDB.Table',
  'AWS.EC2.AMI',
  'AWS.EC2.Instance',
  'AWS.EC2.NetworkACL',
  'AWS.EC2.SecurityGroup',
  'AWS.EC2.Volume',
  'AWS.EC2.VPC',
  'AWS.ECS.Cluster',
  'AWS.EKS.Cluster',
  'AWS.ELBV2.ApplicationLoadBalancer',
  'AWS.GuardDuty.Detector',
  'AWS.GuardDuty.Detector.Meta',
  'AWS.IAM.Group',
  'AWS.IAM.Policy',
  'AWS.IAM.Role',
  'AWS.IAM.RootUser',
  'AWS.IAM.User',
  'AWS.KMS.Key',
  'AWS.Lambda.Function',
  'AWS.PasswordPolicy',
  'AWS.RDS.Instance',
  'AWS.Redshift.Cluster',
  'AWS.S3.Bucket',
  'AWS.WAF.Regional.WebACL',
  'AWS.WAF.WebACL',
] as const;

export const SlackLogTypesEnterprise = ['Slack.AuditLogs'];
export const SlackLogTypesStandard = ['Slack.AccessLogs', 'Slack.IntegrationLogs'];

export enum SlackPlansEnum {
  'enterprise' = 'Enterprise Grid',
  'standard' = 'Standard/Plus',
}

export enum GoogleAppsEnum {
  'access_transparency' = 'Access Transparency',
  'admin' = 'Admin Activity',
  'login' = 'Login Activity',
  'calendar' = 'Calendar',
  'chat' = 'Chat Activity',
  'drive' = 'Drive',
  'groups' = 'Groups Activity',
  'groups_enterprise' = 'Enterprise Groups Activity',
  'jamboard' = 'Jamboard',
  'meet' = 'Meet',
  'mobile' = 'Mobile Audit',
  'rules' = 'Rules Activity',
  'saml' = 'SAML Activity',
  'token' = 'Token Activity',
  'user_accounts' = 'User Accounts Activity',
}

// Section with generic and specific doc URLs
export const AWS_REGIONS = [
  'us-east-1',
  'us-east-2',
  'us-west-1',
  'us-west-2',
  'af-south-1',
  'ap-east-1',
  'ap-south-1',
  'ap-northeast-1',
  'ap-northeast-2',
  'ap-northeast-3',
  'ap-southeast-1',
  'ap-southeast-2',
  'ca-central-1',
  'cn-north-1',
  'cn-northwest-1',
  'eu-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-west-3',
  'eu-south-1',
  'eu-north-1',
  'me-south-1',
  'sa-east-1',
  'us-gov-east-1',
  'us-gov-west-1',
] as const;

export const PANTHER_DOCS_LINK = `https://docs.runpanther.io`;

export const PANTHER_ENTERPRISE_SUPPORT_LINK =
  'https://panther-labs.atlassian.net/servicedesk/customer/portal/2/group/-1';

export const DATA_TRANSPORTS_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/data-transports`;
export const ANALYSIS_UPLOAD_DOC_URL = `${PANTHER_DOCS_LINK}/writing-detections/panther-analysis-tool#uploading-to-panther`;
export const S3_BUCKET_DETAILS_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/data-transports/s3#step-1-enter-the-bucket-details`;
export const S3_ROLE_POLICY_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/data-transports/s3#creating-an-iam-role-manually-or-with-other-automation`;
export const CLOUD_ACCOUNTS_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/setup-cloud-accounts`;
export const CWL_MANUALLY_IAM_ROLE_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/data-transports/cwl-source#creating-an-iam-role-manually-or-with-other-automation`;
export const LOG_ONBOARDING_SNS_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/data-transports/s3#set-up-notifications-of-new-data`;
export const GCS_DATA_ONBOARDING_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/data-transports/gcs`;
export const PRIVACY_DOC_URL = `${PANTHER_DOCS_LINK}/help/security-privacy#privacy`;
export const SAAS_ONBOARDING_ASANA_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/saas-logs/asana`;
export const SAAS_ONBOARDING_BOX_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/saas-logs/box`;
export const SAAS_ONBOARDING_GSUITE_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/saas-logs/gsuite`;
export const SAAS_ONBOARDING_GITHUB_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/saas-logs/github`;
export const SAAS_ONBOARDING_ZENDESK_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/saas-logs/zendesk`;
export const SAAS_ONBOARDING_OKTA_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/saas-logs/okta`;
export const SAAS_ONBOARDING_DUO_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/saas-logs/duo`;
export const SAAS_ONBOARDING_1PASS_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/saas-logs/1password`;
export const SAAS_ONBOARDING_SALESFORCE_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/saas-logs/salesforce`;
export const SAAS_ONBOARDING_SLACK_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/saas-logs/slack`;
export const SAAS_ONBOARDING_ZOOM_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/saas-logs/zoom`;
export const SAAS_ONBOARDING_CROWDSTRIKE_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/saas-logs/crowdstrike`;
export const SAAS_ONBOARDING_M365_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/saas-logs/microsoft`;
export const SAAS_ONBOARDING_ATLASSIAN_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/saas-logs/atlassian`;
export const SAAS_ONBOARDING_SNYK_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/supported-logs/snyk`;
export const SAML_DOC_URL = `${PANTHER_DOCS_LINK}/system-configuration/saml/generic`;
export const MOCKED_FUNCTIONS_DOC_URL = `${PANTHER_DOCS_LINK}/writing-detections/unit-test-mocking`;
export const DATA_SCHEMAS_DOC_URL = `${PANTHER_DOCS_LINK}/data-onboarding/custom-log-types`;
export const SAVED_QUERIES_CRON_DOC_URL = `${PANTHER_DOCS_LINK}/data-analytics/scheduled-queries`;
export const LUTS_S3_ROLE_DOC_URL = `${PANTHER_DOCS_LINK}/data-analytics/lookup-tables/s3-source#creating-an-iam-role`;
export const SNOWFLAKE_DOC_URL = `${PANTHER_DOCS_LINK}/system-configuration/snowflake-setup`;
export const PUBLIC_API_DOC_URL = `${PANTHER_DOCS_LINK}/api-beta`;
export const PUBLIC_API_USAGE_DOC_URL = `${PUBLIC_API_DOC_URL}/using-the-panther-api`;
export const PUBLIC_API_KEY_GENERATION_DOC_URL = `${PUBLIC_API_DOC_URL}/generating-an-api-token`;
// End of doc URLs section

export const DEFAULT_SMALL_PAGE_SIZE = 10;
export const DEFAULT_LARGE_PAGE_SIZE = 25;
export const DEFAULT_MAX_PAGE_SIZE = 999;

// Cron
export const DEFAULT_CRON_EXPRESSION = '0 0 * * *';
// The key under which User-related data will be stored in the storage
export const USER_INFO_STORAGE_KEY = 'panther.user.info';
export const ANALYTICS_CONSENT_STORAGE_KEY = 'panther.generalSettings.analyticsConsent';

export type PermissionMapping<T = string> = Record<
  typeof Permission[keyof typeof Permission],
  {
    id: Permission;
    title: string;
    description: string;
    grouping: T;
    hidden?: boolean;
  }
>;

export const ROLE_PERMISSIONS: PermissionMapping<
  | 'Alerts'
  | 'Detections'
  | 'Cloud Security'
  | 'Analysis'
  | 'Integrations'
  | 'User Management'
  | 'Configuration'
  | 'Data'
  | 'Enrichment'
> = {
  [Permission.UserRead]: {
    id: Permission.UserRead,
    title: 'View Users',
    description: 'Allows viewing the information of the Panther users',
    grouping: 'User Management',
  },
  [Permission.UserModify]: {
    id: Permission.UserModify,
    title: 'Manage Users',
    description:
      'Allows the modification, invitation & deletion of users from the platform. This permission grants Administrator level access to Panther.',
    grouping: 'User Management',
  },
  [Permission.AlertRead]: {
    id: Permission.AlertRead,
    title: 'View Alerts',
    description: 'Allows viewing of alerts in the platform',
    grouping: 'Alerts',
  },
  [Permission.AlertModify]: {
    id: Permission.AlertModify,
    title: 'Manage Alerts',
    description: 'Allows editing of alerts in the platform',
    grouping: 'Alerts',
  },
  [Permission.RuleRead]: {
    id: Permission.RuleRead,
    title: 'View Rules',
    description: 'Allows viewing the log rules setup in Panther',
    grouping: 'Detections',
  },
  [Permission.RuleModify]: {
    id: Permission.RuleModify,
    title: 'Manage Rules',
    description: 'Allows the modification & deletion of log rules',
    grouping: 'Detections',
  },
  [Permission.PolicyRead]: {
    id: Permission.PolicyRead,
    title: 'View Policies',
    description: 'Allows viewing the Cloud Accounts policies',
    grouping: 'Detections',
  },
  [Permission.PolicyModify]: {
    id: Permission.PolicyModify,
    title: 'Manage Policies',
    description: 'Allows editing & deleting policies for Cloud Accounts',
    grouping: 'Detections',
  },
  [Permission.BulkUpload]: {
    id: Permission.BulkUpload,
    title: 'Bulk Upload',
    description: 'Allows the bulk uploading of resources to the platform',
    grouping: 'Analysis',
  },
  [Permission.ResourceRead]: {
    id: Permission.ResourceRead,
    title: 'Read Resources',
    description: 'Allows viewing the onboarded Cloud Account resources',
    grouping: 'Data',
  },
  [Permission.ResourceModify]: {
    id: Permission.ResourceModify,
    title: 'Modify Resources',
    description: 'Allows the suppression of misconfigured Cloud Account resources',
    grouping: 'Data',
  },
  [Permission.DataAnalyticsRead]: {
    id: Permission.DataAnalyticsRead,
    title: 'Run Log Queries',
    description: 'Allows querying historical log data',
    grouping: 'Data',
  },
  [Permission.DataAnalyticsModify]: {
    id: Permission.DataAnalyticsModify,
    title: 'Manage Saved Queries',
    description: 'Allows the management of saved queries',
    grouping: 'Data',
  },
  [Permission.DestinationRead]: {
    id: Permission.DestinationRead,
    title: 'View Destinations',
    description: 'Allows viewing the configured Alert Destinations',
    grouping: 'Integrations',
  },
  [Permission.DestinationModify]: {
    id: Permission.DestinationModify,
    title: 'Manage Destinations',
    description: 'Allows the modification & deletion of Alert Destinations',
    grouping: 'Integrations',
  },
  [Permission.LogSourceRead]: {
    id: Permission.LogSourceRead,
    title: 'View Log Sources',
    description: 'Allows viewing the Log sources setup in Panther',
    grouping: 'Integrations',
  },
  [Permission.LogSourceModify]: {
    id: Permission.LogSourceModify,
    title: 'Manage Log Sources',
    description: 'Allows the management of Log sources',
    grouping: 'Integrations',
  },
  [Permission.LookupRead]: {
    id: Permission.LookupRead,
    title: 'View Lookups',
    description: 'Allows viewing the configured Lookups',
    grouping: 'Enrichment',
  },
  [Permission.LookupModify]: {
    id: Permission.LookupModify,
    title: 'Manage Lookups',
    description: 'Allows the modification & deletion of Lookups',
    grouping: 'Enrichment',
  },
  [Permission.CloudsecSourceRead]: {
    id: Permission.CloudsecSourceRead,
    title: 'View Cloud Security Sources',
    description: 'Allows viewing the connected Cloud Accounts in Panther',
    grouping: 'Integrations',
  },
  [Permission.CloudsecSourceModify]: {
    id: Permission.CloudsecSourceModify,
    title: 'Manage Cloud Security Sources',
    description: 'Allows the management of Cloud Accounts',
    grouping: 'Integrations',
  },
  [Permission.LogSourceRawDataRead]: {
    id: Permission.LogSourceRawDataRead,
    title: 'View Log Sources Raw Data',
    description: 'Allows access to the raw data of Log Sources',
    grouping: 'Integrations',
  },
  [Permission.GeneralSettingsRead]: {
    id: Permission.GeneralSettingsRead,
    title: 'Read Settings & SAML Preferences',
    description: 'Allows viewing the general platform settings & SAML preferences',
    grouping: 'Configuration',
  },
  [Permission.GeneralSettingsModify]: {
    id: Permission.GeneralSettingsModify,
    title: 'Edit Settings & SAML Preferences',
    description: 'Allows the alteration of general platform settings & SAML preferences',
    grouping: 'Configuration',
  },
  [Permission.SummaryRead]: {
    id: Permission.SummaryRead,
    title: 'View Overview',
    description: 'Allows viewing the Cloud Security & Log Analysis overview data',
    grouping: 'Configuration',
  },
  [Permission.OrganizationApiTokenModify]: {
    id: Permission.OrganizationApiTokenModify,
    title: 'Manage API Tokens',
    description: 'Allows management of the organization-wide Panther API tokens',
    grouping: 'Configuration',
  },
  [Permission.OrganizationApiTokenRead]: {
    id: Permission.OrganizationApiTokenRead,
    title: 'View API Tokens',
    description: 'Allows viewing the Panther API tokens of the organization',
    grouping: 'Configuration',
  },
};

export const API_TOKEN_PERMISSIONS: Partial<PermissionMapping<
  'Alerts' | 'User Management' | 'Configuration' | 'Data Lake' | 'Supplementary'
>> = {
  [Permission.AlertRead]: {
    id: Permission.AlertRead,
    title: 'Read Alerts',
    description: 'Allows access to alert information',
    grouping: 'Alerts',
  },
  [Permission.AlertModify]: {
    id: Permission.AlertModify,
    title: 'Manage Alerts',
    description: 'Allows modifying the status of alerts',
    grouping: 'Alerts',
  },
  [Permission.DataAnalyticsRead]: {
    id: Permission.DataAnalyticsRead,
    title: 'Query Data Lake',
    description: 'Allows listing and issuing Data Explorer & Indicator Search queries',
    grouping: 'Data Lake',
  },
  [Permission.UserModify]: {
    id: Permission.UserModify,
    title: 'Manage Users',
    description:
      'Allows the modification, invitation & deletion of users from the platform. Caution! UserModify provides full administrator access to Panther.',
    grouping: 'User Management',
  },
  [Permission.UserRead]: {
    id: Permission.UserRead,
    title: 'Read User Info',
    description:
      'Allows access to user information related to your Panther resources (such as the user that updated an alert)',
    grouping: 'User Management',
  },
  [Permission.OrganizationApiTokenRead]: {
    id: Permission.OrganizationApiTokenRead,
    title: 'Read API Token Info',
    description:
      'Allows access to API token information related to your Panther resources (such as the name of token used to update an alert)',
    grouping: 'Supplementary',
  },
  [Permission.GeneralSettingsRead]: {
    id: Permission.GeneralSettingsRead,
    title: 'Read Panther Settings Info',
    description: 'Allows viewing the general platform settings & Panther Version',
    grouping: 'Configuration',
  },
};

export const PANTHER_SAML_USER_PREFIX = 'PantherSSO';

export const DEFAULT_SENSITIVE_VALUE = '*******************';

export const DESTINATIONS: Record<
  DestinationTypeEnum,
  { logo: string; title: string; type: DestinationTypeEnum }
> = {
  [DestinationTypeEnum.Slack]: {
    logo: slackLogo,
    title: 'Slack',
    type: DestinationTypeEnum.Slack,
  },
  [DestinationTypeEnum.Msteams]: {
    logo: msTeamsLogo,
    title: 'Microsoft Teams',
    type: DestinationTypeEnum.Msteams,
  },
  [DestinationTypeEnum.Opsgenie]: {
    logo: opsgenieLogo,
    title: 'Opsgenie',
    type: DestinationTypeEnum.Opsgenie,
  },
  [DestinationTypeEnum.Jira]: {
    logo: jiraLogo,
    title: 'Jira',
    type: DestinationTypeEnum.Jira,
  },
  [DestinationTypeEnum.Github]: {
    logo: githubLogo,
    title: 'Github',
    type: DestinationTypeEnum.Github,
  },
  [DestinationTypeEnum.Pagerduty]: {
    logo: pagerDutyLogo,
    title: 'PagerDuty',
    type: DestinationTypeEnum.Pagerduty,
  },
  [DestinationTypeEnum.Sns]: {
    logo: snsLogo,
    title: 'AWS SNS',
    type: DestinationTypeEnum.Sns,
  },
  [DestinationTypeEnum.Sqs]: {
    logo: sqsLogo,
    title: 'AWS SQS',
    type: DestinationTypeEnum.Sqs,
  },
  [DestinationTypeEnum.Asana]: {
    logo: asanaLogo,
    title: 'Asana',
    type: DestinationTypeEnum.Asana,
  },
  [DestinationTypeEnum.Customwebhook]: {
    logo: customWebhook,
    title: 'Custom Webhook',
    type: DestinationTypeEnum.Customwebhook,
  },
};

export const SEVERITY_COLOR_MAP: { [key in Severity]: keyof Theme['colors'] } = {
  [Severity.Critical]: 'red-400' as const,
  [Severity.High]: 'orange-400' as const,
  [Severity.Medium]: 'yellow-500' as const,
  [Severity.Low]: 'blue-300' as const,
  [Severity.Info]: 'gray-300' as const,
};

export const QUERY_STATUS_COLOR_MAP: { [key in DataLakeQueryStatus]: keyof Theme['colors'] } = {
  [DataLakeQueryStatus.Cancelled]: 'gray-600' as const,
  [DataLakeQueryStatus.Failed]: 'red-200' as const,
  [DataLakeQueryStatus.Running]: 'purple-200' as const,
  [DataLakeQueryStatus.Succeeded]: 'blue-500' as const,
};

export const ENTITIES_COLOR_MAP: {
  [key in DetectionTypeEnum | 'GLOBAL' | 'DATA_MODEL' | 'SAVED_QUERY']: keyof Theme['colors'];
} = {
  [DetectionTypeEnum.Policy]: 'indigo-300' as const,
  [DetectionTypeEnum.Rule]: 'cyan-500' as const,
  [DetectionTypeEnum.ScheduledRule]: 'pink-400' as const,
  SAVED_QUERY: 'violet-300' as const,
  GLOBAL: 'green-200' as const,
  DATA_MODEL: 'magenta-200' as const,
};

export const ALERT_TYPE_COLOR_MAP: {
  [key in AlertType]: keyof Theme['colors'];
} = {
  [AlertType.Rule]: 'red-300' as const,
  [AlertType.RuleError]: 'teal-500' as const,
  [AlertType.ScheduledRule]: 'yellow-300' as const,
  [AlertType.ScheduledRuleError]: 'orange-300' as const,
  [AlertType.Policy]: 'cyan-400' as const,
  [AlertType.SystemError]: 'yellow-100' as const,
};

export const NON_EDITABLE_ROLE_NAMES = ['Admin'];

export const MITRE_REPORT_KEY = 'MITRE ATT&CK';
