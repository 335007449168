/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  Alert,
  Box,
  Card,
  Flex,
  Grid,
  SimpleGrid,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from 'pouncejs';
import { extractErrorMessage, getGraphqlSafeDateRange } from 'Helpers/utils';
import { AlertListingType, AlertStatus, AlertType, Severity } from 'Generated/schema';
import AlertCard from 'Components/cards/AlertCard';
import NoResultsFound from 'Components/NoResultsFound';
import Panel from 'Components/Panel';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import ErrorBoundary from 'Components/ErrorBoundary';
import { useMetricsFiltersContext } from 'Components/utils/MetricsFiltersContext';
import { ENABLE_ALERTS_GSI_LIST } from 'Source/constants';
import { useGetHighPriorityAlerts } from './graphql/getHighPriorityAlerts.generated';
import { useGetAlertMetrics } from './graphql/getAlertMetrics.generated';
import AlertsOverviewPageSkeleton from './Skeleton';
import AlertSummary from './AlertSummary';
import AlertsBySeverity from './AlertsBySeverity';
import MostActiveRules from './MostActiveRules';
import OverviewFilters from '../OverviewFilters';

const AlertsOverview: React.FC = () => {
  const { filters } = useMetricsFiltersContext();

  const [utcDaysAgo, utcNow] = getGraphqlSafeDateRange({ days: 1 });
  const {
    loading: loadingAlertsAndErrors,
    previousData: previousAlertsAndErrorsData,
    data: alertsAndErrorsData = previousAlertsAndErrorsData,
  } = useGetHighPriorityAlerts({
    // FIXME: Restore cache-and-network when we properly handle duplicates in endless pagination
    // fetchPolicy: 'cache-and-network',
    // nextFetchPolicy: 'cache-first',
    variables: {
      alertsInput: {
        severities: [Severity.Critical, Severity.High],
        type: ENABLE_ALERTS_GSI_LIST ? AlertListingType.Alert : undefined,
        types: ENABLE_ALERTS_GSI_LIST
          ? undefined
          : [AlertType.Policy, AlertType.Rule, AlertType.ScheduledRule],
        pageSize: 5,
        statuses: [AlertStatus.Open],
        createdAtAfter: utcDaysAgo,
        createdAtBefore: utcNow,
      },
      detectionErrorsInput: {
        severities: [Severity.Critical, Severity.High],
        type: ENABLE_ALERTS_GSI_LIST ? AlertListingType.DetectionError : undefined,
        types: ENABLE_ALERTS_GSI_LIST
          ? undefined
          : [AlertType.RuleError, AlertType.ScheduledRuleError],
        pageSize: 5,
        statuses: [AlertStatus.Open],
        createdAtAfter: utcDaysAgo,
        createdAtBefore: utcNow,
      },
      systemErrorsInput: {
        type: ENABLE_ALERTS_GSI_LIST ? AlertListingType.SystemError : undefined,
        types: ENABLE_ALERTS_GSI_LIST ? undefined : [AlertType.SystemError],
        pageSize: 5,
        statuses: [AlertStatus.Open],
        createdAtAfter: utcDaysAgo,
        createdAtBefore: utcNow,
      },
    },
  });

  const { loading, previousData, data = previousData, error } = useGetAlertMetrics({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        metricNames: ['totalAlertsDelta', 'alertsBySeverity', 'alertsByRuleID'],
        ...filters,
      },
    },
  });

  if ((loading || loadingAlertsAndErrors) && (!data || !alertsAndErrorsData)) {
    return <AlertsOverviewPageSkeleton />;
  }

  if (error) {
    return (
      <Alert
        variant="error"
        title="We can't display this content right now"
        description={extractErrorMessage(error)}
      />
    );
  }

  const topAlerts = alertsAndErrorsData?.alerts?.edges.map(edge => edge.node) ?? [];
  const topDetectionErrors =
    alertsAndErrorsData?.detectionErrors?.edges.map(edge => edge.node) ?? [];
  const topSystemErrors = alertsAndErrorsData?.systemErrors?.edges.map(edge => edge.node) ?? [];
  const hasTopAlertsAndErrors =
    topAlerts.length > 0 || topDetectionErrors.length > 0 || topSystemErrors.length > 0;

  let defaultTabIndex;
  if (topAlerts.length) {
    defaultTabIndex = 0;
  } else if (topDetectionErrors.length) {
    defaultTabIndex = 1;
  } else if (topSystemErrors.length) {
    defaultTabIndex = 2;
  }

  const { alertsBySeverity, totalAlertsDelta, alertsByRuleID } = data.getMetrics;
  return (
    <ErrorBoundary>
      <Box as="article" mb={6} position="relative">
        <OverviewFilters />
        <SimpleGrid columns={1} spacingY={4}>
          <Panel title="Alerts Overview">
            {totalAlertsDelta.every(({ value }) => value === 0) ? (
              <Flex my={6} align="center" justify="center">
                <NoResultsFound />
              </Flex>
            ) : (
              <Grid templateColumns="1fr 3fr" minHeight={320}>
                <AlertSummary data={totalAlertsDelta} />
                <AlertsBySeverity alerts={alertsBySeverity} />
              </Grid>
            )}
          </Panel>
          {hasTopAlertsAndErrors && (
            <Card>
              <Tabs defaultIndex={defaultTabIndex}>
                <Box position="relative" px={2}>
                  <TabList>
                    <BorderedTab disabled={!topAlerts.length}>
                      Top 5 High Priority Alerts
                    </BorderedTab>
                    <BorderedTab disabled={!topDetectionErrors.length}>
                      Top 5 High Priority Detection Errors
                    </BorderedTab>
                    <BorderedTab disabled={!topSystemErrors.length}>
                      Top 5 High Priority System Errors
                    </BorderedTab>
                  </TabList>
                  <BorderTabDivider />
                </Box>
                <Box p={6}>
                  <TabPanels>
                    <TabPanel lazy>
                      <Flex direction="column" spacing={2}>
                        {topAlerts.map(detectionMatch => (
                          <AlertCard key={detectionMatch.id} alert={detectionMatch} />
                        ))}
                      </Flex>
                    </TabPanel>
                    <TabPanel lazy>
                      <Flex direction="column" spacing={2}>
                        {topDetectionErrors.map(detectionError => (
                          <AlertCard key={detectionError.id} alert={detectionError} />
                        ))}
                      </Flex>
                    </TabPanel>
                    <TabPanel lazy>
                      <Flex direction="column" spacing={2}>
                        {topSystemErrors.map(systemError => (
                          <AlertCard key={systemError.id} alert={systemError} />
                        ))}
                      </Flex>
                    </TabPanel>
                  </TabPanels>
                </Box>
              </Tabs>
            </Card>
          )}
          <Panel title="Most Active Detections">
            <MostActiveRules alertsByRuleID={alertsByRuleID} />
          </Panel>
        </SimpleGrid>
      </Box>
    </ErrorBoundary>
  );
};

export default AlertsOverview;
