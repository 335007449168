/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../../../../__generated__/schema';

import { HoldingTankTestJobDetails } from '../../../../../../../graphql/fragments/HoldingTankTestJobDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetRunningTaskVariables = {
  input: Types.HoldingTankGetRunningTaskInput;
};

export type GetRunningTask = { getRunningTask?: Types.Maybe<HoldingTankTestJobDetails> };

export const GetRunningTaskDocument = gql`
  query GetRunningTask($input: HoldingTankGetRunningTaskInput!) {
    getRunningTask(input: $input) {
      ... on HoldingTankTestJob {
        ...HoldingTankTestJobDetails
      }
    }
  }
  ${HoldingTankTestJobDetails}
`;

/**
 * __useGetRunningTask__
 *
 * To run a query within a React component, call `useGetRunningTask` and pass it any options that fit your needs.
 * When your component renders, `useGetRunningTask` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunningTask({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRunningTask(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetRunningTask, GetRunningTaskVariables>
) {
  return ApolloReactHooks.useQuery<GetRunningTask, GetRunningTaskVariables>(
    GetRunningTaskDocument,
    baseOptions
  );
}
export function useGetRunningTaskLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRunningTask, GetRunningTaskVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetRunningTask, GetRunningTaskVariables>(
    GetRunningTaskDocument,
    baseOptions
  );
}
export type GetRunningTaskHookResult = ReturnType<typeof useGetRunningTask>;
export type GetRunningTaskLazyQueryHookResult = ReturnType<typeof useGetRunningTaskLazyQuery>;
export type GetRunningTaskQueryResult = ApolloReactCommon.QueryResult<
  GetRunningTask,
  GetRunningTaskVariables
>;
export function mockGetRunningTask({
  data,
  variables,
  errors,
}: {
  data: GetRunningTask;
  variables?: GetRunningTaskVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetRunningTaskDocument, variables },
    result: { data, errors },
  };
}
