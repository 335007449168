/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField, Form, Formik } from 'formik';
import { Box, Flex, Text, useSnackbar } from 'pouncejs';
import { AlertStatus } from 'Generated/schema';
import FormikCombobox from 'Components/fields/ComboBox';
import { capitalize, extractErrorMessage } from 'Helpers/utils';
import SubmitButton from 'Components/buttons/SubmitButton';
import { useSelect } from 'Components/utils/SelectContext';
import { useUpdateAlertStatusById } from 'Source/graphql/queries';

const initialValues = {
  status: AlertStatus.Resolved,
};

interface ListAlertSelectionFormValues {
  status: AlertStatus;
}

export const alertStatusOptions = Object.values(AlertStatus);
export const filterItemToString = (item: AlertStatus) =>
  capitalize(item === AlertStatus.Closed ? 'Invalid' : item.toLowerCase());

const ListAlertDefaultUpdater: React.FC = () => {
  const { selection, resetSelection } = useSelect();
  const { pushSnackbar } = useSnackbar();

  const [updateAlertStatusById] = useUpdateAlertStatusById({
    onCompleted: data => {
      const { status } = data.updateAlertStatusById.alerts[0];
      resetSelection();
      pushSnackbar({
        variant: 'success',
        title: `${data.updateAlertStatusById.alerts.length} Alert(s) set to ${capitalize(
          (status === AlertStatus.Closed ? 'INVALID' : status).toLowerCase()
        )}`,
      });
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to bulk update alert(s) status`,
        description: extractErrorMessage(error),
      });
    },
  });

  const onSubmit = React.useCallback(
    (values: ListAlertSelectionFormValues) =>
      updateAlertStatusById({
        variables: { input: { status: values.status, ids: selection } },
      }),
    // FIXME: Look into missing effect dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selection]
  );

  return (
    <Flex justify="flex-end" align="center">
      <Formik<ListAlertSelectionFormValues> initialValues={initialValues} onSubmit={onSubmit}>
        <Form>
          <Flex spacing={4} align="center">
            <Text>{selection.length} Selected</Text>
            <Box width={150}>
              <FastField
                data-tid="mass-alert-status-update-combobox"
                name="status"
                as={FormikCombobox}
                items={alertStatusOptions}
                itemToString={filterItemToString}
                label="Status"
                placeholder="Select statuses"
                showClearSelectionControl={false}
              />
            </Box>
            <SubmitButton
              data-tid="mass-alert-status-update-apply"
              variantColor="violet-400"
              allowPristineSubmission
            >
              Apply
            </SubmitButton>
          </Flex>
        </Form>
      </Formik>
    </Flex>
  );
};

export default React.memo(ListAlertDefaultUpdater);
