/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Box, Icon, IconProps, FlexProps, Flex } from 'pouncejs';
import React from 'react';
import useRouter from 'Hooks/useRouter';
import { addTrailingSlash, getPathnameFromURI } from 'Helpers/utils';
import { Link as RRLink } from 'react-router-dom';

type NavLinkProps = {
  icon: IconProps['type'];
  label: string;
  to: string;
  isSecondary?: boolean;
};

const NavLink = ({ icon, label, to, isSecondary, ...flexProps }: NavLinkProps & FlexProps) => {
  const { location } = useRouter();
  const pathname = addTrailingSlash(location.pathname);
  const destination = addTrailingSlash(getPathnameFromURI(to));
  const isActive = pathname.startsWith(destination);

  const activeColor = isSecondary || isActive ? 'blue-400' : 'navyblue-500';
  const backgroundColor = isActive ? 'blue-400' : 'transparent';

  return (
    <Box as={RRLink} to={to} aria-current={isActive ? 'page' : undefined}>
      <Flex
        align="center"
        borderRadius="small"
        color="white-200"
        fontSize={isSecondary ? 'small-medium' : 'medium'}
        px={isSecondary ? 2 : 4}
        py={3}
        backgroundColor={backgroundColor}
        _hover={{
          backgroundColor: activeColor,
        }}
        _focus={{
          backgroundColor: activeColor,
        }}
        transition="background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms"
        truncated
        {...flexProps}
      >
        <Icon type={icon} size={isSecondary ? 'small' : 'medium'} mr={isSecondary ? 4 : 3} ml={3} />
        <Box>{label}</Box>
      </Flex>
    </Box>
  );
};

export default NavLink;
