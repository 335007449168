/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import withSEO from 'Hoc/withSEO';
import { compose } from 'Helpers/compose';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import {
  LookupFormValues,
  EMPTY_LOG_TYPE_MAPPING,
  lookupFormValidationSchema,
  getLookupUpdatePayload,
} from 'Components/wizards/LookupWizard/lookupWizardHelpers';
import LookupWizard from 'Components/wizards/LookupWizard';
import { Formik, Form } from 'formik';
import { useAddLookup } from 'Components/wizards/LookupWizard/LookupBasicInfoPanel/graphql/addLookup.generated';
import { useUpdateLookup } from 'Source/graphql/queries/updateLookup.generated';
import { omit } from 'lodash';

type CreateLookupFormValues = Omit<LookupFormValues, 'id'>;

const initialValues: CreateLookupFormValues = {
  name: '',
  description: '',
  reference: '',
  enabled: true,
  associatedLogTypes: [EMPTY_LOG_TYPE_MAPPING],
  schemaName: null,
  primaryKey: null,
  awsAccountId: '',
  updatePeriod: 60,
  s3FileLocation: '',
  kmsKey: '',
  iamRoleArn: '',
  alarmThreshold: 0, // no alarm by default
};

const CreateLookup = () => {
  const [addLookup] = useAddLookup({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          listLookups: (queryData, { toReference }) => {
            const addLookupCacheRef = toReference(data.addLookup);
            return queryData ? [addLookupCacheRef, ...queryData.lookups] : [addLookupCacheRef];
          },
        },
      });
    },
  });

  const [updateLookup] = useUpdateLookup();

  const handleSubmit = React.useCallback(
    async (values: LookupFormValues) => {
      const payload = getLookupUpdatePayload(values);

      try {
        // In most cases, we'll be creating a new lookup.
        //
        // HOWEVER...there is an edge case in the creation flow where the following
        // steps happen:
        //
        // 1) User fills out the form, goes all the way to the "import data" step
        // 2) User selects "manually upload", this submits the form and creates a new lookup
        // 3) User backs up in the form, edits something else (for example, their schema)
        // 4) User returns to import data step, and selects either option
        //    a) If the user selects "manual upload", their upload is actually done without
        //       the changes they made in step 3.
        //    b) If the user selects "refresh", attempting to recreate the lookup (the end of
        //       the refresh flow) will fail since the lookup already exists.
        //
        // In this scenario, we actually need to UPDATE the lookup when the form is submitted
        // instead of creating a new one.
        const hasLookupBeenCreated = Boolean(values.id);

        // Update the lookup if a call was already made to create it during the creation
        // flow (see above explanation ^^)
        if (hasLookupBeenCreated) {
          const { data } = await updateLookup({
            variables: {
              input: payload,
            },
          });

          return data.updateLookup;
        }

        // In most cases, we'll create a new lookup when this form is submitted.
        const { data } = await addLookup({
          variables: {
            input: omit(payload, 'id'),
          },
        });
        return data.addLookup;
      } catch (error) {
        return error as Error;
      }
    },
    [addLookup, updateLookup]
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={lookupFormValidationSchema}
    >
      <Form>
        <LookupWizard title="Creating a new Lookup Table" />
      </Form>
    </Formik>
  );
};

export default compose(
  withSEO({ title: 'New Lookup' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LookupModify],
    fallback: <Page403 />,
  })
)(CreateLookup);
