/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import orderBy from 'lodash/orderBy';
import { Card, SimpleGrid } from 'pouncejs';
import { FloatSerie } from 'Generated/schema';
import NoDataFound from 'Components/NoDataFound';
import BarChart from 'Components/charts/BarChart';

interface EventsByLatencyProps {
  latencies: FloatSerie[];
}

const COLUMNS = 3;

const barConfig = {
  color: 'blue-300' as const,
  showLabel: true,
  height: 24,
  formatValue: (v: number) => (v === 0 ? 'No sample data' : `${v.toFixed(2)} min`),
};

/**
 *  Splits the provided array into 'N' chunks and ensures that in case of uneven length
 *  the elements will go to the earliest chunks.
 * @param array The array that will be chunked
 * @param parts The number of the chunks
 * @returns A new array containing the same data split into sub arrays
 */
const splitToChunks = <T,>(array: T[], parts: number) => {
  const arrayCopy = [...array];
  const result: Array<Array<T>> = [];
  for (let i = parts; i > 0; i -= 1) {
    result.push(arrayCopy.splice(0, Math.ceil(arrayCopy.length / i)));
  }

  return result;
};

const EventsByLatency: React.FC<EventsByLatencyProps> = ({ latencies }) => {
  const data = React.useMemo(() => {
    if (!latencies?.length) {
      return null;
    }
    const orderedLatencies = orderBy(
      latencies.map(e => ({
        label: e.label,
        value: e.value / 60,
        config: barConfig,
      })),
      'value',
      'desc'
    );

    return {
      latencyChunks: splitToChunks(orderedLatencies, COLUMNS),
      maxValue: Math.max(...orderedLatencies.map(l => l.value)),
    };
  }, [latencies]);

  if (!latencies?.length) {
    return <NoDataFound title="No logs processed yet" />;
  }

  return (
    <SimpleGrid columns={COLUMNS} gap={3}>
      {data.latencyChunks.map((chunk, index) => (
        <Card key={index} variant="dark" position="relative" p={4}>
          <BarChart
            data={chunk}
            referenceValue={data.maxValue}
            testId="latency-log-types"
            withDividers
          />
        </Card>
      ))}
    </SimpleGrid>
  );
};

export default React.memo(EventsByLatency);
