/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { Button, DateRangeInput, DateRangeInputProps, Flex } from 'pouncejs';
import { JobStatus } from 'Generated/schema';
import BulletedLoading from 'Components/BulletedLoading';
import { getElapsedTimeDuration } from 'Helpers/utils';
import CollapsablePanel from 'Components/CollapsablePanel';
import { TestReportOverview } from '../TestReport';
import { useSchemasManagementContext } from '../SchemasManagementContext';
import useTestHoldingTankSchemaJob from './useTestSchemasActions';
import { S3PrefixLogTypesFormValues } from '../SchemasManagement';

interface TestSchemaSectionProps {
  onShowTestReport: () => void;
}
const TestSchemasSection: React.FC<TestSchemaSectionProps> = ({ onShowTestReport }) => {
  const {
    values: { s3PrefixLogTypes },
  } = useFormikContext<S3PrefixLogTypesFormValues>();
  const { schemaTestFilters, setSchemaTestFilters, schemaTestJob } = useSchemasManagementContext();
  const { cancelSchemaTestJob, startSchemaTestJob, isLoading } = useTestHoldingTankSchemaJob();

  const onRangeChange = React.useCallback<DateRangeInputProps['onChange']>(
    ([start, end]) => {
      setSchemaTestFilters({
        from: start ? dayjs(start).toISOString() : schemaTestFilters.from,
        to: end ? dayjs(end).toISOString() : schemaTestFilters.to,
      });
    },
    [schemaTestFilters.from, schemaTestFilters.to, setSchemaTestFilters]
  );

  const hasLogTypes = !!s3PrefixLogTypes.some(prefix => prefix.logTypes.length);

  const isStartButtonDisabled = isLoading || !hasLogTypes;

  const [fromDate, toDate] = React.useMemo(
    () => [new Date(schemaTestFilters.from), new Date(schemaTestFilters.to)],
    [schemaTestFilters]
  );

  const isTestJobRunning = schemaTestJob?.status === JobStatus.Running;

  const panelTitle = isTestJobRunning ? (
    <Flex align="center">
      Schema testing is in progress for{' '}
      {schemaTestJob?.stats
        ? getElapsedTimeDuration(schemaTestJob.stats.elapsedTimeSeconds, 'seconds')
        : '0'}
      <BulletedLoading />
    </Flex>
  ) : (
    <>Test Schemas</>
  );
  return (
    <CollapsablePanel
      title={panelTitle}
      actions={
        <Flex spacing={4} align="center">
          {isTestJobRunning ? (
            <>
              <Button
                variantColor="pink-700"
                onClick={cancelSchemaTestJob}
                disabled={isLoading}
                loading={isLoading}
                icon="stop"
              >
                Stop Testing
              </Button>
            </>
          ) : (
            <>
              <DateRangeInput
                disabled={isTestJobRunning}
                withPresets
                withTime
                timezone="utc"
                value={[fromDate, toDate]}
                onChange={onRangeChange}
                labelStart="Date Start"
                labelEnd="Date End"
                disableReset
              />
              <Button
                variantColor="teal-400"
                onClick={startSchemaTestJob}
                loading={isLoading}
                disabled={isStartButtonDisabled}
                icon="play"
              >
                Test All Schemas
              </Button>
            </>
          )}
        </Flex>
      }
    >
      {schemaTestJob && (
        <TestReportOverview schemaTestJob={schemaTestJob} onShowTestReport={onShowTestReport} />
      )}
    </CollapsablePanel>
  );
};

export default TestSchemasSection;
