/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import urls from 'Source/urls';
import useRouter from 'Hooks/useRouter';
import LinkButton from 'Components/buttons/LinkButton';
import { Flex, Text } from 'pouncejs';
import { LogSourceType } from 'Source/constants';

interface FinishSourceSetupActionsProps {
  integrationId: string;
  hasSchemas: boolean;
}

const FinishSourceSetupActions = ({ integrationId, hasSchemas }: FinishSourceSetupActionsProps) => {
  const { match, location } = useRouter<{ id: string; type: LogSourceType }>();
  const editMode = location.pathname.endsWith('/edit/');

  if (editMode) {
    return (
      <LinkButton to={`${urls.integrations.logSources.details(integrationId, match.params.type)}`}>
        Back to Log Source
      </LinkButton>
    );
  }
  return (
    <Flex direction="column" spacing={6}>
      <Text color="teal-200" fontWeight="medium" fontSize="x-large">
        It’s going to take a few minutes for data to be imported to your source
      </Text>
      {!hasSchemas && (
        <Flex>
          <Text width={400} mr={10}>
            You can now attach already existing schemas or logtypes to your source by clicking
            Attach Schemas
          </Text>
          <LinkButton
            variantColor="purple-100"
            to={`${urls.integrations.logSources.schemasManagement(integrationId)}`}
          >
            Attach Schemas
          </LinkButton>
        </Flex>
      )}
      <Flex>
        <Text width={400} mr={10}>
          {!hasSchemas ? 'Alternatively finish' : 'Finish'} the setup now, wait for data to be
          imported and infer them later in a new schema
        </Text>
        <LinkButton
          to={`${urls.integrations.logSources.details(integrationId, match.params.type)}`}
        >
          View Log Source
        </LinkButton>
      </Flex>
    </Flex>
  );
};

export default FinishSourceSetupActions;
