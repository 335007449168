/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import * as Yup from 'yup';
import { WorkdayLogIntegrationDetails } from 'Source/graphql/fragments/WorkdayLogIntegrationDetails.generated';
import { LogTransportMethodsEnum, SUBDOMAIN_REGEX } from 'Source/constants';
import useFindSourcesPerMethod from 'Hooks/useFindSourcesPerMethod';
import { yupIntegrationLabelUniqueness } from 'Helpers/utils';
import { Formik, Form } from 'formik';
import { Wizard } from 'Components/Wizard';
import CancelIntegrationAction from 'Pages/Integrations/components/CancelIntegrationAction';
import ConfigurationPanel from './ConfigurationPanel';
import CredentialsPanel from './CredentialsPanel';
import ValidationPanel from './ValidationPanel';

interface WorkdayLogsWizardProps {
  initialValues: WorkdayLogsSourceWizardValues;
  onSubmit: (
    values: WorkdayLogsSourceWizardValues
  ) => Promise<WorkdayLogIntegrationDetails | Error>;
}

export interface WorkdayLogsSourceWizardValues {
  integrationId?: string; // for updates
  integrationLabel: string;
  logTypes: string[];
  tenantHostName: string;
  tenantName: string;
  activityClientId: string;
  activityClientSecret: string;
  activityRefreshToken: string;
}

const WorkdayLogSourceWizard = ({ initialValues, onSubmit }: WorkdayLogsWizardProps) => {
  const existingPullerSources = useFindSourcesPerMethod(LogTransportMethodsEnum.logPulling);

  const validationSchema: Yup.SchemaOf<WorkdayLogsSourceWizardValues> = React.useMemo(
    () =>
      Yup.object().shape({
        integrationId: Yup.string(),
        logTypes: Yup.array().of(Yup.string().required()).required().min(1),
        integrationLabel: yupIntegrationLabelUniqueness({
          existingLabel: initialValues.integrationLabel,
          existingSources: existingPullerSources,
        }),
        tenantHostName: Yup.string()
          .matches(SUBDOMAIN_REGEX, 'Only lowercase letters, numbers and `-` is allowed')
          .required(),
        tenantName: Yup.string().required(),
        activityClientId: Yup.string().required(),
        activityClientSecret: initialValues.integrationId ? Yup.string() : Yup.string().required(),
        activityRefreshToken: initialValues.integrationId ? Yup.string() : Yup.string().required(),
      }),
    [initialValues.integrationId, initialValues.integrationLabel, existingPullerSources]
  );

  const editMode = !!initialValues.integrationId;

  return (
    <Formik<WorkdayLogsSourceWizardValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Wizard headerTitle="Connecting a new Log Source" enableAllNavigationSteps={editMode}>
          <Wizard.Step
            title="Configure Source"
            description="Provide account information and set preferences to connect the log source to Panther"
          >
            <ConfigurationPanel />
          </Wizard.Step>
          <Wizard.Step
            title="Set Credentials"
            description="Provide account credentials to give Panther permissions to collect logs from the source"
          >
            <CredentialsPanel />
          </Wizard.Step>
          <Wizard.Step
            title="Verify Setup"
            description="Confirm that your log source has been successfully onboarded or troubleshoot any issues"
            navigationDisabled={editMode}
          >
            <ValidationPanel />
          </Wizard.Step>
        </Wizard>
        {!editMode && <CancelIntegrationAction />}
      </Form>
    </Formik>
  );
};

export default WorkdayLogSourceWizard;
