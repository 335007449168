/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField, Form, Formik } from 'formik';
import { Box, Button, Card, Flex, Popover, PopoverContent, PopoverTrigger } from 'pouncejs';
import { ListLookupsInput } from 'Generated/schema';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import * as Yup from 'yup';
import pick from 'lodash/pick';
import PopoverAutoSubmit from 'Components/PopoverAutoSubmit';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import TextButton from 'Components/buttons/TextButton';

export type ListLookupsDropdownFiltersValues = {
  importMethods?: string[];
};

const S3_SYNC = 's3';
const MANUAL_UPLOAD = 'manual';

const filterItemToString = (item: typeof S3_SYNC | typeof MANUAL_UPLOAD) => {
  const filterSelections = {
    [S3_SYNC]: 'S3 Sync',
    [MANUAL_UPLOAD]: 'Manual Upload',
  };
  return filterSelections[item];
};

const importMethods = [S3_SYNC, MANUAL_UPLOAD];
const filterKeys: (keyof Partial<ListLookupsDropdownFiltersValues>)[] = ['importMethods'];

const defaultValues: ListLookupsDropdownFiltersValues = {
  importMethods: [],
};

const validationSchema: Yup.SchemaOf<ListLookupsDropdownFiltersValues> = Yup.object().shape({
  importMethods: Yup.array().of(Yup.string()),
});

/**
 * Dropdown of available filters to apply to the lookups list.
 *
 * By default, no filters are applied.
 */
const LookupDropdownFilters: React.FC = () => {
  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<
    ListLookupsInput
  >();
  const initialFilterValues = React.useMemo(() => {
    return {
      ...defaultValues,
      ...pick(requestParams, filterKeys),
    } as ListLookupsDropdownFiltersValues;
  }, [requestParams]);

  const filtersCount = Object.keys(defaultValues).filter(value => value in requestParams).length;

  return (
    <Popover>
      {({ close: closePopover, isOpen }) => (
        <React.Fragment>
          <PopoverTrigger
            data-tid="list-lookup-filters-menu"
            as={Button}
            iconAlignment="right"
            icon="filter-light"
            aria-label="Additional Filters"
          >
            Filters {filtersCount ? `(${filtersCount})` : ''}
          </PopoverTrigger>
          <PopoverContent alignment="bottom-left">
            <Card shadow="dark300" my={14} p={6} pb={4} width={540}>
              <Formik<ListLookupsDropdownFiltersValues>
                enableReinitialize
                onSubmit={updateRequestParams}
                initialValues={initialFilterValues}
                validationSchema={validationSchema}
              >
                {({ setValues, values }) => (
                  <Form>
                    <PopoverAutoSubmit<ListLookupsDropdownFiltersValues>
                      values={values}
                      isOpen={isOpen}
                      onSubmit={updateRequestParams}
                    />
                    <Flex direction="column" spacing={4}>
                      <FastField
                        name="importMethods"
                        as={FormikMultiCombobox}
                        items={importMethods}
                        itemToString={filterItemToString}
                        label="Import Method"
                        data-tid="list-lookup-filtering-import-method"
                        placeholder="Select import methods"
                      />
                    </Flex>
                    <Flex direction="column" justify="center" align="center" mt={8} spacing={4}>
                      <Box>
                        <Button onClick={closePopover}>Apply Filters</Button>
                      </Box>
                      <TextButton onClick={() => setValues(defaultValues)}>
                        Clear Filters
                      </TextButton>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Card>
          </PopoverContent>
        </React.Fragment>
      )}
    </Popover>
  );
};

export default React.memo(LookupDropdownFilters);
