/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import queryString from 'query-string';
import {
  Alert,
  AlertStatus,
  AnalysisPack,
  AnalysisPackSource,
  ComplianceIntegration,
  DataModel,
  Destination,
  GlobalHelper,
  ListAnalysisPacksInput,
  ListDetectionsInput,
  ListDetectionsSortFieldsEnum,
  LogIntegration,
  Policy,
  LogPullerTypeEnum,
  ResourceDetails,
  Role,
  Rule,
  SchemaRecord,
  SortDirEnum,
  Lookup,
  APIToken,
  AlertsInput,
  DetectionTypeEnum,
} from 'Generated/schema';
import { LogSourceType, LogTransportMethodsEnum } from 'Source/constants';
import { ListLogSourceSortFieldsEnum } from 'Pages/Integrations/LogSources/ListLogSources/ListLogSourceActions/ListLogSourceFilters';
import { ReplayFull } from 'Source/graphql/fragments/ReplayFull.generated';

// Typical URL encoding, allowing colons (:) to be present in the URL. Colons are safe.
// https://stackoverflow.com/questions/14872629/uriencode-and-colon
const urlEncode = (str: string) => encodeURIComponent(str).replace(/%3A/g, unescape);

const urls = {
  overview: {
    home: () => '/overview/',
  },
  alerts: {
    list: ({
      disableDefaultParams = true,
      ...rest
    }: { disableDefaultParams?: boolean } & Partial<AlertsInput> = {}) => {
      if (disableDefaultParams) {
        return '/alerts-and-errors/';
      }
      const params: Partial<AlertsInput> = {
        statuses: [AlertStatus.Open, AlertStatus.Triaged],
        ...rest,
      };

      return `/alerts-and-errors/?${queryString.stringify(params, { arrayFormat: 'bracket' })}`;
    },
    details: (id: Alert['id']) => `${urls.alerts.list()}${urlEncode(id)}/`,
  },
  detections: {
    home: () => '/detections/',
    list: ({ disableDefaultParams = true } = {}) => {
      if (disableDefaultParams) {
        return urls.detections.home();
      }
      const params: ListDetectionsInput = {
        page: 1,
        sortBy: ListDetectionsSortFieldsEnum.LastModified,
        sortDir: SortDirEnum.Descending,
      };

      return `${urls.detections.home()}?${queryString.stringify(params)}`;
    },
    create: (opts?: { sessionId?: string; mitre?: { tacticId: string; techniqueId: string } }) => {
      const base = `${urls.detections.home()}new/`;
      const qs = queryString.stringify({
        sessionId: opts?.sessionId,
        mitreTactic: opts?.mitre?.tacticId,
        mitreTechnique: opts?.mitre?.techniqueId,
      });

      return qs ? `${base}?${qs}` : base;
    },
    details: (id: string, type: DetectionTypeEnum) => {
      switch (type) {
        case DetectionTypeEnum.Policy:
          return urls.analysis.policies.details(id);
        case DetectionTypeEnum.Rule:
        case DetectionTypeEnum.ScheduledRule:
          return urls.analysis.rules.details(id);
        default:
          return urls.detections.list();
      }
    },
  },
  reports: {
    home: () => '/reports/',
    mitreMatrix: (opts?: { matrixId?: string; tacticId?: string; techniqueId?: string }) => {
      const base = '/reports/mitre-attack';
      const qs = queryString.stringify({
        matrix: opts?.matrixId,
        tactic: opts?.tacticId,
        technique: opts?.techniqueId,
      });

      return qs ? `${base}?${qs}` : base;
    },
    mitreMapping: ({ tacticId, techniqueId }: { tacticId?: string; techniqueId?: string }) => {
      const base = '/reports/mitre-attack/edit-mapping';
      const qs = queryString.stringify({ tactic: tacticId, technique: techniqueId });

      return qs ? `${base}?${qs}` : base;
    },
  },
  replays: {
    alerts: (detectionId: Rule['id'], replayId: ReplayFull['id'], alertId: Alert['id']) =>
      `/detections/rules/${detectionId}/replay/${urlEncode(replayId)}/alert/${alertId}`,
  },
  analysis: {
    home: () => '/analysis/',

    rules: {
      list: () => `${urls.detections.list()}rules/`,
      create: () => `${urls.detections.create()}?type=rule`,
      details: (id: Rule['id']) => `${urls.analysis.rules.list()}${urlEncode(id)}/`,
      edit: (id: Rule['id']) => `${urls.analysis.rules.details(id)}edit/`,
    },
    policies: {
      list: () => `${urls.detections.list()}policies/`,
      create: () => `${urls.detections.create()}?type=policy`,
      details: (id: Policy['id']) => `${urls.analysis.policies.list()}${urlEncode(id)}/`,
      edit: (id: Policy['id']) => `${urls.analysis.policies.details(id)}edit/`,
    },
    packs: {
      home: () => `${urls.analysis.home()}packs/`,
      list: ({ disableDefaultParams = true } = {}) => {
        if (disableDefaultParams) {
          return urls.analysis.packs.home();
        }
        const params: ListAnalysisPacksInput & { section: string } = {
          sortDir: SortDirEnum.Ascending,
          sortBy: 'enabled',
          section: 'packs',
        };

        return `${urls.analysis.packs.home()}?${queryString.stringify(params)}`;
      },
      details: (id: AnalysisPack['id']) => `${urls.analysis.packs.home()}${urlEncode(id)}/`,
      packSources: {
        home: () => `${urls.analysis.home()}packs/sources/`,
        list: () => `${urls.analysis.packs.home()}?section=pack-sources`,
        create: () => `${urls.analysis.packs.packSources.home()}new-detection-pack-source/`,
        details: () => `${urls.analysis.packs.packSources.home()}edit-pack-source/`,
        edit: (id: AnalysisPackSource['id']) =>
          `${urls.analysis.packs.packSources.details()}${urlEncode(id)}/`,
      },
    },
    bulkUploader: () => `${urls.analysis.home()}bulk-uploader/`,
  },
  data: {
    home: () => '/data/',
    dataExplorer: () => `${urls.data.home()}explorer/`,
    savedQueries: {
      list: () => `${urls.data.home()}saved-queries/`,
    },
    indicatorSearch: () => `${urls.data.home()}indicator-search/`,
    queryHistory: () => `${urls.data.home()}query-history/`,
    dataModels: {
      list: () => `${urls.analysis.home()}data-models/`,
      create: () => `${urls.data.dataModels.list()}new/`,
      details: (id: DataModel['id']) => `${urls.data.dataModels.list()}${urlEncode(id)}/`,
      edit: (id: DataModel['id']) => `${urls.data.dataModels.details(id)}edit/`,
    },
    globalHelpers: {
      list: () => `${urls.analysis.home()}helpers/`,
      create: () => `${urls.data.globalHelpers.list()}new/`,
      edit: (id: GlobalHelper['id']) => `${urls.data.globalHelpers.list()}${urlEncode(id)}/edit/`,
    },
    resources: {
      list: () => `${urls.data.home()}resources/`,
      details: (id: ResourceDetails['id']) => `${urls.data.resources.list()}${urlEncode(id)}/`, // prettier-ignore
      edit: (id: ResourceDetails['id']) => `${urls.data.resources.details(id)}edit/`,
    },
    schemas: {
      list: () => `${urls.data.home()}data-schemas/`,
      details: (name: SchemaRecord['name']) => `${urls.data.schemas.list()}${urlEncode(name)}/`,
      edit: (name: SchemaRecord['name']) => `${urls.data.schemas.details(name)}edit/`,
      create: () => `${urls.data.schemas.list()}new/`,
    },
  },
  enrichment: {
    home: () => '/enrichment/',
    lookupTables: {
      home: () => `${urls.enrichment.home()}lookup-tables/`,
      list: () => urls.enrichment.lookupTables.home(),
      create: () => `${urls.enrichment.lookupTables.home()}new/`,
      edit: (id: Lookup['id']) => `${urls.enrichment.lookupTables.home()}${id}/edit/`,
    },
    sources: {
      home: () => `${urls.enrichment.home()}sources/`,
      list: () => urls.enrichment.sources.home(),
      details: (id: Lookup['id']) => `${urls.enrichment.sources.home()}${id}`,
    },
  },
  integrations: {
    home: () => `/integrations/`,
    logSources: {
      details: (id: LogIntegration['integrationId'], type: LogSourceType) =>
        `${urls.integrations.logSources.list()}${type}/${id}/`,
      list: ({ disableDefaultParams = true } = {}) => {
        if (disableDefaultParams) {
          return `${urls.integrations.home()}log-sources/`;
        }
        const params = {
          sortBy: ListLogSourceSortFieldsEnum.health,
          sortDir: SortDirEnum.Descending,
        };

        return `${urls.integrations.home()}log-sources/?${queryString.stringify(params)}`;
      },
      create: (type?: LogSourceType) =>
        `${urls.integrations.logSources.list()}add-new-source/${type ? `${type}/` : ''}`,
      edit: (id: LogIntegration['integrationId'], type: LogSourceType) =>
        `${urls.integrations.logSources.list()}${type}/${id}/edit/`,
      schemasManagement: (id: LogIntegration['integrationId']) =>
        `${urls.integrations.logSources.list()}${LogTransportMethodsEnum.s3}/${id}/schemas/`,
      authorize: (id: LogIntegration['integrationId'], type: LogPullerTypeEnum) =>
        `${urls.integrations.logSources.list()}${type}/${id}/authorize/`,
    },
    cloudAccounts: {
      list: () => `${urls.integrations.home()}cloud-accounts/`,
      create: () => `${urls.integrations.cloudAccounts.list()}new/`,
      edit: (id: ComplianceIntegration['integrationId']) =>
        `${urls.integrations.cloudAccounts.list()}${id}/edit/`,
    },
    destinations: {
      list: () => `${urls.integrations.home()}destinations/`,
      create: () => `${urls.integrations.destinations.list()}new/`,
      edit: (id: Destination['outputId']) =>
        `${urls.integrations.destinations.list()}${urlEncode(id)}/edit/`,
    },
  },
  settings: {
    home: () => `/settings/`,
    general: {
      home: () => `${urls.settings.home()}general/`,
    },
    roles: {
      list: () => `${urls.settings.home()}roles/`,
      create: () => `${urls.settings.roles.list()}new/`,
      details: (id: Role['id']) => `${urls.settings.roles.list()}${urlEncode(id)}/`,
      edit: (id: Role['id']) => `${urls.settings.roles.details(id)}edit/`,
    },
    users: () => `${urls.settings.home()}users/`,
    apiTokens: {
      list: () => `${urls.settings.home()}api-tokens/`,
      playground: () => `${urls.settings.apiTokens.list()}playground/`,
      create: () => `${urls.settings.apiTokens.list()}new/`,
      details: (id: APIToken['id']) => `${urls.settings.apiTokens.list()}${urlEncode(id)}/`,
      edit: (id: APIToken['id']) => `${urls.settings.apiTokens.details(id)}edit/`,
    },
  },
  account: {
    auth: {
      signIn: () => `/sign-in/`,
      singleSignOn: () => `/oauth/`,
      forgotPassword: () => `/password-forgot/`,
      resetPassword: () => `/password-reset/`,
    },
    support: () => `/support/`,
  },
};

export const getUrlWithDefaultParams = (url: string) => {
  switch (url) {
    case urls.integrations.logSources.list():
      return urls.integrations.logSources.list({ disableDefaultParams: false });
    case urls.alerts.list():
      return urls.alerts.list({ disableDefaultParams: false });
    case urls.detections.list():
      return urls.detections.list({ disableDefaultParams: false });
    case urls.analysis.packs.list():
      return urls.analysis.packs.list({ disableDefaultParams: false });
    default:
      return url;
  }
};

export default urls;
