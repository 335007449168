/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import dayjs from 'dayjs';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import { getGraphqlSafeDateRange } from 'Helpers/utils';
import { MetricsInput } from 'Generated/schema';

export type MetricsFilters = Pick<MetricsInput, 'fromDate' | 'toDate' | 'intervalMinutes'>;

export const DEFAULT_INTERVAL = 180;
export const DEFAULT_PAST_DAYS = 7;

const MetricsFiltersContext = React.createContext<{
  filters: MetricsFilters;
  // Data received from BE before this date have actual values the rest are used for forecasting
  resultProjectionStartDate: string;
  setFilters: (value: MetricsFilters) => void;
}>(undefined);

export const OverviewFiltersContextProvider: React.FC = ({ children }) => {
  const {
    requestParams: { fromDate, toDate, intervalMinutes },
    updateRequestParams: setFilters,
  } = useRequestParamsWithoutPagination<MetricsFilters>();

  const contextValue = React.useMemo(() => {
    const [utcDaysAgo, utcNow] = getGraphqlSafeDateRange({ days: DEFAULT_PAST_DAYS });

    return {
      filters: {
        intervalMinutes: intervalMinutes ?? DEFAULT_INTERVAL,
        fromDate: fromDate ?? utcDaysAgo,
        toDate: toDate ?? utcNow,
      },
      // Since the last interval received from the BE might not be filled with data
      // yet, we have to store the last valid date in order to differentiate actual from
      // forecast values
      resultProjectionStartDate: dayjs
        .utc(utcNow)
        .subtract(intervalMinutes ?? DEFAULT_INTERVAL, 'minute')
        .format(),
      setFilters,
    };
  }, [intervalMinutes, fromDate, toDate, setFilters]);

  return (
    <MetricsFiltersContext.Provider value={contextValue}>{children}</MetricsFiltersContext.Provider>
  );
};

export const useMetricsFiltersContext = () => React.useContext(MetricsFiltersContext);

export const withMetricsFiltersContext = Component => props => (
  <OverviewFiltersContextProvider>
    <Component {...props} />
  </OverviewFiltersContextProvider>
);
