/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import dayjs from 'dayjs';
import { TestHoldingTankSchemaFilters, HoldingTankFilters } from 'Generated/schema';
import { HoldingTankTestJobDetails } from 'Source/graphql/fragments/HoldingTankTestJobDetails.generated';
import { HoldingTankInferJobDetails } from 'Source/graphql/fragments/HoldingTankInferJobDetails.generated';
import useGetSourceRunningTask from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagementContext/useGetSourceRunningTask';
import { useListTestTasks } from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagementContext/graphql/listTestTasks.generated';
import useJobPolling from './useHoldingTankJobPolling';

export interface SchemasManagementContextValue {
  // Raw data filters
  rawDataFilters: HoldingTankFilters;
  setRawDataFilters: (s: HoldingTankFilters) => void;
  // Test schema filters
  schemaTestFilters: TestHoldingTankSchemaFilters;
  setSchemaTestFilters: (schemaTestFilters: TestHoldingTankSchemaFilters) => void;
  // Currently active schema test job
  schemaTestJob: HoldingTankTestJobDetails;
  // Sets active test schema job id and starts polling it
  setSchemaTestJob: (job: HoldingTankTestJobDetails) => void;
  // Currently active schema infer job
  inferSchemaJob: HoldingTankInferJobDetails;
  // Sets active infer schema job id and starts polling it
  setInferSchemaJob: (job: HoldingTankInferJobDetails) => void;
  sourceId: string;
  testTasks: HoldingTankTestJobDetails[];
}

const SchemasManagementContext = React.createContext<SchemasManagementContextValue>(undefined);

interface SchemasManagementProviderProps<> {
  sourceId?: string;
  children: React.ReactNode;
}

function SchemasManagementProvider({ children, sourceId }: SchemasManagementProviderProps) {
  const [schemaTestJob, setSchemaTestJob] = React.useState<HoldingTankTestJobDetails>(null);
  const [inferSchemaJob, setInferSchemaJob] = React.useState<HoldingTankInferJobDetails>(null);
  const [schemaTestFilters, setSchemaTestFilters] = React.useState<TestHoldingTankSchemaFilters>({
    from: dayjs().utc().subtract(1, 'days').startOf('day').toISOString(),
    to: dayjs().utc().endOf('day').toISOString(),
  });

  const [rawDataFilters, setRawDataFilters] = React.useState<HoldingTankFilters>({
    from: dayjs().utc().startOf('day').toISOString(),
    to: dayjs().utc().endOf('day').toISOString(),
    excludedPrefixes: [],
    contains: '',
    s3Prefix: '',
  });

  const pollingInferSchemaJob = useJobPolling(inferSchemaJob?.id);
  const { data } = useListTestTasks({
    variables: {
      sourceId,
    },
  });
  const { job: runningJob } = useGetSourceRunningTask(sourceId);
  const lastTestJob = data?.listTestTasks.tasks.length && data.listTestTasks.tasks[0];
  const pollingSchemaTestJob = useJobPolling<HoldingTankTestJobDetails>(
    schemaTestJob?.id || runningJob?.id || lastTestJob?.id
  );

  React.useEffect(() => {
    if (pollingSchemaTestJob?.testParameters) {
      setSchemaTestJob(pollingSchemaTestJob);
    }
  }, [pollingSchemaTestJob]);

  React.useEffect(() => {
    if (pollingInferSchemaJob) {
      setInferSchemaJob(pollingInferSchemaJob as HoldingTankInferJobDetails);
    }
  }, [pollingInferSchemaJob]);

  const contextValue = React.useMemo(
    () => ({
      rawDataFilters,
      setRawDataFilters,
      schemaTestFilters,
      setSchemaTestFilters,
      setSchemaTestJob,
      schemaTestJob,
      inferSchemaJob,
      setInferSchemaJob,
      sourceId,
      testTasks: data?.listTestTasks.tasks ?? [],
    }),
    [
      sourceId,
      data?.listTestTasks,
      rawDataFilters,
      setRawDataFilters,
      schemaTestFilters,
      schemaTestJob,
      inferSchemaJob,
      setInferSchemaJob,
    ]
  );

  return (
    <SchemasManagementContext.Provider value={contextValue}>
      {children}
    </SchemasManagementContext.Provider>
  );
}

const MemoizedSchemasManagementProvider = React.memo(SchemasManagementProvider);

const withSchemasManagementContext = (
  config?: Omit<SchemasManagementProviderProps, 'children'>
) => (Component: React.FC) => props => (
  <SchemasManagementProvider {...config}>
    <Component {...props} />
  </SchemasManagementProvider>
);

const useSchemasManagementContext = () =>
  React.useContext<SchemasManagementContextValue>(SchemasManagementContext);

export {
  SchemasManagementContext,
  MemoizedSchemasManagementProvider as SchemasManagementProvider,
  withSchemasManagementContext,
  useSchemasManagementContext,
};
