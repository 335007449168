/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Button, Link, Flex, Heading, Img, Text } from 'pouncejs';

import CelebrateSVG from 'Assets/illustrations/celebrate.svg';
import urls from 'Source/urls';

const EmptyDataFallback: React.FC<{ onEditPrefixes: () => void }> = ({ onEditPrefixes }) => {
  return (
    <Flex spacing={5} direction="column" align="center">
      <Img src={CelebrateSVG} nativeWidth={167} nativeHeight={126} alt="Two people celebrating" />
      <Heading as="h3" size="small" fontWeight="medium">
        Add your first Schema
      </Heading>
      <Button onClick={onEditPrefixes}>Attach existing Schema(s)</Button>
      <Text fontWeight="medium">
        Alternatively you can go and{' '}
        <Link as={RRLink} to={urls.data.schemas.create()}>
          create a new schema
        </Link>
      </Text>
    </Flex>
  );
};

export default EmptyDataFallback;
