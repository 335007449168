/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field } from 'formik';
import { Text, Button, Flex, FormHelperText, Link } from 'pouncejs';
import FormikTextInput from 'Components/fields/TextInput';
import SensitiveTextInput from 'Components/fields/SensitiveTextInput';
import { capitalize, OAuthRedirectUrl } from 'Helpers/utils';
import useCopyToClipboard from 'Hooks/useCopyToClipboard';
import { LogPullerTypeEnum } from 'Generated/schema';
import useRouter from 'Hooks/useRouter';
import {
  LogSourceType,
  SAAS_ONBOARDING_BOX_DOC_URL,
  SAAS_ONBOARDING_GITHUB_DOC_URL,
  SAAS_ONBOARDING_GSUITE_DOC_URL,
  SAAS_ONBOARDING_SLACK_DOC_URL,
  SAAS_ONBOARDING_ZENDESK_DOC_URL,
  SAAS_ONBOARDING_ZOOM_DOC_URL,
} from 'Source/constants';

const documentationUrls: Partial<Record<LogSourceType, string>> = {
  [LogPullerTypeEnum.Box]: SAAS_ONBOARDING_BOX_DOC_URL,
  [LogPullerTypeEnum.Github]: SAAS_ONBOARDING_GITHUB_DOC_URL,
  [LogPullerTypeEnum.Slack]: SAAS_ONBOARDING_SLACK_DOC_URL,
  [LogPullerTypeEnum.Zendesk]: SAAS_ONBOARDING_ZENDESK_DOC_URL,
  [LogPullerTypeEnum.Zoom]: SAAS_ONBOARDING_ZOOM_DOC_URL,
  [LogPullerTypeEnum.Gsuite]: SAAS_ONBOARDING_GSUITE_DOC_URL,
};

const OAuthPullerCredentialsFields = ({
  shouldMaskSecret = false,
}: {
  shouldMaskSecret?: boolean;
}) => {
  const {
    match: {
      params: { type },
    },
  } = useRouter<{ type: LogSourceType }>();
  if (!type) {
    throw new Error(`Unknown source type`);
  }

  const pullerName = capitalize(type);
  const documentationUrl = documentationUrls[type];
  const copyToClipboard = useCopyToClipboard();

  return (
    <Flex spacing={6} direction="column">
      <Text>We suggest you create a new application for isolation purposes.</Text>
      <Flex as="ol" direction="column" spacing={6}>
        <FormHelperText as="li" id="oauth2-helper">
          <Flex direction="column" spacing={4}>
            <Text color="teal-200" fontSize="medium" fontWeight="bold">
              1. Use the link below as the redirect URL in your App settings
            </Text>
            <Flex spacing={4}>
              <Flex
                backgroundColor="navyblue-700"
                padding={3}
                borderRadius="medium"
                align="center"
                truncated
              >
                <Text color="white-100" fontSize="medium" width={1} truncated>
                  {OAuthRedirectUrl}
                </Text>
              </Flex>
              <Button variantColor="navyblue-200" onClick={() => copyToClipboard(OAuthRedirectUrl)}>
                Copy
              </Button>
            </Flex>
          </Flex>
        </FormHelperText>
        <Flex as="li" direction="column" spacing={4}>
          <Text color="teal-200" fontSize="medium" fontWeight="bold">
            2. Fill in the credentials below
          </Text>
          <Field
            label="Client ID"
            name="clientId"
            as={FormikTextInput}
            placeholder={`Your ${pullerName} App Client ID`}
            required
          />
          <Field
            name="clientSecret"
            shouldMask={shouldMaskSecret}
            as={SensitiveTextInput}
            label="Client Secret"
            placeholder={
              shouldMaskSecret
                ? 'Information is hidden. New values will override the existing'
                : `Your ${pullerName} App Client Secret`
            }
            type="password"
            required
            aria-describedby="credentials-helper"
          />
        </Flex>
      </Flex>
      <FormHelperText color="white-100" id="credentials-helper" textAlign="center" mt={2}>
        Need help creating a new {pullerName} app? Check
        <Link external href={documentationUrl} ml={1}>
          our documentation
        </Link>
      </FormHelperText>
    </Flex>
  );
};

export default OAuthPullerCredentialsFields;
