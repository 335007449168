/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Heading } from 'pouncejs';
import { LongSerie } from 'Generated/schema';
import NoDataFound from 'Components/NoDataFound';
import BarChart from 'Components/charts/BarChart';
import DifferenceText from './DifferenceText';

interface AlertSummaryProps {
  data: LongSerie[];
}

const AlertSummary: React.FC<AlertSummaryProps> = ({ data }) => {
  if (!data) {
    return (
      <Card width="25%" variant="dark" px={6} pt={6} pb={4}>
        <NoDataFound title="No alerts are present in the system" />
      </Card>
    );
  }
  const alertsCurrentPeriod = data.find(d => d.label === 'Current Period').value;
  const alertPreviousPeriod = data.find(d => d.label === 'Previous Period').value;

  const diff = alertPreviousPeriod - alertsCurrentPeriod;

  // FIXME: Fix rules-of-hooks violation (hook used after an early return). We'll probably need to
  // do some refactoring to the component to accomplish this.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const alertsChartData = React.useMemo(
    () =>
      data.map(d => ({
        label: d.label,
        value: d.value,
        config: {
          height: 24,
          showLabel: true,
          color: d.label === 'Current Period' ? ('red-300' as const) : ('navyblue-200' as const),
        },
      })),
    [data]
  );

  return (
    <Card variant="dark" px={6} pt={6} pb={4}>
      <Flex direction="column" align="center" justify="space-between">
        <Box width="100%" textAlign="center">
          <Box id="total-alerts" fontWeight="bold">
            Total Alerts
          </Box>
          <Flex
            direction="column"
            align="center"
            justify="center"
            borderRadius="medium"
            my={3}
            pb={2}
            backgroundColor="navyblue-600"
          >
            <Heading
              as="h2"
              size="3x-large"
              color="red-300"
              fontWeight="bold"
              aria-describedby="total-alerts"
              data-testid="alerts-current-period"
            >
              {alertsCurrentPeriod}
            </Heading>
            <DifferenceText diff={diff} />
          </Flex>
        </Box>
        <Box width="100%">
          <BarChart data={alertsChartData} withDividers />
        </Box>
      </Flex>
    </Card>
  );
};

export default AlertSummary;
