/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AnalyticsTracker from 'Components/utils/AnalyticsTracker';
import ListResourcesPage from 'Pages/ListResources';
import ResourceDetailsPage from 'Pages/ResourceDetails';
import PolicyDetailsPage from 'Pages/PolicyDetails';
import GeneralSettingsPage from 'Pages/GeneralSettings';
import SignInPage from 'Pages/SignIn';
import UsersPage from 'Pages/Users';
import RuleDetailsPage from 'Pages/RuleDetails';
import EditRulePage from 'Pages/EditRule';
import CreateDetectionPage from 'Pages/CreateDetection';
import AlertDetailsPage from 'Pages/AlertDetails';
import EditPolicyPage from 'Pages/EditPolicy';
import ListAlertsPage from 'Pages/ListAlerts';
import Layout from 'Components/Layout';
import urls from 'Source/urls';
import GuardedRoute from 'Components/GuardedRoute';
import ForgotPasswordPage from 'Pages/ForgotPassword';
import ForgotPasswordConfirmPage from 'Pages/ForgotPasswordConfirm';
import ErrorBoundary from 'Components/ErrorBoundary';
import Page404 from 'Pages/404';
import ListRolesPage from 'Pages/ListRoles';
import CreateRolePage from 'Pages/CreateRole';
import EditRolePage from 'Pages/EditRole';
import APIErrorFallback from 'Components/utils/ApiErrorFallback';
import SingleSignOnPage from 'Pages/SingleSignOn';
import PromptController from 'Components/utils/PromptController';
import DataExplorerPage from 'Pages/DataExplorer';
import IndicatorSearchPage from 'Pages/IndicatorSearch';
import QueryHistoryPage from 'Pages/QueryHistory';
import SupportPage from 'Pages/Support';
import BulkUploaderPage from 'Pages/BulkUploader';
import ListGlobalHelpersPage from 'Pages/ListGlobalHelpers';
import CreateGlobalHelperPage from 'Pages/CreateGlobalHelper';
import EditGlobalHelper from 'Pages/EditGlobalHelper';
import ListDataSchemasPage from 'Pages/ListDataSchemas';
import CreateDataSchema from 'Pages/CreateDataSchema';
import EditDataSchema from 'Pages/EditDataSchema';
import DataSchemaDetailsPage from 'Pages/DataSchemaDetails';
import CreateDataModelPage from 'Pages/CreateDataModel';
import EditDataModelPage from 'Pages/EditDataModel';
import ListDataModelsPage from 'Pages/ListDataModels';
import ListSavedQueries from 'Pages/ListSavedQueries';
import ListDetectionsPage from 'Pages/ListDetections';
import ListAnalysisPacks from 'Pages/ListAnalysisPacks';
import AnalysisPackDetails from 'Pages/AnalysisPackDetails';
import Overview from 'Pages/Overview';
import CreatePackSourcePage from 'Pages/CreatePackSource';
import EditPackSourcePage from 'Pages/EditPackSource';
import IntegrationRoutes from 'Pages/Integrations';
import APITokenRoutes from 'Pages/APIToken';
import EnrichmentRoutes from 'Pages/Enrichment';
import ReplayedAlertDetailsPage from 'Pages/ReplayedAlertDetails';
import MitreMatrix from 'Pages/Reports/MitreMatrix';
import MitreMapping from 'Pages/Reports/MitreMapping';

// Main page container for the web application, Navigation bar and Content body goes here
const Routes: React.FunctionComponent = () => {
  return (
    <Switch>
      <GuardedRoute
        limitAccessTo="anonymous"
        exact
        path={urls.account.auth.signIn()}
        component={SignInPage}
      />
      <GuardedRoute
        limitAccessTo="anonymous"
        exact
        path={urls.account.auth.singleSignOn()}
        component={SingleSignOnPage}
      />
      <GuardedRoute
        limitAccessTo="anonymous"
        exact
        path={urls.account.auth.forgotPassword()}
        component={ForgotPasswordPage}
      />
      <GuardedRoute
        limitAccessTo="anonymous"
        exact
        path={urls.account.auth.resetPassword()}
        component={ForgotPasswordConfirmPage}
      />
      <GuardedRoute path="/" limitAccessTo="authenticated">
        <Layout>
          <ErrorBoundary>
            <APIErrorFallback>
              <Switch>
                <Redirect exact from="/" to={`${urls.overview.home()}?tab=alerts`} />
                <Redirect exact from="/alerts" to={urls.alerts.list()} />
                <Redirect exact from="/alerts/:id" to={urls.alerts.details(':id')} />
                <Redirect
                  exact
                  from="/detections/rules/:id/replay"
                  to={`${urls.analysis.rules.edit(':id')}?section=functions`}
                />
                <Redirect
                  exact
                  from="/detections/rules/:id/replay/:replayId/alert"
                  to={`${urls.analysis.rules.edit(':id')}?section=functions`}
                />
                <Route exact path={urls.overview.home()} component={Overview} />
                <Route exact path={urls.alerts.list()} component={ListAlertsPage} />
                <Route exact path={urls.alerts.details(':id')} component={AlertDetailsPage} />
                <Route
                  exact
                  path={urls.replays.alerts(':detectionId', ':replayId', ':alertId')}
                  component={ReplayedAlertDetailsPage}
                />
                {/* ******************* COMPLIANCE ***************************** */}
                <Redirect exact from={urls.data.home()} to={urls.data.resources.list()} />
                <Route exact path={urls.data.resources.list()} component={ListResourcesPage} />
                <Route
                  exact
                  path={urls.data.resources.details(':id')}
                  component={ResourceDetailsPage}
                />
                {/* ******************* ANALYSIS ***************************** */}
                <Redirect
                  exact
                  from={urls.analysis.home()}
                  to={urls.analysis.packs.list({ disableDefaultParams: false })}
                />
                <Redirect
                  exact
                  from={urls.analysis.policies.list()}
                  to={`${urls.detections.list()}?analysisTypes[]=POLICY&page=1&sortBy=lastModified&sortDir=descending`}
                />
                <Redirect
                  exact
                  from={urls.analysis.rules.list()}
                  to={`${urls.detections.list()}?analysisTypes[]=RULE&page=1&sortBy=lastModified&sortDir=descending`}
                />
                <Route exact path={urls.detections.list()} component={ListDetectionsPage} />
                <Redirect exact path={urls.reports.home()} to={urls.reports.mitreMatrix()} />
                <Route exact path={urls.reports.mitreMatrix()} component={MitreMatrix} />
                <Route path={urls.reports.mitreMapping({})} component={MitreMapping} />
                <Route exact path={urls.detections.create()} component={CreateDetectionPage} />
                <Route
                  exact
                  path={urls.analysis.policies.details(':id')}
                  component={PolicyDetailsPage}
                />
                <Route exact path={urls.analysis.policies.edit(':id')} component={EditPolicyPage} />
                <Route
                  exact
                  path={urls.analysis.rules.details(':id')}
                  component={RuleDetailsPage}
                />
                <Route exact path={urls.analysis.rules.edit(':id')} component={EditRulePage} />
                <Route
                  exact
                  path={urls.data.globalHelpers.list()}
                  component={ListGlobalHelpersPage}
                />
                <Route
                  exact
                  path={urls.data.globalHelpers.create()}
                  component={CreateGlobalHelperPage}
                />
                <Route
                  exact
                  path={urls.data.globalHelpers.edit(':id')}
                  component={EditGlobalHelper}
                />
                <Route exact path={urls.analysis.bulkUploader()} component={BulkUploaderPage} />
                <Redirect
                  exact
                  from={`${urls.data.globalHelpers.list()}:id`}
                  to={urls.data.globalHelpers.edit(':id')}
                />
                <Route exact path={urls.data.dataModels.list()} component={ListDataModelsPage} />
                <Route exact path={urls.data.dataModels.create()} component={CreateDataModelPage} />
                <Route
                  exact
                  path={urls.data.dataModels.edit(':id')}
                  component={EditDataModelPage}
                />
                <Redirect
                  exact
                  from={urls.data.dataModels.details(':id')}
                  to={urls.data.dataModels.edit(':id')}
                />
                {/* ******************* ENRICHMENT ***************************** */}
                <Route path={urls.enrichment.home()} component={EnrichmentRoutes} />
                {/* ******************* INTEGRATIONS ***************************** */}
                <Route path={urls.integrations.home()} component={IntegrationRoutes} />
                {/* ******************* DATA ANALYTICS ***************************** */}
                <Redirect exact from={urls.data.home()} to={urls.data.dataExplorer()} />
                <Route exact path={urls.data.dataExplorer()} component={DataExplorerPage} />
                <Route exact path={urls.data.savedQueries.list()} component={ListSavedQueries} />
                <Route exact path={urls.data.indicatorSearch()} component={IndicatorSearchPage} />
                <Route exact path={urls.data.queryHistory()} component={QueryHistoryPage} />
                <Route exact path={urls.analysis.packs.list()} component={ListAnalysisPacks} />
                <Route
                  exact
                  path={urls.analysis.packs.packSources.create()}
                  component={CreatePackSourcePage}
                />
                <Route
                  exact
                  path={urls.analysis.packs.packSources.edit(':id')}
                  component={EditPackSourcePage}
                />
                <Redirect
                  exact
                  from={urls.analysis.packs.packSources.details()}
                  to={urls.analysis.packs.packSources.list()}
                />
                <Redirect
                  exact
                  from={urls.analysis.packs.packSources.home()}
                  to={urls.analysis.packs.packSources.list()}
                />
                <Route
                  exact
                  path={urls.analysis.packs.details(':id')}
                  component={AnalysisPackDetails}
                />
                <Route exact path={urls.data.schemas.create()} component={CreateDataSchema} />
                <Route
                  exact
                  path={urls.data.schemas.details(':name')}
                  component={DataSchemaDetailsPage}
                />
                <Route exact path={urls.data.schemas.edit(':name')} component={EditDataSchema} />
                <Route exact path={urls.data.schemas.list()} component={ListDataSchemasPage} />
                {/* ******************* SETTINGS ***************************** */}
                <Redirect exact from={urls.settings.home()} to={urls.settings.general.home()} />
                <Route exact path={urls.settings.general.home()} component={GeneralSettingsPage} />
                <Route exact path={urls.settings.roles.list()} component={ListRolesPage} />
                <Route exact path={urls.settings.roles.create()} component={CreateRolePage} />
                <Route exact path={urls.settings.roles.edit(':id')} component={EditRolePage} />
                <Redirect
                  exact
                  from={urls.settings.roles.details(':id')}
                  to={urls.settings.roles.list()}
                />
                <Route exact path={urls.settings.users()} component={UsersPage} />
                <Route path={urls.settings.apiTokens.list()} component={APITokenRoutes} />
                <Route exact path={urls.account.support()} component={SupportPage} />
                <Route component={Page404} />
              </Switch>
            </APIErrorFallback>
          </ErrorBoundary>
        </Layout>
        <PromptController />
        <AnalyticsTracker />
      </GuardedRoute>
    </Switch>
  );
};

export default React.memo(Routes);
