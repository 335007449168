/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, TabList, TabPanel, TabPanels, Tabs } from 'pouncejs';
import { LongSeriesData } from 'Generated/schema';
import NoDataFound from 'Components/NoDataFound';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import EventVolumeTab from './EventVolumeTab';
import EventsByLogTypeTab from './EventsByLogTypeTab';

interface EventAnalyticsSectionProps {
  eventsProcessed: LongSeriesData;
  bytesProcessed: LongSeriesData;
}

const EventAnalyticsSection: React.FC<EventAnalyticsSectionProps> = ({
  eventsProcessed,
  bytesProcessed,
}) => {
  return (
    <Card as="section">
      <Tabs>
        <Box position="relative" px={2}>
          <TabList>
            <BorderedTab>Number of Events by Log Type</BorderedTab>
            <BorderedTab>Volume of Events Processed</BorderedTab>
          </TabList>
          <BorderTabDivider />
        </Box>
        <Box p={6}>
          <TabPanels>
            <TabPanel lazy>
              {!eventsProcessed?.series?.length ? (
                <NoDataFound title="No logs processed yet" />
              ) : (
                <EventsByLogTypeTab eventsProcessed={eventsProcessed} />
              )}
            </TabPanel>
            <TabPanel lazy>
              {!bytesProcessed?.series?.length ? (
                <NoDataFound title="No bytes processed yet" />
              ) : (
                <EventVolumeTab volumeSeries={bytesProcessed} />
              )}
            </TabPanel>
          </TabPanels>
        </Box>
      </Tabs>
    </Card>
  );
};

export default React.memo(EventAnalyticsSection);
