/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useAsyncQueryContext from 'Hooks/useAsyncQueryContext';
import { Flex } from 'pouncejs';
import { FastField, useFormikContext, Formik, Form } from 'formik';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import isEqual from 'lodash/isEqual';
import useUrlParams from 'Hooks/useUrlParams';
import usePrevious from 'Hooks/usePrevious';
import { ViewSelections, ResultsFilterValues } from '../Results';
import DownloadAllButton from '../DownloadAllButton';
import DownloadSingleButton from '../DownloadSingleButton';

/**
 * Continuously checks whether form values have changed, and if so, automatically
 * submits the form.
 */
const AutoSubmitFormikForm = () => {
  const { values, submitForm } = useFormikContext<ResultsFilterValues>();
  const previousValues = usePrevious(values);

  React.useEffect(() => {
    const skipSubmit = previousValues === undefined || isEqual(values, previousValues);
    if (!skipSubmit) {
      submitForm();
    }
  }, [values, previousValues, submitForm]);

  return null;
};

const SelectedColumnsField = ({ results }: { results: Record<string, unknown> }) => {
  const columns = Object.keys(results[0]);
  const { values } = useFormikContext<ResultsFilterValues>();
  const customContent = React.useCallback(
    ({ isOpen }) => (isOpen ? <></> : <>{`Filter Columns (${values.selectedColumns.length})`}</>),
    [values.selectedColumns]
  );
  return (
    <FastField
      name="selectedColumns"
      as={FormikMultiCombobox}
      items={columns}
      renderContent={customContent}
      hideLabel
      searchable
      data-tid="data-explorer-results-column-filter"
      canClearAllAfter={3}
      showClearSelectionControl
    />
  );
};

export interface ResultsActionBarProps {
  viewSelection: ViewSelections;
  results: any;
}
const ResultsActionBar: React.FC<ResultsActionBarProps> = ({ viewSelection, results }) => {
  const {
    state: { queryStatus, queryId },
  } = useAsyncQueryContext();
  const { updateUrlParams, urlParams } = useUrlParams();
  const timeoutRef = React.useRef(null);

  // When the component unmounts, cancel any pending timeouts so we don't clobber the URL
  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const initialValues = React.useMemo(
    () => ({ selectedColumns: urlParams.filteredColumns ?? [] }),
    [urlParams.filteredColumns]
  );

  return viewSelection === 'tabular' ? (
    <>
      <Flex spacing={4} width="250px" zIndex="1002" flexDirection="column">
        <Formik<ResultsFilterValues>
          enableReinitialize
          onSubmit={values => {
            // If there's a pending timeout, cancel it so we don't clobber the URL
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
            }

            // This timeout drastically improves the perceived performance of the results
            // being filtered. Since filtering by columns (esp going to no filters) potentially
            // adds tons of DOM nodes, the rendering in the results is VERY slow.
            //
            // Adding a brief pause before we actually apply the filter gives the input
            // the user just interacted with a chance to update into the active state, making
            // it _feel_ faster overall.
            timeoutRef.current = setTimeout(() => {
              updateUrlParams({ filteredColumns: values.selectedColumns });
            }, 200);
          }}
          initialValues={initialValues}
        >
          <Form>
            <AutoSubmitFormikForm />
            <SelectedColumnsField results={results} />
          </Form>
        </Formik>
      </Flex>
      <Flex ml="6" width="136px">
        {/**
         * FIXME: There's a bug where the lazy query inside `DownloadAllButton` gets automatically
         *  fired if its parameters change after it has beeen executed once. This leads to endless
         *  downloads when the user presses the backward/forward browser buttons
         * https://github.com/apollographql/apollo-client/issues/6636#issuecomment-666310747
         */}
        <DownloadAllButton key={queryId} disabled={queryStatus !== 'succeeded'} />
      </Flex>
    </>
  ) : (
    <DownloadSingleButton jsonData={results} />
  );
};

export default ResultsActionBar;
