/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Button, Flex, useSnackbar } from 'pouncejs';
import { useSelect } from 'Components/utils/SelectContext';
import { without, isEqual } from 'lodash';
import LinkButton from 'Components/buttons/LinkButton';
import urls from 'Source/urls';
import { extractErrorMessage } from 'Helpers/utils';
import { useUpdateMitreMapping } from '../graphql/updateMitreMapping.generated';
import { MitreMappedRelation } from '../MitreMappingPage';

interface MitreMappingDetectionActionsProps {
  tacticId: string;
  techniqueId: string;
  onChangesSaved: () => void;
  reportSettingId: string;
  mappedDetectionIds: string[];
}

const MitreMappingDetectionActions = ({
  tacticId,
  techniqueId,
  onChangesSaved,
  reportSettingId,
  mappedDetectionIds,
}: MitreMappingDetectionActionsProps) => {
  const { pushSnackbar } = useSnackbar();
  const { selection } = useSelect<MitreMappedRelation>();

  const selectedDetectionIds = selection.map(o => o.id);

  const [updateMitreMapping, { loading }] = useUpdateMitreMapping({
    onCompleted: () => {
      onChangesSaved();
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: 'Update failed',
        description: extractErrorMessage(error) || 'Unknown error',
      });
    },
  });

  const handleSaveChanges = () => {
    const addDetectionIds = without(selectedDetectionIds, ...mappedDetectionIds);
    const removeDetectionIds = without(mappedDetectionIds, ...selectedDetectionIds);

    updateMitreMapping({
      variables: {
        input: {
          add: { detectionIds: addDetectionIds },
          remove: { detectionIds: removeDetectionIds },
          reportKeys: [`${tacticId}:${techniqueId}`],
        },
      },
      update: cache => {
        const id = cache.identify({ __typename: 'ReportSetting', id: reportSettingId });
        cache.evict({ id, fieldName: 'detections' });
        cache.modify({
          id,
          fields: {
            matchingDetectionIds: value => [
              ...without(value, ...removeDetectionIds),
              ...addDetectionIds,
            ],
          },
        });
      },
    });
  };

  const hasChanges =
    selectedDetectionIds.length !== mappedDetectionIds.length ||
    !isEqual(selectedDetectionIds, mappedDetectionIds);

  return (
    <Flex spacing={2}>
      <LinkButton
        to={urls.reports.mitreMatrix({ tacticId, techniqueId })}
        variant="outline"
        variantColor="navyblue-300"
      >
        Cancel
      </LinkButton>
      <Button disabled={!hasChanges} onClick={handleSaveChanges} loading={loading}>
        Save changes
      </Button>
    </Flex>
  );
};

export default MitreMappingDetectionActions;
