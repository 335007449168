/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import intersection from 'lodash/intersection';
import {
  AlertEvents,
  AlertsInput,
  AlertsOutput,
  ListLookupResponse,
  DataLakeQueriesOutput,
  ListSavedQueriesInput,
  ListSavedQueriesResponse,
  Query,
  QueryAlertsArgs,
  QueryListSavedQueriesArgs,
  ResolversParentTypes,
  Permission,
  Severity,
  HoldingTankRawDataResponse,
  QueryHoldingTankRawDataArgs,
  HoldingTankRawDataInput,
  ListLookupsInput,
  RelatedAlertsOutput,
  ReplayedAlertsInput,
  ReplayedAlertsOutput,
  QueryDataLakeQueriesArgs,
  DataLakeQueriesInput,
  QueryListLookupsArgs,
  QueryReplayedAlertsArgs,
  Mutation,
  QueryHoldingTankTestSchemasResultArgs,
  HoldingTankTestSchemasResultInput,
  HoldingTankTestSchemasResultsResponse,
} from 'Generated/schema';
import storage from 'Helpers/storage';
import {
   ANALYTICS_CONSENT_STORAGE_KEY,
 } from 'Source/constants'; // prettier-ignore
import {
  FieldPolicy,
  FieldReadFunction,
  Reference,
  TypePolicies as ApolloTypePolicies,
} from '@apollo/client';
import { expandPermissions } from 'Helpers/utils';
import { endlessPaginationDefaultMerge } from './utils';

interface FixedLengthArray<T extends any, L extends number> extends Array<T> {
  0: T;
  length: L;
}

type FieldValues<T> =
  | FieldPolicy<T, T, T | Reference | undefined>
  | FieldReadFunction<T, T | Reference | undefined>;

type TypePolicy<T> = {
  keyFields?: keyof T | (keyof T)[] | false;
  fields?: Partial<
    {
      [P in keyof T]: FieldValues<T[P]>;
    }
  >;
};

export type TypePolicies = Partial<
  {
    [T in keyof ResolversParentTypes]: TypePolicy<ResolversParentTypes[T]>;
  }
> & {
  Query: TypePolicy<Query>;
  Mutation?: TypePolicy<Mutation>;
};

const sortSeverities = (severities: Severity[]) => {
  return intersection(Object.values(Severity), severities);
};

const typePolicies: TypePolicies = {
  Query: {
    fields: {
      destination(existing, { args, toReference }) {
        return existing || toReference({ __typename: 'Destination', outputId: args.id });
      },
      getAvailableLogProvider(existing, { args, toReference }) {
        return existing || toReference({ __typename: 'AvailableLogProvider', id: args.id });
      },
      getComplianceIntegration(existing, { args, toReference }) {
        return (
          existing || toReference({ __typename: 'ComplianceIntegration', integrationId: args.id })
        );
      },
      getDataModel(existing, { args, toReference }) {
        return existing || toReference({ __typename: 'DataModel', id: args.id });
      },
      getAnalysisPack(existing, { args, toReference }) {
        return existing || toReference({ __typename: 'AnalysisPack', id: args.id });
      },
      getS3LogIntegration(existing, { args, toReference }) {
        return existing || toReference({ __typename: 'S3LogIntegration', integrationId: args.id });
      },
      getCloudWatchLogIntegration(existing, { args, toReference }) {
        return (
          existing ||
          toReference({ __typename: 'CloudWatchLogIntegration', integrationId: args.id })
        );
      },
      getEventBridgeLogIntegration(existing, { args, toReference }) {
        return (
          existing || toReference({ __typename: 'EventBridgeIntegration', integrationId: args.id })
        );
      },
      getLogPullingIntegration(existing, { args, toReference }) {
        return (
          existing || toReference({ __typename: 'LogPullingIntegration', integrationId: args.id })
        );
      },
      dataLakeDatabase(existing, { args, toReference }) {
        return existing || toReference({ __typename: 'DataLakeDatabase', name: args.name });
      },
      dataLakeDatabaseTable(existing, { args, toReference }) {
        return (
          existing ||
          toReference({
            __typename: 'DataLakeDatabaseTable',
            name: args.input.tableName,
            databaseName: args.input.databaseName,
          })
        );
      },
      getRole(existing, { args, toReference }) {
        return existing || toReference({ __typename: 'Role', id: args.id });
      },
      getSavedQuery(existing, { args, toReference }) {
        return existing || toReference({ __typename: 'SavedQuery', id: args.id });
      },
      getSqsLogIntegration(existingData, { args, toReference }) {
        return (
          existingData ||
          toReference({ __typename: 'SqsLogSourceIntegration', integrationId: args.id })
        );
      },
      alerts: {
        keyArgs: (): [keyof QueryAlertsArgs, FixedLengthArray<keyof AlertsInput, 15>] => [
          'input',
          [
            'detectionId',
            'severities',
            'logTypes',
            'resourceTypes',
            'logSources',
            'types',
            'type',
            'nameContains',
            'createdAtBefore',
            'createdAtAfter',
            'statuses',
            'eventCountMin',
            'eventCountMax',
            'sortBy',
            'sortDir',
          ],
        ],
        read(existing) {
          return existing;
        },
        merge: endlessPaginationDefaultMerge<AlertsOutput>(
          'edges',
          variables => variables.input?.cursor
        ),
      },
      listSavedQueries: {
        keyArgs: (): [
          keyof QueryListSavedQueriesArgs,
          FixedLengthArray<keyof ListSavedQueriesInput, 9>
        ] => [
          'input',
          [
            'contains',
            'createdBefore',
            'createdAfter',
            'showDeleted',
            'userId',
            'sortDir',
            'isScheduled',
            'isActive',
            'tags',
          ],
        ],
        read(existing) {
          return existing;
        },
        merge: endlessPaginationDefaultMerge<ListSavedQueriesResponse>('savedQueries'),
      },
      dataLakeQueries: {
        keyArgs: (): [
          keyof QueryDataLakeQueriesArgs,
          FixedLengthArray<keyof DataLakeQueriesInput, 7>
        ] => [
          'input',
          [
            'kind',
            'contains',
            'status',
            'issuedBy',
            'startedAtBefore',
            'startedAtAfter',
            'sortDir',
          ],
        ],
        read(existing) {
          return existing;
        },
        merge: endlessPaginationDefaultMerge<DataLakeQueriesOutput>(
          'edges',
          variables => variables.input?.cursor
        ),
      },
      listLookups: {
        keyArgs: (): [keyof QueryListLookupsArgs, FixedLengthArray<keyof ListLookupsInput, 2>] => [
          'input',
          ['managed', 'importMethods'],
        ],
        read(existing) {
          return existing;
        },
        merge: endlessPaginationDefaultMerge<ListLookupResponse>('lookups'),
      },
      holdingTankRawData: {
        keyArgs: (): [
          keyof QueryHoldingTankRawDataArgs,
          FixedLengthArray<keyof HoldingTankRawDataInput, 3>
        ] => ['input', ['sourceId', 'streamType', 'filters']],
        read(existing) {
          return existing;
        },
        merge: endlessPaginationDefaultMerge<HoldingTankRawDataResponse>(
          'data',
          variables => variables.input.paginationToken
        ),
      },
      holdingTankTestSchemasResult: {
        keyArgs: (): [
          keyof QueryHoldingTankTestSchemasResultArgs,
          FixedLengthArray<keyof HoldingTankTestSchemasResultInput, 3>
        ] => ['input', ['taskId', 'logType', 'prefix']],
        read(existing) {
          return existing;
        },
        merge: endlessPaginationDefaultMerge<HoldingTankTestSchemasResultsResponse>(
          'results',
          variables => variables.input.paginationToken
        ),
      },
      replayedAlerts: {
        keyArgs: (): [
          keyof QueryReplayedAlertsArgs,
          FixedLengthArray<keyof ReplayedAlertsInput, 10>
        ] => [
          'input',
          [
            'types',
            'sortDir',
            'replayId',
            'logTypes',
            'severities',
            'nameContains',
            'eventCountMin',
            'eventCountMax',
            'createdAtAfter',
            'createdAtBefore',
          ],
        ],
        read(existing) {
          return existing;
        },
        merge: endlessPaginationDefaultMerge<ReplayedAlertsOutput>(
          'alerts',
          variables => variables.input?.cursor
        ),
      },
    },
  },
  Destination: {
    keyFields: ['outputId'],
    fields: {
      defaultForSeverity: {
        read(existing: Severity[]) {
          return existing ? sortSeverities(existing) : existing;
        },
      },
    },
  },
  Alert: {
    keyFields: ['id'],
    fields: {
      origin: {
        merge(existing, incoming) {
          return existing ? { ...existing, ...incoming } : incoming;
        },
      },
    },
  },
  AlertOriginRule: {
    keyFields: false,
    fields: {
      events: {
        keyArgs: false,
        merge: endlessPaginationDefaultMerge<AlertEvents>(
          'items',
          variables => variables.eventsInput?.exclusiveStartKey
        ),
      },
    },
  },
  AlertOriginReplayedRule: {
    keyFields: false,
    fields: {
      events: {
        keyArgs: false,
        merge: endlessPaginationDefaultMerge<AlertEvents>(
          'items',
          variables => variables.eventsInput?.exclusiveStartKey
        ),
      },
    },
  },
  AvailableLogProvider: {
    keyFields: ['id'],
    fields: {
      details: {
        merge: false,
      },
      availableParsers: {
        merge: false,
      },
    },
  },
  AlertOriginSystemError: {
    keyFields: false,
    fields: {
      events: {
        keyArgs: false,
        merge: endlessPaginationDefaultMerge<AlertEvents>(
          'items',
          variables => variables.eventsInput?.exclusiveStartKey
        ),
      },
      alerts: {
        keyArgs: false,
        merge: endlessPaginationDefaultMerge<RelatedAlertsOutput>(
          'edges',
          variables => variables.input?.cursor
        ),
      },
    },
  },
  ComplianceItem: {
    keyFields: ['policyId', 'resourceId'],
  },
  SavedQuery: {
    fields: {
      defaultDatabase: {
        read: defaultDatabase => {
          // NOTE: When defaultDatabase is '' we consider it null
          if (defaultDatabase === '') {
            return null;
          }
          return defaultDatabase;
        },
      },
    },
  },
  SchemaRecord: {
    keyFields: ['name'],
  },
  ComplianceIntegration: {
    keyFields: ['integrationId'],
  },
  S3LogIntegration: {
    keyFields: ['integrationId'],
  },
  CloudWatchLogIntegration: {
    keyFields: ['integrationId'],
  },
  EventBridgeIntegration: {
    keyFields: ['integrationId'],
  },
  LogPullingIntegration: {
    keyFields: ['integrationId'],
  },
  DataLakeDatabase: {
    keyFields: ['name'],
  },
  DataLakeDatabaseTable: {
    keyFields: ['name', 'databaseName'],
  },
  DataLakeQuery: {
    keyFields: ['id'],
    fields: {
      results: {
        keyArgs: (args, { variables }) => {
          return variables.id;
        },
      },
    },
  },
  SqsLogSourceIntegration: {
    keyFields: ['integrationId'],
  },
  GcsLogSourceIntegration: {
    keyFields: ['integrationId'],
  },
  GeneralSettingsConfig: {
    keyFields: ['__typename'],
    fields: {
      analyticsConsent: {
        merge(_, incoming) {
          storage.local.write(ANALYTICS_CONSENT_STORAGE_KEY, incoming);
          return incoming;
        },
      },
    },
  },
  SamlConfig: {
    keyFields: ['__typename'],
  },
  Role: {
    fields: {
      permissions: {
        read(existing: Permission[]) {
          return existing ? expandPermissions(existing) : existing;
        },
      },
    },
  },
  APIToken: {
    fields: {
      permissions: {
        read(existing: Permission[]) {
          return existing ? expandPermissions(existing) : existing;
        },
      },
    },
  },
  DetectionTestDefinition: {
    keyFields: false,
  },
};

export default (typePolicies as unknown) as ApolloTypePolicies;
