/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Text } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';
import urls from 'Source/urls';
import { SourceIntegration } from 'Pages/Integrations/LogSources/LogSourceDetailsPage/components/InformationPanel';
import { LogTransportMethodsEnum } from 'Source/constants';
import { Permission, S3LogIntegration } from 'Generated/schema';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import EmptyDataFallback from './EmptyDataFallback';
import LogTypeCard from './LogTypeCard';
import S3PrefixesLogTypes from './S3PrefixesLogTypes';

const SchemasPanel = ({ source }: { source: SourceIntegration }) => {
  const { logTypes, integrationId: sourceId, integrationType } = source;
  const isS3 = integrationType === LogTransportMethodsEnum.s3;

  if (isS3) {
    const s3Source = source as S3LogIntegration;
    if (logTypes.length === 0) {
      return <EmptyDataFallback sourceId={sourceId} />;
    }
    return (
      <>
        <Flex justify="space-between" align="center">
          <Text fontSize="large">Attached Schemas</Text>
          {source.isEditable && (
            <RoleRestrictedAccess
              allowedPermissions={[Permission.LogSourceRawDataRead, Permission.LogSourceModify]}
            >
              <Box
                as={RRLink}
                px={4}
                py={2}
                borderRadius="small"
                fontSize="medium"
                backgroundColor="navyblue-200"
                aria-label={`link to schema`}
                to={`${urls.integrations.logSources.schemasManagement(sourceId)}`}
              >
                Edit Schemas
              </Box>
            </RoleRestrictedAccess>
          )}
        </Flex>
        {s3Source.s3PrefixLogTypes.map((s3PrefixLogTypesElement, i) => {
          return (
            <Box backgroundColor="navyblue-500" key={i} pb={4} mt={4}>
              <S3PrefixesLogTypes sourceId={sourceId} {...s3PrefixLogTypesElement} />
            </Box>
          );
        })}
      </>
    );
  }

  return (
    <>
      <Text fontSize="large">Attached Schemas</Text>
      <Box mt={4} minHeight={200}>
        {logTypes.map(logType => (
          <LogTypeCard key={logType} sourceId={sourceId} logType={logType} />
        ))}
      </Box>
    </>
  );
};

export default SchemasPanel;
