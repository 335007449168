/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { Breadcrumbs as PounceBreadcrumbs, Img } from 'pouncejs';
import React from 'react';
import { isGuid, capitalize, shortenId, isHash } from 'Helpers/utils';
import { Link as RRLink } from 'react-router-dom';
import useRouter from 'Hooks/useRouter';
import { getUrlWithDefaultParams } from 'Source/urls';
import Portal from 'Components/utils/Portal';
import { ARN_REGEX, LogTransportMethodsEnum } from 'Source/constants';
import PantherIcon from 'Assets/panther-minimal-logo.svg';
import { LogPullerTypeEnum } from 'Generated/schema';

const shortenHashId = (text: string) =>
  isHash(text.toLowerCase()) ? shortenId(text).toLowerCase() : text;

export const customPathNames = {
  [LogTransportMethodsEnum.s3]: 'AWS S3',
  [LogTransportMethodsEnum.sqs]: 'AWS SQS',
  [LogTransportMethodsEnum.cloudwatch]: 'AWS CloudWatch Logs',
  [LogTransportMethodsEnum.gcs]: 'Google Cloud Storage',
  [LogPullerTypeEnum.M365]: 'Microsoft',
  [LogPullerTypeEnum.Onepassword]: '1Password',
  [LogPullerTypeEnum.Gsuite]: 'Google Workspace',
  'api-tokens': 'API Tokens',
  'mitre-attack': 'MITRE ATT&CK®',
};

export const parseBreadcrumbText = (text: string) =>
  text
    .replace(/([-_])+/g, ' ')
    .replace(/ and /g, ' & ')
    .split(' ')
    .map(capitalize)
    .map(shortenHashId)
    .join(' ');

const generateBreadcrumbText = (pathKey: string) => {
  const uriDecodedPathKey = decodeURIComponent(pathKey);
  const isPathKeyArn = Boolean(uriDecodedPathKey.match(ARN_REGEX));
  const isCustomPath = Object.keys(customPathNames).includes(pathKey);
  const shouldParseBreadcrumb = !isPathKeyArn && !isCustomPath;

  if (isCustomPath) {
    return customPathNames[pathKey];
  }
  if (shouldParseBreadcrumb) {
    return parseBreadcrumbText(uriDecodedPathKey);
  }

  return uriDecodedPathKey;
};

interface BreadcrumbComposition {
  Actions: React.FC;
}

const Breadcrumbs: React.FC & BreadcrumbComposition = () => {
  const {
    location: { pathname },
  } = useRouter();

  const fragments = React.useMemo(() => {
    // split by slash and remove empty-splitted values caused by trailing slashes. We also don't
    // want to display the UUIDs as part of the breadcrumbs (which unfortunately exist in the URL)
    const pathKeys = pathname.split('/').filter(fragment => !!fragment && !isGuid(fragment));

    // return the label (what to show) and the uri of each fragment. The URI is constructed by
    // taking the existing path and removing whatever is after each pathKey (only keeping whatever
    // is before-and-including our key). The key is essentially the URL path itself just prettified
    // for display
    if (!pathKeys.length) {
      return [];
    }

    return [
      {
        href: '/',
        children: (
          <Img src={PantherIcon} nativeWidth={18} nativeHeight={18} alt="Home" marginTop="6px" />
        ),
      },
      ...pathKeys.map(key => ({
        href: getUrlWithDefaultParams(
          `${pathname.substr(0, pathname.indexOf(`/${key}/`))}/${key}/`
        ),
        children: generateBreadcrumbText(key),
      })),
    ];
  }, [pathname]);
  return <PounceBreadcrumbs items={fragments} as={RRLink} />;
};

const BreadcrumbActions: React.FC = ({ children }) => {
  return <Portal selector="#main-header">{children}</Portal>;
};

Breadcrumbs.Actions = React.memo(BreadcrumbActions);

export default Breadcrumbs;
