/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';

import SuccessStatus from 'Assets/statuses/illustration-success.svg';
import EventThresholdAlarmConfigurator from 'Components/EventThresholdAlarmConfigurator';
import { useWizardContext, WizardPanel } from 'Components/Wizard';
import { UnhealthyPanel } from 'Components/SourceVerificationPanels';
import { S3LogSourceWizardValues } from 'Pages/Integrations/LogSources/S3/S3LogSourceWizard/S3LogSourceWizard';
import useGetLogSourceHealthMetrics, { excludedMetrics } from 'Hooks/useGetLogSourceHealthMetrics';
import { useFormikContext } from 'formik';
import { extractErrorMessage } from 'Helpers/utils';
import { Alert, Box, Flex, Img, Spinner } from 'pouncejs';
import { useGetS3LogSource } from 'Source/graphql/queries/getS3LogSource.generated';
import FinishSourceSetupActions from 'Pages/Integrations/components/FinishSourceSetupActions';

const HealthCheckPanel = () => {
  const { data: wizardData } = useWizardContext<{ integrationId?: string }>();
  const { initialValues } = useFormikContext<S3LogSourceWizardValues>();
  const { data, loading, error, refetch } = useGetS3LogSource({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { id: wizardData.integrationId },
  });

  const { unhealthyMetrics } = useGetLogSourceHealthMetrics(data?.getS3LogIntegration);
  const filteredMetrics = unhealthyMetrics.filter(metric => !excludedMetrics.includes(metric.type));

  if (loading) {
    return (
      <Flex align="center" justify="center" height={380}>
        <Spinner />
      </Flex>
    );
  }

  if (error || !data) {
    return (
      <Flex align="center" justify="center" height={380}>
        <Alert
          variant="error"
          title="Couldn't fetch health metrics"
          description={
            extractErrorMessage(error) || 'Failed to get the health details of the S3 Log source'
          }
        />
      </Flex>
    );
  }

  const source = data.getS3LogIntegration;

  if (!source.isHealthy && filteredMetrics.length > 0) {
    return <UnhealthyPanel unhealthyMetrics={filteredMetrics} onRetry={() => refetch()} />;
  }

  return (
    <WizardPanel>
      <Flex align="center" direction="column" mx="auto" width={620}>
        <Box width={400}>
          <WizardPanel.Heading
            title="Everything looks good!"
            subtitle={
              initialValues.integrationId
                ? 'Your configured stack was deployed successfully and your source’s setup is now complete!'
                : 'Your configured stack was deployed successfully and Panther now has permissions to pull data!'
            }
            divider={null}
            subtitleProps={{ color: 'gray-300', fontWeight: 'normal' }}
          />
        </Box>
        <Img
          nativeWidth={157}
          nativeHeight={118}
          mb={8}
          alt="Stack deployed successfully"
          src={SuccessStatus}
        />
        <EventThresholdAlarmConfigurator logSource={source} />

        <WizardPanel.Actions>
          <FinishSourceSetupActions
            integrationId={source.integrationId}
            hasSchemas={source.logTypes.length > 0}
          />
        </WizardPanel.Actions>
      </Flex>
    </WizardPanel>
  );
};

export default HealthCheckPanel;
