/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import queryString from 'query-string';
import { Box, Divider, Flex, Icon, IconProps, Img, Link } from 'pouncejs';
import urls from 'Source/urls';
import { Link as RRLink } from 'react-router-dom';

import PantherEnterpriseLogo from 'Assets/panther-plain-logo.svg';
import { PANTHER_DOCS_LINK } from 'Source/constants';
import { Permission } from 'Generated/schema';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import BetaTag from 'Components/BetaTag';
import useRouter from 'Hooks/useRouter';
import DetectionsNavigation from 'Components/Navigation/SecondaryNavigations/DetectionsNavigation';
import NavLink from './NavLink';
import NavGroup from './NavGroup';
import ProfileInfo from './ProfileInfo';
import {
  AnalysisNavigation,
  AnalysisNavigationLinks,
  DataEnrichmentNavigation,
  DataEnrichmentNavLinks,
  DataNavigation,
  DataNavLinks,
  DetectionsNavigationLinks,
  IntegrationNavigationsLinks,
  IntegrationsNavigation,
  SettingsNavigation,
  SettingsNavLinks,
} from './SecondaryNavigations';

const DETECTIONS_NAV_KEY = 'detections';
const ANALYSIS_NAV_KEY = 'analysis';
const INTEGRATIONS_NAV_KEY = 'integrations';
const SETTINGS_NAV_KEY = 'settings';
const DATA_NAV_KEY = 'data';
const ENRICHMENT_NAV_KEY = 'enrichment';

export type NavigationLink = {
  to: string;
  icon: IconProps['type'];
  label: string;
  permissions?: Permission[];
};

type NavKeys =
  | typeof DETECTIONS_NAV_KEY
  | typeof ANALYSIS_NAV_KEY
  | typeof SETTINGS_NAV_KEY
  | typeof DATA_NAV_KEY
  | typeof INTEGRATIONS_NAV_KEY
  | typeof ENRICHMENT_NAV_KEY;

const Navigation = () => {
  const {
    location: { pathname },
  } = useRouter();

  // Normally we woulnd't be neeeding the code below in a separate function. It would just be inside
  // a `React.useEffect`. We add this here cause it's important to give React.useState the proper
  // initial value, so that the animation of the Navbar doesn't kick on the initial render. If it
  // wasn't for that, we wouldn't have "abstracted" this function here and we would just have an
  // initial value of `null` which would instantly be updated from the code in `React.useEffect`
  const getSecondaryNavKey = () => {
    const pathIncludesNav = (navLink: NavigationLink) =>
      pathname.includes(queryString.parseUrl(navLink.to).url);
    const isUnderDetectionsNav = DetectionsNavigationLinks.some(pathIncludesNav);
    const isUnderAnalysisNav = AnalysisNavigationLinks.some(pathIncludesNav);
    const isUnderSettingsNav = SettingsNavLinks.some(pathIncludesNav);
    const isUnderDataNav = DataNavLinks.some(pathIncludesNav);
    const isUnderIntegrationsNav = IntegrationNavigationsLinks.some(pathIncludesNav);
    const isUnderDataEnrichmentNav = DataEnrichmentNavLinks.some(pathIncludesNav);

    if (isUnderDetectionsNav) {
      return DETECTIONS_NAV_KEY;
    }
    if (isUnderAnalysisNav) {
      return ANALYSIS_NAV_KEY;
    }
    if (isUnderIntegrationsNav) {
      return INTEGRATIONS_NAV_KEY;
    }
    if (isUnderSettingsNav) {
      return SETTINGS_NAV_KEY;
    }
    if (isUnderDataNav) {
      return DATA_NAV_KEY;
    }
    if (isUnderDataEnrichmentNav) {
      return ENRICHMENT_NAV_KEY;
    }
    return null;
  };

  const [secondaryNav, setSecondaryNav] = React.useState<NavKeys>(getSecondaryNavKey());

  React.useEffect(() => {
    setSecondaryNav(getSecondaryNavKey());
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const isDetectionsNavigationActive = secondaryNav === DETECTIONS_NAV_KEY;
  const isAnalysisNavigationActive = secondaryNav === ANALYSIS_NAV_KEY;
  const isDataAnalyticsNavigationActive = secondaryNav === DATA_NAV_KEY;
  const isIntegrationsNavigationActive = secondaryNav === INTEGRATIONS_NAV_KEY;
  const isSettingsNavigationActive = secondaryNav === SETTINGS_NAV_KEY;
  const isDataEnrichmentNavigationActive = secondaryNav === ENRICHMENT_NAV_KEY;

  return (
    <Flex
      as="aside"
      boxShadow="dark50"
      position="sticky"
      top={0}
      zIndex={10}
      minWidth="min-content"
      height="100vh"
      backgroundColor="navyblue-700"
      overflowY="auto"
      // This CSS property removes layout shifts when the scrollbar is not displayed.
      // At the time of writing it is only implemented in Chromium-based browsers, but that's
      // a large amount of users that gets this small enhancement.
      // https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-gutter
      //
      // `scrollbar-gutter` is not supported by `pounce` props or in the `sx` prop, so applying it
      // with inline style is the only way to go.
      style={{ scrollbarGutter: 'stable both-edges' }}
    >
      <Flex as="nav" direction="column" width={220} height="100%" aria-label="Main" pb={2}>
        <Box as={RRLink} to="/" px={4} pb={3} pt={8}>
          <Img
            src={PantherEnterpriseLogo}
            alt="Panther logo"
            nativeWidth="auto"
            nativeHeight={32}
            display="block"
          />
        </Box>
        <Flex direction="column" as="ul" flex="1 0 auto" px={4}>
          <Divider width="100%" color="navyblue-300" />
          <RoleRestrictedAccess allowedPermissions={[Permission.SummaryRead]}>
            <Box as="li" mb={2}>
              <NavLink
                icon="dashboard-alt"
                to={`${urls.overview.home()}?tab=alerts`}
                label="Overview"
              />
            </Box>
          </RoleRestrictedAccess>
          <RoleRestrictedAccess allowedPermissions={[Permission.AlertRead, Permission.AlertModify]}>
            <Box as="li" mb={2}>
              <NavLink
                icon="alert-circle"
                to={urls.alerts.list({ disableDefaultParams: false })}
                label="Alerts & Errors"
              />
            </Box>
          </RoleRestrictedAccess>
          <RoleRestrictedAccess
            allowedPermissions={[
              Permission.PolicyRead,
              Permission.PolicyModify,
              Permission.RuleRead,
              Permission.RuleModify,
            ]}
          >
            <Box as="li" mb={2}>
              <NavGroup
                active={isDetectionsNavigationActive}
                icon="detection"
                label="Detections"
                onSelect={() =>
                  setSecondaryNav(isDetectionsNavigationActive ? null : DETECTIONS_NAV_KEY)
                }
              >
                <DetectionsNavigation />
              </NavGroup>
            </Box>
          </RoleRestrictedAccess>
          <RoleRestrictedAccess allowedPermissions={[Permission.SummaryRead]}>
            <Box as="li" mb={2}>
              <NavGroup
                active={isAnalysisNavigationActive}
                icon="log-analysis"
                label="Analysis"
                onSelect={() =>
                  setSecondaryNav(isAnalysisNavigationActive ? null : ANALYSIS_NAV_KEY)
                }
              >
                <AnalysisNavigation />
              </NavGroup>
            </Box>
          </RoleRestrictedAccess>

          <RoleRestrictedAccess
            allowedPermissions={[
              Permission.DataAnalyticsRead,
              Permission.ResourceRead,
              Permission.ResourceModify,
            ]}
          >
            <Box as="li" mb={2}>
              <NavGroup
                active={isDataAnalyticsNavigationActive}
                icon="data"
                label="Data"
                onSelect={() =>
                  setSecondaryNav(isDataAnalyticsNavigationActive ? null : DATA_NAV_KEY)
                }
              >
                <DataNavigation />
              </NavGroup>
            </Box>
          </RoleRestrictedAccess>

          <RoleRestrictedAccess
            allowedPermissions={[Permission.LookupRead, Permission.LookupModify]}
          >
            <Box as="li" mb={2}>
              <NavGroup
                active={isDataEnrichmentNavigationActive}
                icon="enrichment"
                label={
                  <Flex alignItems="center">
                    Enrichment
                    <Box ml="2" pointerEvents="none">
                      <BetaTag size="small" variant="border" />
                    </Box>
                  </Flex>
                }
                onSelect={() =>
                  setSecondaryNav(isDataEnrichmentNavigationActive ? null : ENRICHMENT_NAV_KEY)
                }
              >
                <DataEnrichmentNavigation />
              </NavGroup>
            </Box>
          </RoleRestrictedAccess>

          <RoleRestrictedAccess
            allowedPermissions={[
              Permission.LogSourceRead,
              Permission.LogSourceModify,
              Permission.CloudsecSourceRead,
              Permission.CloudsecSourceModify,
              Permission.DestinationRead,
              Permission.DestinationModify,
            ]}
          >
            <Box as="li" mb={2}>
              <NavGroup
                active={isIntegrationsNavigationActive}
                icon="integrations"
                label="Integrations"
                onSelect={() =>
                  setSecondaryNav(isIntegrationsNavigationActive ? null : INTEGRATIONS_NAV_KEY)
                }
              >
                <IntegrationsNavigation />
              </NavGroup>
            </Box>
          </RoleRestrictedAccess>

          <RoleRestrictedAccess
            allowedPermissions={[
              Permission.UserRead,
              Permission.UserModify,
              Permission.GeneralSettingsRead,
              Permission.GeneralSettingsModify,
              Permission.PolicyRead,
              Permission.PolicyModify,
              Permission.RuleRead,
              Permission.RuleModify,
              Permission.OrganizationApiTokenRead,
              Permission.OrganizationApiTokenModify,
            ]}
          >
            <Box as="li" mb={2}>
              <NavGroup
                active={isSettingsNavigationActive}
                icon="settings-alt"
                label="Settings"
                onSelect={() =>
                  setSecondaryNav(isSettingsNavigationActive ? null : SETTINGS_NAV_KEY)
                }
              >
                <SettingsNavigation />
              </NavGroup>
            </Box>
          </RoleRestrictedAccess>
          <Box as="li" mt="auto">
            <Link external href={PANTHER_DOCS_LINK}>
              <Flex
                align="center"
                fontSize="medium"
                fontWeight="normal"
                borderRadius="small"
                px={4}
                py={3}
                color="white-200"
                _hover={{
                  color: 'white-200',
                  backgroundColor: 'navyblue-500',
                }}
              >
                <Icon type="docs" size="medium" mr={3} />
                <Box>Documentation</Box>
              </Flex>
            </Link>
          </Box>
          <Box as="li">
            {/* Icon in Navlink has margin left eqaul to 3 and Navlink container has padding left equal to 4.
           Solve alignment by passing pl={1} property to NavLink container */}
            <NavLink icon="help" label="Support" to={urls.account.support()} pl={1} />
          </Box>
        </Flex>

        <Box p={4} backgroundColor="navyblue-800" mt={4}>
          <ProfileInfo />
        </Box>
      </Flex>
    </Flex>
  );
};

export default React.memo(Navigation);
