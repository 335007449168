/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { GetMitreDetections } from 'Pages/Reports/MitreMatrix/graphql/getMitreDetections.generated';
import { Alert, Box, Flex, IconButton, Link, Text } from 'pouncejs';
import { RuleSummary } from 'Source/graphql/fragments/RuleSummary.generated';
import { isEmpty, without } from 'lodash';
import urls from 'Source/urls';
import { Link as RRLink } from 'react-router-dom';
import { DetectionTypeEnum } from 'Generated/schema';
import { useMitreContext } from '../MitreContext';

type ReportSettingDetection = GetMitreDetections['reportSetting']['detections'][0];

interface LogTypeIssue {
  detection: ReportSettingDetection;
  missingTypes: string[];
}

interface MitreRelationsDetectionWarningsProps {
  detections: ReportSettingDetection[];
}

const MitreRelationsDetectionWarnings = ({ detections }: MitreRelationsDetectionWarningsProps) => {
  const [detailsOpen, setDetailsOpen] = React.useState(true);
  const { enabledLogTypes } = useMitreContext();
  const disabledDetections = detections.filter(d => !d.enabled);

  const logTypeIssues: LogTypeIssue[] = detections
    .filter(d => d.analysisType === DetectionTypeEnum.Rule)
    .map(d => {
      return {
        detection: d,
        missingTypes: without((d as RuleSummary).logTypes, ...enabledLogTypes),
      };
    })
    .filter(({ missingTypes }) => !isEmpty(missingTypes));

  const hasDisabledDetections = !isEmpty(disabledDetections);
  const hasMissingLogTypes = !isEmpty(logTypeIssues);
  const warningCount = (hasDisabledDetections ? 1 : 0) + (hasMissingLogTypes ? 1 : 0);

  if (warningCount === 0) {
    return null;
  }

  return (
    <Box px={4} py={4}>
      <Alert
        title={
          <Text fontSize="large" fontWeight="bold">
            Configuration issues may affect coverage
          </Text>
        }
        variant="warning"
        actions={
          <IconButton
            icon={detailsOpen ? 'caret-up' : 'caret-down'}
            size="medium"
            variant="ghost"
            title={detailsOpen ? 'hide details' : 'show details'}
            aria-label={detailsOpen ? 'hide details' : 'show details'}
            variantColor="yellow-300"
            onClick={() => setDetailsOpen(!detailsOpen)}
          />
        }
      />
      {detailsOpen && (
        <Flex spacing={4} direction="column" pt={4} ml={4} pb={2}>
          {hasDisabledDetections && (
            <Box>
              <Text as="h4" mb={2}>
                Some detections are disabled. Enable them now to ensure partial coverage.
              </Text>
              <Box ml={4} as="ul">
                {disabledDetections.map(detection => (
                  <Text
                    as="li"
                    mb={1}
                    key={detection.id}
                    lineHeight="relaxed"
                    fontFamily="monospace"
                  >
                    <Link
                      as={RRLink}
                      to={urls.detections.details(detection.id, detection.analysisType)}
                      variant="neutral"
                    >
                      {detection.displayName || detection.id}
                    </Link>{' '}
                    is disabled
                  </Text>
                ))}
              </Box>
            </Box>
          )}
          {hasMissingLogTypes && (
            <Box>
              <Text as="h4" mb={2}>
                Some log types associated with the detection(s) are not set up.{' '}
                <Link as={RRLink} to={urls.integrations.logSources.create()}>
                  Onboard them now
                </Link>{' '}
                to ensure partial coverage.
              </Text>
              <Box ml={4} as="ul">
                {logTypeIssues.map(({ detection, missingTypes }) => (
                  <Text
                    key={detection.id}
                    as="li"
                    mb={1}
                    lineHeight="relaxed"
                    fontFamily="monospace"
                  >
                    <Link
                      as={RRLink}
                      to={urls.detections.details(detection.id, detection.analysisType)}
                      variant="neutral"
                    >
                      {detection.displayName || detection.id}
                    </Link>{' '}
                    is missing:{' '}
                    <Text as="span" fontFamily="">
                      {missingTypes.join(', ')}
                    </Text>
                  </Text>
                ))}
              </Box>
            </Box>
          )}
        </Flex>
      )}
    </Box>
  );
};

export default MitreRelationsDetectionWarnings;
