/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Text, SimpleGrid, Flex, FormHelperText } from 'pouncejs';
import * as Yup from 'yup';
import { Formik, FastField } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import SubmitButton from 'Components/buttons/SubmitButton';
import { DATA_SCHEMA_ID_PREFIX, dataSchemaNameValidation } from 'Helpers/utils';
import FormikTextArea from 'Components/fields/TextArea';

interface InferSchemaFormProps {
  initialPrefix: string;
  availablePrefixes: string[];
  availableLogTypes: string[];
  onSubmit: (values: NewSchemaFormValues) => void;
}

export interface NewSchemaFormValues {
  name: string;
  prefixes: string[];
  description: string;
  referenceURL: string;
}

const InferSchemaForm: React.FC<InferSchemaFormProps> = ({
  initialPrefix,
  availablePrefixes = [],
  availableLogTypes,
  onSubmit,
}) => {
  const initialValues: NewSchemaFormValues = {
    prefixes: [initialPrefix],
    name: '',
    description: '',
    referenceURL: '',
  };

  const validationSchema = Yup.object({
    prefixes: Yup.array(Yup.string()).min(1).required(),
    name: dataSchemaNameValidation(availableLogTypes),
    description: Yup.string(),
    referenceURL: Yup.string().url(),
  });

  return (
    <Formik<NewSchemaFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <SimpleGrid columns={1} spacing={5}>
        <Text fontSize="x-large">
          To infer a new schema please provide the following information
        </Text>
        <Box>
          <FastField
            as={FormikMultiCombobox}
            label="Select Prefixes"
            name="prefixes"
            items={availablePrefixes}
            itemToString={item => (item === '' ? '*' : item)}
            allowAdditions
            searchable
            aria-describedby="prefixes-helper"
          />
          <FormHelperText id="prefixes-helper" fontSize="small-medium" color="navyblue-100" mt={1}>
            Select from already used prefixes or type and enter to create a new one
          </FormHelperText>
        </Box>
        <FastField
          as={FormikTextInput}
          name="name"
          label="Schema Name"
          prefix={DATA_SCHEMA_ID_PREFIX}
          placeholder={`Must start with \`${DATA_SCHEMA_ID_PREFIX}\` followed by a capital letter`}
          required
        />
        <FastField
          as={FormikTextInput}
          name="referenceURL"
          label="Reference URL - optional"
          placeholder="The URL to the log's schema documentation"
        />
        <Box mb={6}>
          <FastField
            as={FormikTextArea}
            name="description"
            label="Description - optional"
            placeholder="A verbose description of what this log type does"
          />
        </Box>
        <Flex justify="center">
          <SubmitButton variantColor="blue-300">Apply Changes</SubmitButton>
        </Flex>
      </SimpleGrid>
    </Formik>
  );
};

export default InferSchemaForm;
