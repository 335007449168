/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { Text, Box, Flex, Grid, Table, Alert, Button, Img, Heading } from 'pouncejs';
import { useViewRawData } from 'Pages/Integrations/LogSources/S3/SchemasManagement/graphql/viewRawData.generated';
import useRouter from 'Hooks/useRouter';
import { CollapsableCell } from 'Components/forms/DataSchemaForm/SampleDataSection/common';
import ViewRawDataFilters from 'Pages/Integrations/LogSources/S3/SchemasManagement/ViewRawData/ViewRawDataFilters/ViewRawDataFilters';
import TablePlaceholder from 'Components/TablePlaceholder';
import { useSchemasManagementContext } from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagementContext/SchemasManagementContext';
import ErrorBoundary from 'Components/ErrorBoundary';
import useInfiniteScroll from 'Hooks/useInfiniteScroll';
import { appendDataSchemaPrefix, capitalize, extractErrorMessage } from 'Helpers/utils';
import { DEFAULT_SMALL_PAGE_SIZE, ENABLE_HOLDING_TANK } from 'Source/constants';
import CollapsablePanel from 'Components/CollapsablePanel';
import { useFormikContext } from 'formik';
import { S3PrefixLogTypesFormValues } from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagement';
import SetS3PrefixesModal from 'Components/modals/SetS3PrefixesModal';
import useModal from 'Hooks/useModal';
import NoDataFoundIllustration from 'Assets/illustrations/charts.svg';
import InferSchemaButton from './InferSchemaButton/InferSchemaButton';

const ViewRawData: React.FC = () => {
  const { values, setValues } = useFormikContext<S3PrefixLogTypesFormValues>();
  const [inferJobError, setInferJobError] = React.useState('');
  const { showModal } = useModal();
  const { match } = useRouter<{ id: string }>();
  const { rawDataFilters } = useSchemasManagementContext();

  const { loading, error, previousData, data = previousData, fetchMore, refetch } = useViewRawData({
    variables: {
      input: {
        sourceId: match.params.id,
        filters: {
          ...rawDataFilters,
        },
        pageSize: DEFAULT_SMALL_PAGE_SIZE,
        streamType: values.logStreamType,
      },
    },
  });

  const hasError = Boolean(error);
  const rawData = data?.holdingTankRawData?.data;
  const paginationToken = data?.holdingTankRawData?.pageInfo?.paginationToken;
  const hasNextPage = Boolean(data?.holdingTankRawData?.pageInfo?.hasNextPage);

  const onEditStreamType = React.useCallback(() => {
    const onModalSubmit = (submittedValues: S3PrefixLogTypesFormValues) => {
      setValues(submittedValues);
    };
    showModal(
      <SetS3PrefixesModal
        initialValues={values}
        draftSchemaNames={values.draftSchemas.map(s => appendDataSchemaPrefix(s.name))}
        onSuccess={onModalSubmit}
      />,
      {
        title: 'Stream Type & Schemas',
        showCloseButton: true,
      }
    );
  }, [showModal, values, setValues]);

  const { sentinelRef } = useInfiniteScroll<HTMLDivElement>({
    loading,
    threshold: 500,
    onLoadMore: () => {
      fetchMore({
        variables: {
          input: {
            sourceId: match.params.id,
            filters: {
              ...rawDataFilters,
            },
            pageSize: DEFAULT_SMALL_PAGE_SIZE,
            streamType: values.logStreamType,
            paginationToken,
          },
        },
      });
    },
  });

  if (hasError) {
    const errMsg = extractErrorMessage(error);
    const isStreamTypeError = errMsg && errMsg.startsWith('Invalid stream');
    return (
      <CollapsablePanel title="Raw Events">
        <Flex direction="column" spacing={2}>
          <ViewRawDataFilters />
          <Box my={6}>
            {isStreamTypeError ? (
              <Alert
                variant="error"
                title="Couldn't load your data"
                description={
                  <Flex justify="space-between" align="center" spacing={4}>
                    <Text fontWeight="bold">
                      It looks like you have selected the wrong stream type and we cannot display
                      your data properly. Change the stream type and try again
                    </Text>
                    <Button
                      variant="outline"
                      variantColor="red-300"
                      size="small"
                      onClick={onEditStreamType}
                    >
                      Edit Stream Type
                    </Button>
                  </Flex>
                }
              />
            ) : (
              <Alert
                variant="error"
                title="Couldn't load your data"
                description={
                  extractErrorMessage(error) ||
                  'There was an error when performing your request, please contact support@runpanther.io'
                }
              />
            )}
          </Box>
        </Flex>
      </CollapsablePanel>
    );
  }

  return (
    <ErrorBoundary>
      <CollapsablePanel
        title="Raw Events"
        actions={
          ENABLE_HOLDING_TANK ? (
            <InferSchemaButton
              disabled={loading || !rawData.length}
              setInferSchemaError={setInferJobError}
            />
          ) : null
        }
      >
        <Flex direction="column" spacing={2}>
          {Boolean(inferJobError) && (
            <Alert
              variant="error"
              title="Failed to infer schema"
              description={capitalize(inferJobError)}
              discardable
            />
          )}
          <ViewRawDataFilters />
          {loading ? (
            <>
              <TablePlaceholder rowCount={1} rowHeight={10} />
              <TablePlaceholder rowCount={4} rowHeight={40} />
            </>
          ) : (
            <>
              {rawData?.length ? (
                <Grid
                  overflow="auto"
                  maxHeight="max(calc(100vh - 950px),600px)"
                  willChange="scroll"
                >
                  <Table size="small" stickyHeader data-testid="sample-data-table">
                    <Table.Head>
                      <Table.Row>
                        <Box as={Table.HeaderCell} backgroundColor="navyblue-700">
                          Raw Events
                        </Box>
                        <Box as={Table.HeaderCell} backgroundColor="navyblue-700">
                          S3Key
                        </Box>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {rawData.map((log, i) => {
                        return (
                          <Table.Row key={`sample-data-log-${i}`}>
                            <Box as={Table.Cell} width={0.5}>
                              <CollapsableCell
                                text={log.event}
                                hideText="Hide Log"
                                showText="Show Log"
                                sliceOn={300}
                              />
                            </Box>
                            <Box as={Table.Cell}>{log.s3Key}</Box>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                  {hasNextPage && (
                    <Box py={8} ref={sentinelRef}>
                      <TablePlaceholder rowCount={10} />
                    </Box>
                  )}
                </Grid>
              ) : (
                <Box height="400px">
                  <Flex height="100%" direction="column" align="center" justify="center">
                    <Img
                      nativeWidth={80}
                      nativeHeight={90}
                      alt="Charts"
                      src={NoDataFoundIllustration}
                    />
                    <Heading size="x-small" color="navyblue-100" mt={6}>
                      No data found on specified timeframe
                    </Heading>
                    <Box mt={2}>
                      <Button
                        variant="outline"
                        variantColor="navyblue-300"
                        onClick={() => refetch()}
                      >
                        Refresh
                      </Button>
                    </Box>
                  </Flex>
                </Box>
              )}
            </>
          )}
        </Flex>
      </CollapsablePanel>
    </ErrorBoundary>
  );
};

export default ViewRawData;
