/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetOrganizationCloudSecurityStatsVariables = {};

export type GetOrganizationCloudSecurityStats = {
  organizationStats?: Types.Maybe<{
    scannedResources?: Types.Maybe<{
      byType?: Types.Maybe<
        Array<
          Types.Maybe<
            Pick<Types.ScannedResourceStats, 'type'> & {
              count?: Types.Maybe<Pick<Types.ComplianceStatusCounts, 'fail' | 'pass' | 'error'>>;
            }
          >
        >
      >;
    }>;
    appliedPolicies?: Types.Maybe<{
      info?: Types.Maybe<Pick<Types.ComplianceStatusCounts, 'error' | 'pass' | 'fail'>>;
      low?: Types.Maybe<Pick<Types.ComplianceStatusCounts, 'error' | 'pass' | 'fail'>>;
      medium?: Types.Maybe<Pick<Types.ComplianceStatusCounts, 'error' | 'pass' | 'fail'>>;
      high?: Types.Maybe<Pick<Types.ComplianceStatusCounts, 'error' | 'pass' | 'fail'>>;
      critical?: Types.Maybe<Pick<Types.ComplianceStatusCounts, 'error' | 'pass' | 'fail'>>;
    }>;
    topFailingPolicies: Array<Pick<Types.Policy, 'id' | 'severity'>>;
    topFailingResources: Array<Pick<Types.ResourceSummary, 'id'>>;
  }>;
  listComplianceIntegrations: Array<Pick<Types.ComplianceIntegration, 'integrationId'>>;
};

export const GetOrganizationCloudSecurityStatsDocument = gql`
  query GetOrganizationCloudSecurityStats {
    organizationStats(input: { limitTopFailing: 5 }) {
      scannedResources {
        byType {
          type
          count {
            fail
            pass
            error
          }
        }
      }
      appliedPolicies {
        info {
          error
          pass
          fail
        }
        low {
          error
          pass
          fail
        }
        medium {
          error
          pass
          fail
        }
        high {
          error
          pass
          fail
        }
        critical {
          error
          pass
          fail
        }
      }
      topFailingPolicies {
        id
        severity
      }
      topFailingResources {
        id
      }
    }
    listComplianceIntegrations {
      integrationId
    }
  }
`;

/**
 * __useGetOrganizationCloudSecurityStats__
 *
 * To run a query within a React component, call `useGetOrganizationCloudSecurityStats` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationCloudSecurityStats` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationCloudSecurityStats({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationCloudSecurityStats(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetOrganizationCloudSecurityStats,
    GetOrganizationCloudSecurityStatsVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetOrganizationCloudSecurityStats,
    GetOrganizationCloudSecurityStatsVariables
  >(GetOrganizationCloudSecurityStatsDocument, baseOptions);
}
export function useGetOrganizationCloudSecurityStatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetOrganizationCloudSecurityStats,
    GetOrganizationCloudSecurityStatsVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetOrganizationCloudSecurityStats,
    GetOrganizationCloudSecurityStatsVariables
  >(GetOrganizationCloudSecurityStatsDocument, baseOptions);
}
export type GetOrganizationCloudSecurityStatsHookResult = ReturnType<
  typeof useGetOrganizationCloudSecurityStats
>;
export type GetOrganizationCloudSecurityStatsLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationCloudSecurityStatsLazyQuery
>;
export type GetOrganizationCloudSecurityStatsQueryResult = ApolloReactCommon.QueryResult<
  GetOrganizationCloudSecurityStats,
  GetOrganizationCloudSecurityStatsVariables
>;
export function mockGetOrganizationCloudSecurityStats({
  data,
  variables,
  errors,
}: {
  data: GetOrganizationCloudSecurityStats;
  variables?: GetOrganizationCloudSecurityStatsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetOrganizationCloudSecurityStatsDocument, variables },
    result: { data, errors },
  };
}
