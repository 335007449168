/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box } from 'pouncejs';
import { useMitreContext } from 'Pages/Reports/MitreMatrix/MitreContext';
import { findIndex } from 'lodash';

const rowHeight = 36;

const MitreMatrixGridUnderlay = () => {
  const { activeMatrix, activeTactic } = useMitreContext();

  const activeTacticIndex = React.useMemo<number>(
    () => findIndex(activeMatrix?.content.tactics, { id: activeTactic?.id }),
    [activeMatrix, activeTactic]
  );

  const lowlightStyles = React.useMemo(
    () => ({
      top: `${rowHeight * (activeTacticIndex + 1)}px`,
      left: 0,
      right: 0,
      bottom: 0,
      transition: 'top 200ms',
    }),
    [activeTacticIndex]
  );

  return (
    <Box top={0} left={0} right={0} bottom={0} position="absolute" zIndex={0}>
      {activeTactic && (
        <Box
          style={lowlightStyles}
          width="100%"
          height={rowHeight}
          position="absolute"
          backgroundColor="navyblue-600"
        />
      )}
    </Box>
  );
};

export default React.memo(MitreMatrixGridUnderlay);
