/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Button, Divider } from 'pouncejs';
import useGetLogSourceHealthMetrics from 'Hooks/useGetLogSourceHealthMetrics';
import useUrlParams from 'Hooks/useUrlParams';
import urls from 'Source/urls';
import useRouter from 'Hooks/useRouter';
import { capitalize, checkIfSourceIsAuthorized } from 'Helpers/utils';
import {
  LogPullingIntegration,
  S3LogIntegration,
  EventBridgeIntegration,
  CloudWatchLogIntegration,
  SqsLogSourceIntegration,
  GcsLogSourceIntegration,
} from 'Generated/schema';
import { LogTransportMethodsEnum } from 'Source/constants';

export type SourceIntegration =
  | S3LogIntegration
  | LogPullingIntegration
  | EventBridgeIntegration
  | CloudWatchLogIntegration
  | SqsLogSourceIntegration
  | GcsLogSourceIntegration;

const InformationPanel = ({ source }: { source: SourceIntegration }) => {
  const { unhealthyMetrics } = useGetLogSourceHealthMetrics(source);
  const { updateUrlParams } = useUrlParams();
  const { history } = useRouter();

  const alertStyles = { backgroundColor: 'navyblue-600' };
  const emptySchema = !source.logTypes.length;
  const emptyMetrics = !unhealthyMetrics.length;

  // If the source is unauthorised and LogPullingIntegration, we will not display any health error!
  if (source.integrationType === LogTransportMethodsEnum.logPulling) {
    const puller = source as LogPullingIntegration;
    if (!checkIfSourceIsAuthorized(puller)) {
      const sourceType = capitalize(puller.pullerType);
      return (
        <>
          <Box mb={3}>
            <Alert
              variant="error"
              title={`Grant Panther Access to your ${sourceType}`}
              actions={
                <Button
                  variantColor="red-300"
                  size="medium"
                  onClick={() =>
                    history.push(
                      urls.integrations.logSources.authorize(
                        puller.integrationId,
                        puller.pullerType
                      )
                    )
                  }
                >
                  Authorize
                </Button>
              }
              {...alertStyles}
            />
          </Box>

          <Divider my={6} color="navyblue-300" />
        </>
      );
    }
  }

  if (emptyMetrics && !emptySchema) {
    return null;
  }

  return (
    <>
      {emptySchema && (
        <Box mb={3}>
          <Alert
            variant="warning"
            title="There is currently no schema attached to this source so any data coming through cannot be processed."
            actions={
              <Button
                size="medium"
                onClick={() =>
                  history.push(urls.integrations.logSources.schemasManagement(source.integrationId))
                }
              >
                Attach Schema(s)
              </Button>
            }
            {...alertStyles}
          />
        </Box>
      )}
      {unhealthyMetrics.map(({ message }) => (
        <Box key={message} mb={3}>
          <Alert
            variant="error"
            title={message}
            actions={
              <Button
                variantColor="red-300"
                size="medium"
                onClick={() => updateUrlParams({ tab: 'health' })}
              >
                View Errors
              </Button>
            }
            {...alertStyles}
          />
        </Box>
      ))}

      <Divider my={6} color="navyblue-300" />
    </>
  );
};

export default InformationPanel;
