/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex } from 'pouncejs';
import { LogStreamTypeEnum } from 'Generated/schema';
import Editor from 'Components/Editor';

const eventLogs = [
  `{ "ip": "10.0.0.1", "username": "user-1@example.com", "country": "France" }`,
  `{ "ip": "10.0.0.2", "username": "user-2@example.com", "country": "France" }`,
  `{ "ip": "10.0.0.3", "username": "user-3@example.com", "country": "France" }`,
];

const cloudwatchExample = `{
  "owner": "111111111111",
  "logGroup": "services/foo/logs",
  "logStream": "111111111111_CloudTrail/logs_us-east-1",
  "messageType": "DATA_MESSAGE",
  "logEvents": [
      {
          "id": "31953106606966983378809025079804211143289615424298221568",
          "timestamp": 1432826855000,
          "message": "{\\"ip\\": \\"10.0.0.1\\", \\"user\\": \\"user-1@example.com\\", \\"country\\": \\"France\\"}"
      },
      {
          "id": "31953106606966983378809025079804211143289615424298221569",
          "timestamp": 1432826855000,
          "message": "{\\"ip\\": \\"10.0.0.2\\", \\"user\\": \\"user-2@example.com\\", \\"country\\": \\"France\\"}"
      },
      {
          "id": "31953106606966983378809025079804211143289615424298221570",
          "timestamp": 1432826855000,
          "message": "{\\"ip\\": \\"10.0.0.3\\", \\"user\\": \\"user-3@example.com\\", \\"country\\": \\"France\\"}"
      }
  ]
}`;

const streamTypesExample: Record<LogStreamTypeEnum, string> = {
  [LogStreamTypeEnum.CloudWatchLogs]: cloudwatchExample,
  [LogStreamTypeEnum.JsonArray]: `[\n\t${eventLogs.join(',\n\t')}\n]`,
  [LogStreamTypeEnum.Lines]: `${eventLogs.join('\n')}`,
};

const S3StreamTypeHelperModal = ({ streamType }: { streamType: LogStreamTypeEnum }) => {
  const logStreamTypeExample = streamTypesExample[streamType];
  return (
    <Flex
      width={streamType === LogStreamTypeEnum.CloudWatchLogs ? 1025 : 825}
      direction="column"
      spacing={4}
      mt={4}
    >
      <Box fontWeight="medium">Input file</Box>
      <Editor
        readOnly
        minHeight={17}
        hideLineNumbers
        language="json"
        value={logStreamTypeExample || ''}
      />

      <Box fontWeight="medium">Panther Reads</Box>
      <Flex spacing={2} direction="column">
        {eventLogs.map(event => (
          <Editor
            key={event}
            readOnly
            hideLineNumbers
            minHeight={17}
            language="json"
            value={event}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default S3StreamTypeHelperModal;
