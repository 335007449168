/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar, Text } from 'pouncejs';
import { LogIntegration } from 'Generated/schema';
import { useDeleteLogSource } from './graphql/deleteLogSource.generated';
import ConfirmModal from '../ConfirmModal';

export interface DeleteLogSourceModalProps {
  source: Pick<LogIntegration, 'integrationId' | 'integrationLabel'>;
  description?: string | React.ReactNode;
  onSuccess?: () => void;
}

const DeleteLogSourceModal: React.FC<DeleteLogSourceModalProps> = ({
  source,
  description,
  onSuccess = () => {},
}) => {
  const sourceDisplayName = source.integrationLabel;
  const { pushSnackbar } = useSnackbar();
  const [deleteLogSource] = useDeleteLogSource({
    variables: {
      id: source.integrationId,
    },
    update: cache => {
      cache.evict({ id: cache.identify(source) });
      cache.gc();
    },
    onCompleted: () => {
      onSuccess();

      pushSnackbar({
        variant: 'success',
        title: `Successfully deleted source: ${sourceDisplayName}`,
      });
    },
    onError: () => {
      pushSnackbar({
        variant: 'error',
        title: `Failed to delete source: ${sourceDisplayName}`,
      });
    },
  });

  return (
    <ConfirmModal
      subtitle={[
        <Text key={0}>
          Are you sure you want to delete <b>{sourceDisplayName}</b>?
        </Text>,
        <Text fontSize="medium" mt={6} key={1}>
          {description}
        </Text>,
        <Text fontSize="medium" color="gray-300" mt={6} mx="auto" key={2} maxWidth="440px">
          Note: Deleting a log source will stop the collection of future events, but any collected
          data will remain accessible in Data Explorer
        </Text>,
      ]}
      variant="destructive"
      onConfirm={deleteLogSource}
    />
  );
};

export default DeleteLogSourceModal;
