/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import * as Yup from 'yup';
import { Box, SimpleGrid, Grid, Flex, IconButton, Text } from 'pouncejs';
import { Formik, FieldArray, Field, FastField } from 'formik';
import { S3PrefixLogTypesFormValues } from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagement';
import { yupS3PrefixLogTypesValidation } from 'Helpers/utils';
import FormikTextInput from 'Components/fields/TextInput';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import SubmitButton from 'Components/buttons/SubmitButton';
import { s3PrefixLogTypesInitialValues } from 'Pages/Integrations/LogSources/S3/S3LogSourceWizard';
import FormikRadio from 'Components/fields/Radio';
import { LogStreamTypeEnum } from 'Generated/schema';

interface PrefixesLogTypesFormProps {
  initialValues: S3PrefixLogTypesFormValues;
  availableLogTypes: string[];
  onSubmit: (values: S3PrefixLogTypesFormValues) => void;
}

const EMPTY_ARRAY = [];

const PrefixesForm: React.FC<PrefixesLogTypesFormProps> = ({
  initialValues,
  availableLogTypes,
  onSubmit,
}) => {
  return (
    <Formik<S3PrefixLogTypesFormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        s3PrefixLogTypes: yupS3PrefixLogTypesValidation(),
        logStreamType: Yup.string().oneOf(Object.keys(LogStreamTypeEnum)).required(),
      })}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <SimpleGrid columns={1} spacing={5}>
          <Box borderBottom="1px solid" borderColor="navyblue-300" pb={4}>
            <Text>Select Stream Type</Text>
            <Flex align="flex-start" justify="space-between" mt={2}>
              <Box>
                <FastField
                  as={FormikRadio}
                  size="medium"
                  name="logStreamType"
                  value={LogStreamTypeEnum.Lines}
                  label="Lines"
                  aria-describedby="stream-type-lines-text"
                />
                <Text ml="44px" fontSize="small-medium" id="stream-type-lines-text">
                  Events are line delimited
                </Text>
              </Box>
              <Box>
                <FastField
                  as={FormikRadio}
                  name="logStreamType"
                  value={LogStreamTypeEnum.JsonArray}
                  label="JSON Array"
                  aria-describedby="serviceRegion-label-text"
                />
                <Text ml="44px" fontSize="small-medium" id="stream-type-lines-text">
                  Events are in JSON Array format
                </Text>
              </Box>
              <Box>
                <FastField
                  as={FormikRadio}
                  name="logStreamType"
                  value={LogStreamTypeEnum.CloudWatchLogs}
                  label="CloudWatch Logs"
                  aria-describedby="serviceRegion-label-text"
                />
                <Text ml="44px" fontSize="small-medium" id="stream-type-lines-text">
                  Events are delivered to S3 from CloudWatch Logs
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box position="relative" overflowX="auto" maxHeight={500}>
            <Text mb={4}>Attach Schemas</Text>
            <FieldArray
              name="s3PrefixLogTypes"
              render={arrayHelpers => {
                return values.s3PrefixLogTypes.map((_, index, array) => {
                  return (
                    <Flex
                      key={index}
                      p={4}
                      position="relative"
                      backgroundColor="navyblue-500"
                      spacing={4}
                      data-testid={`s3-prefix-${index}`}
                    >
                      <Grid templateColumns="9fr 1fr" width={1} gap={5}>
                        <Flex direction="column" spacing={2}>
                          <SimpleGrid columns={2} spacing={2}>
                            <Field
                              name={`s3PrefixLogTypes.${index}.prefix`}
                              label="S3 Prefix Filter (leave empty to allow all)"
                              placeholder="Limit logs to objects that start with matching characters"
                              as={FormikTextInput}
                              width={1}
                            />
                            <Box maxWidth={365}>
                              <Field
                                name={`s3PrefixLogTypes.${index}.excludedPrefixes`}
                                label="S3 Prefix Exclusion Filter"
                                placeholder="Exclude logs to objects that start with matching characters"
                                items={EMPTY_ARRAY}
                                as={FormikMultiCombobox}
                                allowAdditions
                                searchable
                              />
                            </Box>
                          </SimpleGrid>

                          <Field
                            name={`s3PrefixLogTypes.${index}.logTypes`}
                            label="Log Types"
                            placeholder="The types of logs that are collected"
                            items={availableLogTypes}
                            as={FormikMultiCombobox}
                            searchable
                            required
                          />
                        </Flex>
                        <Flex align="center" spacing={2}>
                          {array.length > 1 && (
                            <IconButton
                              size="medium"
                              icon="close-outline"
                              variantColor="blue-300"
                              variantBorderStyle="circle"
                              aria-label={`Remove prefix ${index}`}
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          )}
                          {index + 1 === array.length && (
                            <IconButton
                              size="medium"
                              icon="add-circle"
                              variantColor="blue-300"
                              variantBorderStyle="circle"
                              aria-label="Add prefix"
                              onClick={() =>
                                setFieldValue(
                                  `s3PrefixLogTypes.${index + 1}`,
                                  s3PrefixLogTypesInitialValues
                                )
                              }
                            />
                          )}
                        </Flex>
                      </Grid>
                    </Flex>
                  );
                });
              }}
            />
          </Box>
          <Flex justify="center">
            <SubmitButton variantColor="blue-300">Apply Changes</SubmitButton>
          </Flex>
        </SimpleGrid>
      )}
    </Formik>
  );
};

export default PrefixesForm;
