/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useRouter from 'Hooks/useRouter';
import Page404 from 'Pages/404';
import { LogPullerTypeEnum } from 'Generated/schema';
import { LogSourceType, LogTransportMethodsEnum } from 'Source/constants';
import { EditAsanaLogSource } from '../Asana';
import { EditBoxLogSource } from '../Box';
import { EditCloudWatchLogSource } from '../Cloudwatch';
import { EditCrowdstrikeLogSource } from '../Crowdstrike';
import { EditDuoLogSource } from '../Duo';
import { EditEventbridgeLogSource } from '../Eventbridge';
import { EditGithubLogSource } from '../Github';
import { EditGsuiteLogSource } from '../Gsuite';
import { EditM365LogSource } from '../M365';
import { EditOktaLogSource } from '../Okta';
import { EditOnePasswordLogSource } from '../Onepassword';
import { EditS3LogSource } from '../S3';
import { EditSalesforceLogSource } from '../Salesforce';
import { EditSlackLogSource } from '../Slack';
import { EditSqsLogSource } from '../Sqs';
import { EditZendeskLogSource } from '../Zendesk';
import { EditZoomLogSource } from '../Zoom';
import { EditAtlassianLogSource } from '../Atlassian';
import { EditGcsLogSource } from '../GoogleCloudStorage';
import { EditWorkdayLogSource } from '../Workday';
import { EditSnykLogSource } from '../Snyk';

const editLogSourcePageList: Record<LogSourceType, React.ElementType> = {
  [LogPullerTypeEnum.Asana]: EditAsanaLogSource,
  [LogPullerTypeEnum.Box]: EditBoxLogSource,
  [LogTransportMethodsEnum.cloudwatch]: EditCloudWatchLogSource,
  [LogTransportMethodsEnum.gcs]: EditGcsLogSource,
  [LogPullerTypeEnum.Crowdstrike]: EditCrowdstrikeLogSource,
  [LogPullerTypeEnum.Duo]: EditDuoLogSource,
  [LogTransportMethodsEnum.eventbridge]: EditEventbridgeLogSource,
  [LogPullerTypeEnum.Github]: EditGithubLogSource,
  [LogPullerTypeEnum.Gsuite]: EditGsuiteLogSource,
  [LogPullerTypeEnum.M365]: EditM365LogSource,
  [LogPullerTypeEnum.Okta]: EditOktaLogSource,
  [LogPullerTypeEnum.Onepassword]: EditOnePasswordLogSource,
  [LogTransportMethodsEnum.s3]: EditS3LogSource,
  [LogPullerTypeEnum.Salesforce]: EditSalesforceLogSource,
  [LogPullerTypeEnum.Slack]: EditSlackLogSource,
  [LogTransportMethodsEnum.sqs]: EditSqsLogSource,
  [LogPullerTypeEnum.Zendesk]: EditZendeskLogSource,
  [LogPullerTypeEnum.Zoom]: EditZoomLogSource,
  [LogPullerTypeEnum.Atlassian]: EditAtlassianLogSource,
  [LogPullerTypeEnum.Workday]: EditWorkdayLogSource,
  [LogPullerTypeEnum.Snyk]: EditSnykLogSource,
};

const EditLogSourceMapper = () => {
  const {
    match: {
      params: { type },
    },
  } = useRouter<{ type: LogSourceType }>();

  const LogSourcePages = editLogSourcePageList[type] || Page404;
  return <LogSourcePages />;
};

export default EditLogSourceMapper;
