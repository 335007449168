/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { remToPx } from 'Helpers/utils';
import { FloatSeries, LongSeries } from 'Generated/schema';
import { useTheme } from 'pouncejs';

type GetLegendProps = {
  series: (LongSeries | FloatSeries)[];
  title?: string;
};

type GetLegendFunc = (props: GetLegendProps) => any;

const useChartOptions = () => {
  const theme = useTheme();
  const getLegend: GetLegendFunc = React.useCallback(
    ({ series, title }) => {
      /*
       * 'legendData' must be an array of values that matches 'series.name' in order
       * to display them in correct order and color
       * e.g. [AWS.ALB, AWS.S3, ...etc]
       */
      const legendData = series.map(({ label }) => label);
      return {
        type: 'scroll' as const,
        orient: 'vertical' as const,
        left: 'auto',
        right: 'auto',
        // Pushing down legend to fit title
        top: title ? 30 : 'auto',
        icon: 'circle',
        data: legendData,
        inactiveColor: theme.colors['gray-400'],
        textStyle: {
          color: theme.colors['white-200'],
          fontFamily: theme.fonts.primary,
          fontSize: remToPx(theme.fontSizes['x-small']),
        },
        pageIcons: {
          vertical: ['M7 14L12 9L17 14H7Z', 'M7 10L12 15L17 10H7Z'],
        },
        pageIconColor: theme.colors['white-200'],
        pageIconInactiveColor: theme.colors['navyblue-300'],
        pageIconSize: 12,
        pageTextStyle: {
          fontFamily: theme.fonts.primary,
          color: theme.colors['white-200'],
          fontWeight: theme.fontWeights.bold as any,
          fontSize: remToPx(theme.fontSizes['x-small']),
        },
        pageButtonGap: theme.space[3] as number,
      };
    },
    [theme]
  );

  return React.useMemo(() => ({ getLegend }), [getLegend]);
};

export default useChartOptions;
