/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';

export type UserDetails = Pick<
  Types.User,
  'id' | 'givenName' | 'familyName' | 'email' | 'createdAt' | 'status'
> & { role: Pick<Types.Role, 'id' | 'name'> };

export const UserDetails = gql`
  fragment UserDetails on User {
    id
    givenName
    familyName
    email
    createdAt
    status
    role {
      id
      name
    }
  }
`;
