/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Card } from 'pouncejs';
import TimeSeriesChart from 'Components/charts/TimeSeriesChart';
import { LongSeriesData } from 'Generated/schema';
import { useMetricsFiltersContext } from 'Components/utils/MetricsFiltersContext';

interface EventsByLogTypeTabProps {
  eventsProcessed: LongSeriesData;
}

const EventsByLogTypeTab: React.FC<EventsByLogTypeTabProps> = ({ eventsProcessed }) => {
  const { resultProjectionStartDate } = useMetricsFiltersContext();
  return (
    <Card variant="dark" data-testid="events-by-log-type-chart" height={289} py={5} pl={4}>
      <TimeSeriesChart
        chartId="events-by-log-type"
        data={eventsProcessed}
        zoomable
        projectionStartDate={resultProjectionStartDate}
      />
    </Card>
  );
};

export default React.memo(EventsByLogTypeTab);
