/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField, Field, Form, Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
} from 'pouncejs';
import { AlertStatus, AlertType, AlertsInput, Severity } from 'Generated/schema';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import { capitalize, alertTypeToString } from 'Helpers/utils';
import * as Yup from 'yup';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import TextButton from 'Components/buttons/TextButton';
import FormikNumberInput from 'Components/fields/NumberInput';
import { useListAvailableLogTypes, useListLogSourcesMinimal } from 'Source/graphql/queries';
import { RESOURCE_TYPES } from 'Source/constants';
import PopoverAutoSubmit from 'Components/PopoverAutoSubmit';
import { ListAlertsFiltersKeys } from './ListAlertFilters';

export type ListAlertsDropdownFiltersValues = Pick<
  AlertsInput,
  | 'types'
  | 'resourceTypes'
  | 'logTypes'
  | 'logSources'
  | 'severities'
  | 'statuses'
  | 'eventCountMax'
  | 'eventCountMin'
>;

export interface ListAlertsDropdownFiltersProps {
  excludedFields?: readonly ListAlertsFiltersKeys[];
}

const filterItemToString = (item: Severity | AlertStatus) =>
  item === AlertStatus.Closed ? 'Invalid' : capitalize(item.toLowerCase());

const statusOptions = Object.values(AlertStatus);
const severityOptions = Object.values(Severity);
const alertTypeOptions = Object.values(AlertType);
const filterKeys: (keyof Partial<AlertsInput>)[] = [
  'types',
  'resourceTypes',
  'logTypes',
  'logSources',
  'severities',
  'statuses',
  'eventCountMax',
  'eventCountMin',
];

const defaultValues: ListAlertsDropdownFiltersValues = {
  types: [],
  resourceTypes: [],
  logTypes: [],
  logSources: [],
  severities: [],
  statuses: [],
  // @ts-ignore
  eventCountMin: '',
  // @ts-ignore
  eventCountMax: '',
};

const validationSchema: Yup.SchemaOf<ListAlertsDropdownFiltersValues> = Yup.object().shape({
  types: Yup.array().of(Yup.string()),
  resourceTypes: Yup.array().of(Yup.string()),
  logTypes: Yup.array().of(Yup.string()),
  logSources: Yup.array().of(Yup.string()),
  severities: Yup.array().of(Yup.string()),
  statuses: Yup.array().of(Yup.string()),
  eventCountMin: Yup.number().integer().moreThan(0, 'Must be greater than 0'),
  eventCountMax: Yup.number().integer().moreThan(0, 'Must be greater than 0'),
});

const ListAlertsDropdownFilters: React.FC<ListAlertsDropdownFiltersProps> = ({
  excludedFields,
}) => {
  const { data: logTypeData } = useListAvailableLogTypes();
  const { data: logSourceData } = useListLogSourcesMinimal();

  const { requestParams, updateRequestParams } = useRequestParamsWithoutPagination<AlertsInput>();

  const initialFilterValues = React.useMemo(() => {
    return {
      ...defaultValues,
      ...pick(requestParams, filterKeys),
    } as ListAlertsDropdownFiltersValues;
  }, [requestParams]);

  const filtersCount = Object.keys(defaultValues).filter(
    (key: keyof ListAlertsDropdownFiltersValues) =>
      key in requestParams && !excludedFields.includes(key)
  ).length;

  const shouldDisplayFilter = (filter: keyof ListAlertsDropdownFiltersValues) =>
    !excludedFields.includes(filter);

  const logSourcesNormalized = React.useMemo(() => {
    const logSourcesArray = logSourceData?.logSources ?? [];
    return logSourcesArray.reduce(
      (acc, item) => ({ ...acc, [item.integrationId]: item.integrationLabel }),
      {}
    );
  }, [logSourceData]);

  const logSourcesOptions = Object.keys(logSourcesNormalized);

  return (
    <Popover>
      {({ close: closePopover, isOpen }) => (
        <React.Fragment>
          <PopoverTrigger
            data-tid="alerts-dropdown-filters"
            as={Button}
            iconAlignment="right"
            icon="filter-light"
            aria-label="Additional Filters"
          >
            Filters {filtersCount ? `(${filtersCount})` : ''}
          </PopoverTrigger>
          <PopoverContent alignment="bottom-left">
            <Card
              shadow="dark300"
              my={14}
              p={6}
              pb={4}
              width={540}
              data-testid="dropdown-alert-listing-filters"
            >
              <Formik<ListAlertsDropdownFiltersValues>
                enableReinitialize
                onSubmit={updateRequestParams}
                initialValues={initialFilterValues}
                validationSchema={validationSchema}
              >
                {({ setValues, values }) => (
                  <Form>
                    <PopoverAutoSubmit<ListAlertsDropdownFiltersValues>
                      isOpen={isOpen}
                      values={values}
                      onSubmit={updateRequestParams}
                    />
                    <Flex direction="column" spacing={4}>
                      {shouldDisplayFilter('types') && (
                        <Field
                          as={FormikMultiCombobox}
                          label="Alert Types"
                          name="types"
                          placeholder="Select alert types"
                          items={alertTypeOptions}
                          itemToString={alertTypeToString}
                        />
                      )}
                      {shouldDisplayFilter('severities') && (
                        <FastField
                          name="severities"
                          as={FormikMultiCombobox}
                          items={severityOptions}
                          itemToString={filterItemToString}
                          label="Severity"
                          data-testid="alert-listing-severity-filtering"
                          placeholder="Select severities"
                        />
                      )}
                      {shouldDisplayFilter('logTypes') && (
                        <Field
                          as={FormikMultiCombobox}
                          label="Log Types"
                          name="logTypes"
                          placeholder="Select log types"
                          items={logTypeData?.listAvailableLogTypes?.logTypes ?? []}
                          searchable
                        />
                      )}
                      {shouldDisplayFilter('resourceTypes') && (
                        <Field
                          as={FormikMultiCombobox}
                          label="Resource Types"
                          name="resourceTypes"
                          placeholder="Select resource types"
                          items={RESOURCE_TYPES}
                          searchable
                        />
                      )}
                      {shouldDisplayFilter('logSources') && (
                        <Field
                          as={FormikMultiCombobox}
                          label="Log Sources"
                          name="logSources"
                          placeholder="Select log sources"
                          itemToString={item => logSourcesNormalized[item]}
                          items={logSourcesOptions}
                          searchable
                        />
                      )}
                      {shouldDisplayFilter('statuses') && (
                        <FastField
                          name="statuses"
                          as={FormikMultiCombobox}
                          items={statusOptions}
                          itemToString={filterItemToString}
                          label="Status"
                          data-testid="alert-listing-status-filtering"
                          placeholder="Select statuses"
                        />
                      )}
                      {shouldDisplayFilter('eventCountMin') &&
                        shouldDisplayFilter('eventCountMax') && (
                          <SimpleGrid columns={2} gap={4}>
                            <FastField
                              name="eventCountMin"
                              as={FormikNumberInput}
                              min={1}
                              label="Min Events"
                              data-testid="alert-listing-min-event"
                              placeholder="Minimum number of events"
                            />
                            <FastField
                              name="eventCountMax"
                              as={FormikNumberInput}
                              min={1}
                              label="Max Events"
                              data-testid="alert-listing-max-event"
                              placeholder="Maximum number of events"
                            />
                          </SimpleGrid>
                        )}
                    </Flex>
                    <Flex direction="column" justify="center" align="center" mt={8} spacing={4}>
                      <Box>
                        <Button onClick={closePopover}>Apply Filters</Button>
                      </Box>
                      <TextButton
                        role="button"
                        onClick={() => setValues(omit(defaultValues, excludedFields))}
                      >
                        Clear Filters
                      </TextButton>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Card>
          </PopoverContent>
        </React.Fragment>
      )}
    </Popover>
  );
};

export default React.memo(ListAlertsDropdownFilters);
