/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useFormikContext } from 'formik';
import useRouter from 'Hooks/useRouter';
import { useSnackbar } from 'pouncejs';
import { appendDataSchemaPrefix, extractErrorMessage } from 'Helpers/utils';
import { useCancelHoldingTankJob } from './graphql/cancelHoldingTankJob.generated';
import { useTestHoldingTankSchemas } from './graphql/testHoldingTankSchemas.generated';
import { useSchemasManagementContext } from '../SchemasManagementContext/SchemasManagementContext';
import { S3PrefixLogTypesFormValues } from '../SchemasManagement';

const useTestSchemasActions = () => {
  const { pushSnackbar } = useSnackbar();
  const {
    values: { s3PrefixLogTypes, logStreamType, draftSchemas },
  } = useFormikContext<S3PrefixLogTypesFormValues>();
  const { setSchemaTestJob, schemaTestJob, schemaTestFilters } = useSchemasManagementContext();
  const { match } = useRouter<{ id: string }>();
  const [testHoldingTankSchemas, { loading: startTestLoading }] = useTestHoldingTankSchemas({
    onCompleted: testData => {
      setSchemaTestJob(testData.testHoldingTankSchemas);
    },
    onError: testError => {
      setSchemaTestJob(null);
      pushSnackbar({
        variant: 'error',
        title: `Failed to test holding tank data`,
        description: extractErrorMessage(testError),
      });
    },
  });

  const [cancelTask, { loading: cancelTestLoading }] = useCancelHoldingTankJob({
    onError: cancelError => {
      pushSnackbar({
        variant: 'error',
        title: "Couldn't cancel schema testing operation",
        description: extractErrorMessage(cancelError),
      });
    },
    onCompleted: data => {
      setSchemaTestJob(data.cancelHoldingTankJob);
    },
  });

  const cancelSchemaTestJob = React.useCallback(() => {
    cancelTask({
      variables: {
        id: schemaTestJob?.id,
      },
    });
  }, [cancelTask, schemaTestJob?.id]);

  const startSchemaTestJob = React.useCallback(() => {
    return testHoldingTankSchemas({
      variables: {
        input: {
          sourceId: match.params.id,
          filters: schemaTestFilters,
          s3PrefixLogTypes,
          streamType: logStreamType,
          newSchemas: draftSchemas.map(draftSchema => ({
            ...draftSchema,
            name: appendDataSchemaPrefix(draftSchema.name),
          })),
        },
      },
    });
  }, [
    match.params.id,
    s3PrefixLogTypes,
    logStreamType,
    schemaTestFilters,
    testHoldingTankSchemas,
    draftSchemas,
  ]);

  return React.useMemo(
    () => ({
      startSchemaTestJob,
      cancelSchemaTestJob,
      isLoading: cancelTestLoading || startTestLoading,
    }),
    [startSchemaTestJob, cancelSchemaTestJob, cancelTestLoading, startTestLoading]
  );
};

export default useTestSchemasActions;
