/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box } from 'pouncejs';
import useModal from 'Hooks/useModal';
import { S3PrefixLogTypesFormValues } from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagement';
import useAvailableLogTypesForProvider from 'Hooks/useAvailableLogTypesForProvider';
import PrefixesForm from './PrefixesForm';

export interface SetS3PrefixesLogtypesModalProps {
  initialValues: S3PrefixLogTypesFormValues;
  draftSchemaNames: string[];
  onSuccess: (values: S3PrefixLogTypesFormValues) => void;
}

const SetS3PrefixesModal: React.FC<SetS3PrefixesLogtypesModalProps> = ({
  initialValues,
  draftSchemaNames,
  onSuccess,
}) => {
  const { hideModal } = useModal();
  const { availableLogTypes } = useAvailableLogTypesForProvider();
  const onSubmit = React.useCallback(
    (val: S3PrefixLogTypesFormValues) => {
      onSuccess(val);
      hideModal();
    },
    [hideModal, onSuccess]
  );

  return (
    <Box width={857}>
      <PrefixesForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        availableLogTypes={[...availableLogTypes, ...draftSchemaNames]}
      />
    </Box>
  );
};

export default SetS3PrefixesModal;
