/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import dayjs from 'dayjs';
import { Alert, Box, Flex } from 'pouncejs';
import { slugify, extractErrorMessage } from 'Helpers/utils';
import Panel from 'Components/Panel';
import ErrorBoundary from 'Components/ErrorBoundary';
import { compose } from 'Helpers/compose';
import { ListSchemasInput, Permission, SortDirEnum } from 'Generated/schema';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import withSEO from 'Hoc/withSEO';
import useUrlParams from 'Hooks/useUrlParams';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import Page403 from 'Pages/403';
import DataSchemaCard from 'Components/cards/DataSchemaCard';
import EmptyDataFallback from './EmptyDataFallback';
import ListDataModelsSkeleton from './Skeleton';
import ListDataSchemasFilters, {
  filters as inlineFilterKeys,
  ListDataModelInlineFiltersValues,
  ListDataSchemasSortFieldsEnum,
} from './ListDataSchemasFilters';
import { useListSchemas } from './graphql/listDataSchemas.generated';

interface ListSchemasURLParams extends ListDataModelInlineFiltersValues, ListSchemasInput {}

const filterAndSort = (dataSchemas, filters) => {
  const { contains = '', sortBy, sortDir } = filters;
  const filteredDataSchemas = dataSchemas.filter(({ name }) =>
    name.toLowerCase().includes(contains.toLowerCase())
  );

  if (sortBy === ListDataSchemasSortFieldsEnum.name) {
    return filteredDataSchemas.sort(({ name: firstName }, { name: secondName }) =>
      sortDir === SortDirEnum.Ascending
        ? firstName.localeCompare(secondName)
        : secondName.localeCompare(firstName)
    );
  }

  if (sortBy === ListDataSchemasSortFieldsEnum.createdAt) {
    return filteredDataSchemas.sort(
      ({ createdAt: firstCreatedAt }, { createdAt: secondCreatedAt }) => {
        const firstDate = dayjs(firstCreatedAt);
        const secondDate = dayjs(secondCreatedAt);
        if (sortDir === SortDirEnum.Descending) {
          return firstDate.isBefore(secondDate) ? 1 : -1;
        }
        return firstDate.isAfter(secondDate) ? 1 : -1;
      }
    );
  }
  return filteredDataSchemas;
};

const ListDataSchemas: React.FC = () => {
  const { urlParams } = useUrlParams<ListSchemasURLParams>({
    parseNumbers: false,
  });

  const params = omit(urlParams, inlineFilterKeys);
  const inlineFilters = pick(urlParams, inlineFilterKeys);

  const { loading, error, data } = useListSchemas({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: params,
    },
  });

  const dataSchemas = React.useMemo(() => data?.listSchemas?.results || [], [data]);

  const filteredDataSchemas = React.useMemo(() => {
    return filterAndSort(dataSchemas, inlineFilters);
  }, [dataSchemas, inlineFilters]);

  if (loading && !data) {
    return <ListDataModelsSkeleton />;
  }

  return (
    <Box mb={6}>
      <ErrorBoundary>
        <Panel data-tracking-page="schemas" title="Schemas" actions={<ListDataSchemasFilters />}>
          {error && (
            <Alert
              variant="error"
              title="Couldn't load your data schemas"
              description={
                extractErrorMessage(error) ||
                'There was an error while attempting to list your data schemas'
              }
            />
          )}
          {filteredDataSchemas.length > 0 ? (
            <Flex direction="column" spacing={2}>
              {filteredDataSchemas.map(dataSchema => (
                <DataSchemaCard
                  key={`data-schema-${slugify(dataSchema.name)}`}
                  dataSchema={dataSchema}
                />
              ))}
            </Flex>
          ) : (
            <EmptyDataFallback />
          )}
        </Panel>
      </ErrorBoundary>
    </Box>
  );
};

export default compose(
  withSEO({ title: 'Schemas' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceRead, Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(ListDataSchemas);
