/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import orderBy from 'lodash/orderBy';
import { Grid, Box, Text, Card, SimpleGrid, Flex } from 'pouncejs';
import { LongSerie } from 'Generated/schema';
import { formatBytes, toPlural } from 'Helpers/utils';
import VolumeSummary from '../../VolumeSummary';

interface DataIngestionTabProps {
  bytesIngested: LongSerie[];
}

const DataIngestionTab: React.FC<DataIngestionTabProps> = ({ bytesIngested }) => {
  const bytesIngestedOrdered = React.useMemo(() => {
    return orderBy(bytesIngested, 'value', 'desc');
  }, [bytesIngested]);

  const total = React.useMemo(() => {
    return bytesIngested.reduce((prev, current) => prev + current.value, 0);
  }, [bytesIngested]);

  return (
    <Grid templateColumns="1fr 3fr" gap={4}>
      <VolumeSummary
        total={total}
        title="Total Volume Ingested"
        subTitle="during last 12 months"
        color="teal-300"
      />
      <Box>
        <SimpleGrid as="dl" columns={3} columnGap={3} rowGap="11px">
          {bytesIngestedOrdered.map(b => (
            <Card overflow="auto" key={b.label} position="relative" variant="dark" py={4} px={3}>
              <Flex justify="space-between" align="center">
                <Text as="dt" width="65%" truncated fontSize="small">
                  {b.label}
                </Text>
                <Text as="dd" fontSize="small-medium" fontWeight="bold">
                  {toPlural(formatBytes(b.value))}
                </Text>
              </Flex>
            </Card>
          ))}
        </SimpleGrid>
      </Box>
    </Grid>
  );
};

export default React.memo(DataIngestionTab);
