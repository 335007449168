/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Button, Card, Grid, Flex, Table } from 'pouncejs';
import { useGetSampleData } from 'Components/forms/DataSchemaForm/SampleDataSection/graphql/getSampleData.generated';
import TablePlaceholder from 'Components/TablePlaceholder';
import { useSchemaGeneration } from 'Components/utils/SchemaGenerationContext';
import { extractErrorMessage } from 'Helpers/utils';
import { CollapsableCell, DisplayLimit } from '../common';

const SampleDataRawResults: React.FC = () => {
  const { sessionId, reset } = useSchemaGeneration();
  const { data, loading, error } = useGetSampleData({
    skip: !sessionId,
    variables: {
      input: {
        sessionId,
      },
    },
  });

  const rawData = data?.getSampleData.data;
  const total = data?.getSampleData.total;

  if (loading) {
    return <TablePlaceholder rowCount={8} />;
  }

  if (error) {
    return (
      <Card mt={4} p={4}>
        <Alert
          variant="error"
          title="Couldn't load your sample data"
          description={
            extractErrorMessage(error) ||
            'There was an error when performing your request, please contact support@runpanther.io'
          }
        />
        <Flex mt={4} justify="center">
          <Button variant="outline" onClick={reset}>
            Reset
          </Button>
        </Flex>
      </Card>
    );
  }

  return (
    <Grid overflow="auto" maxHeight="max(calc(100vh - 950px),600px)" willChange="scroll">
      <Table size="small" stickyHeader data-testid="sample-data-table">
        <Table.Head>
          <Table.Row>
            <Box as={Table.HeaderCell} backgroundColor="navyblue-300">
              Raw Events ({total})
            </Box>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {rawData.map((log, i) => {
            return (
              <Table.Row key={`sample-data-log-${i}`}>
                <Box as={Table.Cell}>
                  <CollapsableCell
                    text={log}
                    hideText="Hide Log"
                    showText="Show Log"
                    sliceOn={300}
                  />
                </Box>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {rawData.length < total && <DisplayLimit />}
    </Grid>
  );
};

export default SampleDataRawResults;
