/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { AbstractButton, Badge, Box, Flex, Link, Spinner, Text } from 'pouncejs';
import { Link as RRLink } from 'react-router-dom';
import urls from 'Source/urls';
import { useFormikContext } from 'formik';
import FlatBadge from 'Components/badges/FlatBadge';
import { appendDataSchemaPrefix } from 'Helpers/utils';
import { PutUserSchemaInput } from 'Generated/schema';
import { S3PrefixLogTypesFormValues } from '../SchemasManagement';

interface LogTypeBadgeProps {
  logType: string;
  prefix: string;
  isNewPrefix?: boolean;
  loading: boolean;
  onEditSchema: (schema: PutUserSchemaInput) => void;
}

const LogTypeBadge: React.FC<LogTypeBadgeProps> = ({
  prefix,
  logType,
  isNewPrefix,
  loading,
  onEditSchema,
}) => {
  const { initialValues, values } = useFormikContext<S3PrefixLogTypesFormValues>();
  const isDraft = values.draftSchemas.some(
    schema => appendDataSchemaPrefix(schema.name) === logType
  );
  // Searching if prefix exist on initial values
  const existingPrefixIndex = initialValues.s3PrefixLogTypes.findIndex(
    ({ prefix: existingPrefix }) => existingPrefix === prefix
  );
  // Searching if logType exist on initial prefix
  const isLogTypeInInitialPrefix =
    existingPrefixIndex !== -1 &&
    initialValues.s3PrefixLogTypes[existingPrefixIndex].logTypes.find(
      initialLogType => initialLogType === logType
    );

  const handleEditSchema = React.useCallback(() => {
    onEditSchema(
      values.draftSchemas.find(schema => appendDataSchemaPrefix(schema.name) === logType)
    );
  }, [onEditSchema, values.draftSchemas, logType]);

  return (
    <Box backgroundColor="navyblue-600" spacing={2} justify="space-between" p={4} mx={2} mb={2}>
      <Flex spacing={2} as="h4" fontWeight="medium" wordBreak="break-word">
        {isDraft ? (
          <AbstractButton onClick={handleEditSchema}>
            <Flex spacing={2} color="blue-200" align="center">
              <Text>{logType}</Text>
              <Badge color="orange-200">
                <Box fontWeight="bold" color="white">
                  Draft
                </Box>
              </Badge>
              {loading && <Spinner size="small" />}
            </Flex>
          </AbstractButton>
        ) : (
          <>
            <Link
              as={RRLink}
              aria-label={`Link to ${logType} schema`}
              to={`${urls.data.schemas.details(logType)}`}
              external
            >
              {logType}
            </Link>
            {/* Display this badge if prefix exists but this is a new log type */}
            {!isNewPrefix && !isLogTypeInInitialPrefix && (
              <FlatBadge backgroundColor="navyblue-700" color="blue-300">
                NEW
              </FlatBadge>
            )}
          </>
        )}
      </Flex>
    </Box>
  );
};

export default LogTypeBadge;
