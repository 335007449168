/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSnackbar } from 'pouncejs';
import Page404 from 'Pages/404';
import withSEO from 'Hoc/withSEO';
import useRouter from 'Hooks/useRouter';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { compose } from 'Helpers/compose';
import { Permission } from 'Generated/schema';
import Page403 from 'Pages/403';
import { extractErrorMessage } from 'Helpers/utils';
import { ENABLE_WORKDAY_PULLER } from 'Source/constants';
import withFeatureFlag from 'Hoc/withFeatureFlag';
import { useGetWorkdayLogSource } from './graphql/getWorkdayLogSource.generated';
import { useUpdateWorkdayLogSource } from './graphql/updateWorkdayLogSource.generated';
import WorkdayLogSourceWizard, { WorkdayLogsSourceWizardValues } from './WorkdayLogSourceWizard';

const EditWorkdayLogSource = () => {
  const { pushSnackbar } = useSnackbar();
  const {
    match: {
      params: { id },
    },
  } = useRouter<{ id: string }>();

  const { data, error: getError } = useGetWorkdayLogSource({
    variables: { id },
    onError: error => {
      pushSnackbar({
        title: extractErrorMessage(error) || 'An unknown error occurred',
        variant: 'error',
      });
    },
  });

  const [updateWorkdayLogSource] = useUpdateWorkdayLogSource();

  const initialValues = React.useMemo(
    () => ({
      integrationId: id,
      integrationLabel: data?.getLogPullingIntegration.integrationLabel ?? 'Loading...',
      logTypes: data?.getLogPullingIntegration?.logTypes ?? [],
      tenantHostName: data?.getLogPullingIntegration.pullerConfig.workday?.tenantHostName,
      tenantName: data?.getLogPullingIntegration.pullerConfig.workday?.tenantName,
      activityClientId: data?.getLogPullingIntegration.pullerConfig.workday?.activityClientId,
      activityClientSecret:
        data?.getLogPullingIntegration.pullerConfig.workday?.activityClientSecret,
      activityRefreshToken:
        data?.getLogPullingIntegration.pullerConfig.workday?.activityRefreshToken,
    }),
    [data, id]
  );

  const handleSubmit = React.useCallback(
    async (values: WorkdayLogsSourceWizardValues) => {
      try {
        const resp = await updateWorkdayLogSource({
          variables: {
            input: {
              integrationId: values.integrationId,
              integrationLabel: values.integrationLabel,
              pullerConfig: {
                logTypes: values.logTypes,
                workday: {
                  tenantHostName: values.tenantHostName,
                  tenantName: values.tenantName,
                  activityClientId: values.activityClientId,
                  activityClientSecret: values.activityClientSecret,
                  activityRefreshToken: values.activityRefreshToken,
                },
              },
            },
          },
        });
        return resp.data.updateLogPullingIntegration;
      } catch (err) {
        return err as Error;
      }
    },
    [updateWorkdayLogSource]
  );

  // we optimistically assume that an error in "get" is a 404. We don't have any other info
  if (getError) {
    return <Page404 />;
  }

  return <WorkdayLogSourceWizard initialValues={initialValues} onSubmit={handleSubmit} />;
};

export default compose(
  withSEO({ title: 'Edit Workday Source' }),
  withFeatureFlag({ featureFlags: [ENABLE_WORKDAY_PULLER], fallback: <Page404 /> }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.LogSourceModify],
    fallback: <Page403 />,
  })
)(EditWorkdayLogSource);
