/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Card, Flex } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import { extractErrorMessage } from 'Helpers/utils';
import { ListDataModelsInput } from 'Generated/schema';
import useRouter from 'Hooks/useRouter';
import pick from 'lodash/pick';
import { useListDataModels } from 'Source/graphql/queries';
import DataModelCard from 'Pages/ListDataModels/DataModelCard';
import { TableControlsPagination } from 'Components/utils/TableControls';
import useRequestParamsWithPagination from 'Hooks/useRequestParamsWithPagination';
import EmptyDataFallback from 'Pages/AnalysisPackDetails/EmptyDataFallback/EmptyDataFallback';
import ListDataModelsSkeleton from '../ItemsSkeleton';

const ListPackDataModels = () => {
  const { match } = useRouter<{ id: string }>();
  const { requestParams, updatePagingParams } = useRequestParamsWithPagination<
    ListDataModelsInput
  >();
  const strippedParams = React.useMemo(() => pick(requestParams, ['page']), [requestParams]);
  const { loading, error, previousData, data = previousData } = useListDataModels({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        ...strippedParams,
        packIds: [match.params.id],
      },
    },
  });
  const dataModels = data?.listDataModels?.models || [];
  const pagingData = data?.listDataModels?.paging;

  if (loading && !data) {
    return <ListDataModelsSkeleton />;
  }

  if (error) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load your data models"
          description={
            extractErrorMessage(error) ||
            'There was an error when performing your request, please contact support@runpanther.io'
          }
        />
      </Box>
    );
  }

  if (!dataModels.length) {
    return <EmptyDataFallback message="No Data Models on Pack" />;
  }

  return (
    <ErrorBoundary>
      <Card as="section" position="relative" p={4}>
        <Flex direction="column" spacing={2}>
          {dataModels.map(dataModel => (
            <DataModelCard key={dataModel.id} dataModel={dataModel} />
          ))}
        </Flex>
      </Card>
      <Box my={5}>
        <TableControlsPagination
          page={pagingData.thisPage}
          totalPages={pagingData.totalPages}
          onPageChange={updatePagingParams}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default ListPackDataModels;
