/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import { Field, useFormikContext } from 'formik';
import SensitiveTextInput from 'Components/fields/SensitiveTextInput';
import { WizardPanel } from 'Components/Wizard';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import FormikTextInput from 'Components/fields/TextInput';
import { WorkdayLogsSourceWizardValues } from './WorkdayLogSourceWizard';

const CredentialsPanel = () => {
  const { initialValues, dirty, isValid } = useFormikContext<WorkdayLogsSourceWizardValues>();
  const editMode = !!initialValues.integrationId;

  return (
    <WizardPanel>
      <Box width={500} m="auto">
        <WizardPanel.Heading title="Fill in the form below with your credentials" divider={null} />
        <ErrorBoundary>
          <Flex flexDirection="column" spacing={4}>
            <Field
              name="tenantHostName"
              label="Tenant Host Name"
              as={FormikTextInput}
              placeholder="Your Workday tenant host name"
              required
            />
            <Field
              name="tenantName"
              label="Tenant Name"
              as={FormikTextInput}
              placeholder="Your Workday tenant name"
              required
            />
            <Field
              name="activityClientId"
              label="Client Id"
              as={FormikTextInput}
              placeholder="Your Workday client id"
              required
            />
            <Field
              name="activityClientSecret"
              label="Client Secret"
              shouldMask={editMode}
              as={SensitiveTextInput}
              placeholder={
                editMode
                  ? 'Information is hidden. New values will override the existing.'
                  : 'Your Workday client secret'
              }
              required={!editMode}
            />
            <Field
              name="activityRefreshToken"
              label="Refresh Token"
              shouldMask={editMode}
              as={SensitiveTextInput}
              placeholder={
                editMode
                  ? 'Information is hidden. New values will override the existing.'
                  : 'Your Workday refresh token'
              }
              required={!editMode}
            />
          </Flex>
        </ErrorBoundary>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext disabled={!dirty || !isValid}>
            Continue Setup
          </WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default CredentialsPanel;
