/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Button, Card, Flex, Heading, IconButton, Alert } from 'pouncejs';
import { useSchemasManagementContext } from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagementContext';
import { useFormikContext } from 'formik';
import { shortenId, stripDataSchemaPrefix } from 'Helpers/utils';
import useModal from 'Hooks/useModal';
import ConfirmModal from 'Components/modals/ConfirmModal';
import { LogStreamTypeEnum } from 'Generated/schema';
import S3PrefixesLogTypesDisplay from './S3PrefixesLogTypesDisplay';
import { S3PrefixLogTypesFormValues } from '../SchemasManagement';
import TestReportOverview from './TestReportOverview';

interface TestReportProps {
  onBack: () => void;
}
const TestReport: React.FC<TestReportProps> = ({ onBack }) => {
  const { schemaTestJob } = useSchemasManagementContext();
  const { showModal } = useModal();
  const { setValues } = useFormikContext<S3PrefixLogTypesFormValues>();
  const loadConfiguration = React.useCallback(() => {
    setValues({
      s3PrefixLogTypes: schemaTestJob.testParameters.s3PrefixLogTypes,
      logStreamType: schemaTestJob.testParameters.streamType as LogStreamTypeEnum,
      draftSchemas: schemaTestJob.testParameters?.newSchemas.map(draftSchema => ({
        ...draftSchema,
        name: stripDataSchemaPrefix(draftSchema.name),
      })),
    });
    onBack();
  }, [setValues, schemaTestJob, onBack]);
  const erroredPrefixes = schemaTestJob.testResults
    .filter(t => t.errored > 0)
    .map(t => `“${t.prefix === '' ? '*' : t.prefix}”`)
    .join(' and ');

  return (
    <Card as="section" width={1} p={4}>
      <Flex
        p={6}
        borderBottom="1px solid"
        borderColor={'navyblue-300'}
        justify="space-between"
        align="center"
        maxHeight={80}
      >
        <Flex align="center">
          <IconButton
            variant="solid"
            variantColor="transparent"
            icon="arrow-back"
            onClick={onBack}
            size="medium"
            aria-label="Back to Schema Management"
          />
          <Heading size="x-small" as="h4">
            Test Report {shortenId(schemaTestJob.id)}
          </Heading>
        </Flex>
      </Flex>
      <Flex direction="column" spacing={4}>
        {Boolean(erroredPrefixes) && (
          <Alert
            variant="error"
            title={`Panther detected errors in ${erroredPrefixes} when testing data. This could be due to an incorrect stream type, unsupported data format, or access issues.`}
          />
        )}
        <TestReportOverview schemaTestJob={schemaTestJob} />
        <Box>
          {schemaTestJob.testParameters.s3PrefixLogTypes.map((s3PrefixLogTypesElement, i) => {
            return (
              <Box backgroundColor="navyblue-500" key={i} pb={4} mb={4}>
                <S3PrefixesLogTypesDisplay {...s3PrefixLogTypesElement} index={i} />
              </Box>
            );
          })}
        </Box>
      </Flex>
      {!erroredPrefixes && (
        <Flex justify="center" my={2}>
          <Button
            onClick={() =>
              showModal(
                <ConfirmModal
                  variant="continue"
                  subtitle="This action will replace all current schemas but the configuration will not be applied to the source until you save it."
                  onConfirm={loadConfiguration}
                />,
                {
                  title: 'Are you sure you want to Load tested schemas as draft?',
                }
              )
            }
          >
            Load tested schemas as draft
          </Button>
        </Flex>
      )}
    </Card>
  );
};

export default TestReport;
