/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useFormikContext } from 'formik';
import { useWizardContext, WizardPanel } from 'Components/Wizard';
import { FailingPanel, PendingPanel } from 'Components/SourceVerificationPanels';
import SuccessIllustration from 'Assets/illustrations/onboard-success.svg';
import { Img, Flex, Box } from 'pouncejs';
import EventThresholdAlarmConfigurator from 'Components/EventThresholdAlarmConfigurator';
import FinishSourceSetupActions from 'Pages/Integrations/components/FinishSourceSetupActions';
import { GcsLogSourceWizardValues } from './GcsLogSourceWizard';
import SubscriptionInformation from './SubscriptionInformation';

const SetupVerification = () => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const { currentStepStatus, setCurrentStepStatus, setNavigationAvailability } = useWizardContext();
  const { values, submitForm } = useFormikContext<GcsLogSourceWizardValues>();
  const result = React.useRef<any | Error>(null);

  React.useEffect(() => {
    (async () => {
      setNavigationAvailability(false);
      result.current = await (submitForm() as Promise<any>);

      if (result.current instanceof Error) {
        setErrorMessage(result.current.message);
        setCurrentStepStatus('FAILING');
        setNavigationAvailability(true);
      } else {
        setErrorMessage('');
        setCurrentStepStatus('PASSING');
      }
    })();
  }, [setCurrentStepStatus, setNavigationAvailability, submitForm]);

  if (currentStepStatus === 'PENDING') {
    return <PendingPanel />;
  }

  if (currentStepStatus === 'FAILING') {
    return <FailingPanel errorMessage={errorMessage} />;
  }

  const source = result.current;

  return (
    <WizardPanel>
      <Flex align="center" direction="column" mx="auto" width={665}>
        <WizardPanel.Heading
          title="Everything looks good!"
          subtitle="Your configured stack was deployed successfully and your source&#39;s setup is now complete!"
          divider={null}
          subtitleProps={{
            color: 'gray-300',
            fontSize: 'medium',
            fontWeight: 'medium',
            width: 339,
          }}
        />
        <Img
          nativeWidth={157}
          nativeHeight={118}
          alt="Stack deployed successfully"
          src={SuccessIllustration}
        />

        <SubscriptionInformation data={values} />

        {source && (
          <Box width={1}>
            <EventThresholdAlarmConfigurator logSource={source} />
          </Box>
        )}

        <WizardPanel.Actions>
          <FinishSourceSetupActions
            integrationId={source.integrationId}
            hasSchemas={source.logTypes.length > 0}
          />
        </WizardPanel.Actions>
      </Flex>
    </WizardPanel>
  );
};

export default SetupVerification;
