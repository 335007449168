/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Table } from 'pouncejs';
import queryString from 'query-string';
import useRouter from 'Hooks/useRouter';
import { getCellAlignment } from './ResultsTableColumn';
import ResultsTableRow from './ResultsTableRow';

export interface ResultsTableProps {
  records: Record<string, unknown>[];
  columnOrder: string[];
  setDataView: (index: number) => void;
}

const ResultsTable: React.FC<ResultsTableProps> = ({ records, columnOrder, setDataView }) => {
  const { location } = useRouter();

  const filteredColumns = React.useMemo(() => {
    const search = location.search ?? '';
    const params = queryString.parse(search, { arrayFormat: 'bracket' }) as {
      filteredColumns?: string[];
    };
    const selectedColumns =
      params?.filteredColumns?.length > 0 ? params.filteredColumns : columnOrder;

    if (selectedColumns.length === 0) {
      return columnOrder;
    }
    return columnOrder.filter(column => selectedColumns.includes(column));
  }, [location.search, columnOrder]);

  const orderedRecords = React.useMemo(() => {
    return records.map(record => {
      const orderedRecord: Record<string, unknown> = {};
      filteredColumns.forEach(column => {
        orderedRecord[column] = record[column];
      });
      return orderedRecord;
    });
  }, [records, filteredColumns]);

  const handleRowClick = React.useCallback(
    (selectedRow: Record<string, unknown>) => {
      setDataView(orderedRecords.findIndex(row => row === selectedRow));
    },
    [orderedRecords, setDataView]
  );

  return (
    <Box position="relative" zIndex={0}>
      <Table size="small" stickyHeader data-testid="results-table">
        <Table.Head>
          <Table.Row>
            <Box as={Table.HeaderCell} sx={{ zIndex: 1001 }} position="sticky" left={0} />
            {filteredColumns.map(columnName => (
              <Box
                sx={{ zIndex: 1000 }}
                as={Table.HeaderCell}
                key={columnName}
                align={getCellAlignment(records[0][columnName])}
                textTransform="none"
              >
                {columnName}
              </Box>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {orderedRecords.map((record, rowIndex) => (
            <ResultsTableRow
              key={rowIndex}
              record={record}
              onClick={handleRowClick}
              even={rowIndex % 2 === 0}
            />
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
};

export default React.memo(ResultsTable);
