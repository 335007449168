/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Card, Flex, Heading, Text, theme } from 'pouncejs';
import { formatBytes, slugify, toPlural } from 'Helpers/utils';
import dayjs from 'dayjs';

interface VolumeSummaryProps {
  total: number;
  title: string;
  subTitle?: string;
  fromDate?: string;
  toDate?: string;
  color: keyof typeof theme['colors'];
}

const VolumeSummary: React.FC<VolumeSummaryProps> = ({
  total,
  title,
  subTitle,
  fromDate,
  toDate,
  color,
}) => {
  const [bytes, suffix] = formatBytes(total).split(' ');
  return (
    <Card variant="dark" p={6} height="289px">
      <Flex textAlign="center" direction="column">
        <Heading id={slugify(title)} fontWeight="bold" fontSize="medium-large">
          {title}
        </Heading>
        {subTitle && (
          <Text fontSize="small-medium" my={1}>
            {subTitle}
          </Text>
        )}
        <Flex
          direction="column"
          flexGrow={1}
          align="center"
          justify="center"
          borderRadius="medium"
          my={3}
          py={4}
          backgroundColor="navyblue-600"
        >
          <Heading
            as="h2"
            size="3x-large"
            color={color}
            fontWeight="bold"
            aria-describedby={slugify(title)}
          >
            {bytes}
          </Heading>
          <Text as="b">{toPlural(suffix)}</Text>
        </Flex>
        {fromDate && (
          <Box as="dl">
            <Flex justify="space-between" fontSize="small">
              <Text as="dt" fontWeight="bold">
                Start Date
              </Text>
              <Text as="dd">{dayjs(fromDate).format('MMM DD YYYY hh:mmA')}</Text>
            </Flex>
            <Flex justify="space-between" fontSize="small" mt={2}>
              <Text as="dt" fontWeight="bold">
                End Date
              </Text>
              <Text as="dd">{dayjs(toDate).format('MMM DD YYYY hh:mmA')}</Text>
            </Flex>
          </Box>
        )}
      </Flex>
    </Card>
  );
};

export default VolumeSummary;
