/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Form, Formik, FastField } from 'formik';
import FormikAutosave from 'Components/utils/Autosave';
import { Box, Flex } from 'pouncejs';
import { HoldingTankFilters } from 'Generated/schema';
import FormikTextInput from 'Components/fields/TextInput';
import FormikDateRangeInput from 'Components/fields/DateRangeInput';
import { useSchemasManagementContext } from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagementContext/SchemasManagementContext';

export type ViewRawDataFiltersKeys = keyof HoldingTankFilters;

type ViewDataFilters = Pick<HoldingTankFilters, 'contains' | 's3Prefix' | 'from' | 'to'> & {
  excludedPrefix: string;
};

const ViewRawDataFilters = () => {
  const { rawDataFilters, setRawDataFilters } = useSchemasManagementContext();
  const initialFilterValues = React.useMemo(
    () => ({
      s3Prefix: rawDataFilters.s3Prefix,
      contains: rawDataFilters.contains,
      from: rawDataFilters.from,
      to: rawDataFilters.to,
      // FIXME: The whole custom handling for excludedPrefixes should be mitigated when multibox components gets updated
      excludedPrefix: rawDataFilters?.excludedPrefixes[0] || '',
    }),
    [rawDataFilters]
  );

  return (
    <Formik<ViewDataFilters>
      enableReinitialize
      initialValues={initialFilterValues}
      onSubmit={values => {
        setRawDataFilters({
          s3Prefix: values.s3Prefix,
          contains: values.contains,
          from: values.from,
          to: values.to,
          excludedPrefixes: values.excludedPrefix ? [values.excludedPrefix] : [],
        });
      }}
    >
      <Form>
        <FormikAutosave threshold={200} />
        <Flex spacing={4} align="center" width="100%">
          <Box flexGrow={1}>
            <FastField
              data-tid="raw-data-contains-filter"
              name="contains"
              icon="search"
              iconAlignment="left"
              as={FormikTextInput}
              label="Search"
              placeholder="Search for text"
            />
          </Box>
          <FastField
            data-tid="raw-data-prefix-filter"
            name="s3Prefix"
            iconAlignment="left"
            as={FormikTextInput}
            label="Prefix"
            placeholder="Filter by prefix"
          />
          <FastField
            data-tid="raw-data-excluded-prefix-filter"
            name="excludedPrefix"
            iconAlignment="left"
            as={FormikTextInput}
            label="Excluded Prefix"
            placeholder="Excluded prefix"
          />
          <FormikDateRangeInput
            alignment="right"
            withPresets
            withTime
            useUTC
            disableReset
            variant="outline"
            format="MM/DD/YYYY HH:mm"
            labelStart="Date Start (UTC)"
            labelEnd="Date End (UTC)"
            placeholderStart="MM/DD/YY HH:mm"
            placeholderEnd="MM/DD/YY HH:mm"
            nameStart="from"
            nameEnd="to"
          />
        </Flex>
      </Form>
    </Formik>
  );
};

export default React.memo(ViewRawDataFilters);
