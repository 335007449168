/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useMetricsFiltersContext } from 'Components/utils/MetricsFiltersContext';
import { Box, Flex } from 'pouncejs';
import { minutesToString } from 'Helpers/utils';
import { Formik, Form, Field } from 'formik';
import FormikAutosave from 'Components/utils/Autosave';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikDateRangeInput from 'Components/fields/DateRangeInput';
import { MetricsInput } from 'Generated/schema';

export type GraphFiltersValues = Pick<MetricsInput, 'fromDate' | 'toDate' | 'intervalMinutes'>;
export const intervalMinutesOptions = [15, 30, 60, 180, 720, 1440];

const OverviewFilters = () => {
  const { filters, setFilters } = useMetricsFiltersContext();
  return (
    <Formik<GraphFiltersValues> initialValues={filters} onSubmit={setFilters}>
      <Form>
        <FormikAutosave threshold={50} />
        <Flex spacing={4} maxWidth={500}>
          <Box maxWidth={100}>
            <Field
              backgroundColor="navyblue-500"
              variant="solid"
              data-tid="graph-interval"
              as={FormikCombobox}
              label="Interval"
              name="intervalMinutes"
              items={intervalMinutesOptions}
              itemToString={minutesToString}
            />
          </Box>
          <FormikDateRangeInput
            // @ts-ignore
            backgroundColor="navyblue-500"
            variant="solid"
            alignment="right"
            withPresets
            withTime
            disableReset
            format="MM/DD/YYYY HH:mm"
            labelStart="Date Start"
            labelEnd="Date End"
            placeholderStart="MM/DD/YY HH:mm"
            placeholderEnd="MM/DD/YY HH:mm"
            nameStart="fromDate"
            nameEnd="toDate"
          />
        </Flex>
      </Form>
    </Formik>
  );
};

export default OverviewFilters;
