/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import omit from 'lodash/omit';
import { Alert, Box, Card, Flex, TabList, TabPanel, TabPanels, Tabs } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import { compose } from 'Helpers/compose';
import { ListAnalysisPacksInput, Permission } from 'Generated/schema';
import NoResultsFound from 'Components/NoResultsFound';
import ErrorBoundary from 'Components/ErrorBoundary';
import withSEO from 'Hoc/withSEO';
import { TableControlsPagination } from 'Components/utils/TableControls';
import useRequestParamsWithPagination from 'Hooks/useRequestParamsWithPagination';
import PackCard from 'Components/cards/PackCard';
import { DEFAULT_SMALL_PAGE_SIZE } from 'Source/constants';
import { BorderedTab, BorderTabDivider } from 'Components/BorderedTab';
import ListAnalysisPackSources from 'Pages/ListAnalysisPackSources';
import useCheckPermissions from 'Hooks/useCheckPermissions';
import { useListAnalysisPacks } from './graphql/listAnalysisPacks.generated';
import ListPacksFilters from './ListPacksFilters';
import ListPacksSkeleton from './Skeleton';

const ListAnalysisPacks = () => {
  const {
    updatePagingParams,
    requestParams,
    setRequestParamsAndResetPaging,
  } = useRequestParamsWithPagination<ListAnalysisPacksInput & { section: string }>();
  const { loading, error, previousData, data = previousData } = useListAnalysisPacks({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: { ...omit(requestParams, ['section']), pageSize: DEFAULT_SMALL_PAGE_SIZE },
    },
  });
  const canViewPackSources = useCheckPermissions(
    [Permission.PolicyRead, Permission.RuleRead],
    'AND'
  );

  const tabIndex = React.useMemo(() => {
    const section = requestParams?.section;
    switch (section) {
      case 'packs':
        return 0;
      case 'pack-sources':
        return 1;
      default:
        return 0;
    }
  }, [requestParams?.section]);

  if (loading && !data) {
    return <ListPacksSkeleton />;
  }

  if (error) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load packs"
          description={
            extractErrorMessage(error) ||
            'There was an error when performing your request, please contact support@runpanther.io'
          }
        />
      </Box>
    );
  }

  // Get query results while protecting against exceptions
  const packItems = data?.listAnalysisPacks.packs;
  const pagingData = data?.listAnalysisPacks.paging;

  const handleTabChange = index => {
    let section;
    switch (index) {
      case 0:
        section = 'packs';
        break;
      case 1:
        section = 'pack-sources';
        break;
      default:
        section = 'packs';
        break;
    }
    setRequestParamsAndResetPaging({ section });
  };

  return (
    <ErrorBoundary>
      <Card as="section" position="relative">
        <Tabs index={tabIndex} onChange={handleTabChange}>
          <Box px={2}>
            <TabList>
              <BorderedTab data-tid="general-settings-main-view">Packs</BorderedTab>

              {canViewPackSources && (
                <BorderedTab data-tid="general-settings-pack-sources-view">
                  Detection Pack Sources
                </BorderedTab>
              )}
            </TabList>
          </Box>
          <BorderTabDivider />
          <Box p={6}>
            <TabPanels>
              <TabPanel unmountWhenInactive>
                <Flex direction="column" spacing={3}>
                  <ListPacksFilters />
                  {packItems.length ? (
                    packItems.map(pack => <PackCard key={pack.id} pack={pack} />)
                  ) : (
                    <Box my={8}>
                      <NoResultsFound title="No Packs found" />
                    </Box>
                  )}
                  <TableControlsPagination
                    page={pagingData.thisPage}
                    totalPages={pagingData.totalPages}
                    onPageChange={updatePagingParams}
                  />
                </Flex>
              </TabPanel>
              {canViewPackSources && (
                <TabPanel unmountWhenInactive>
                  <ErrorBoundary>
                    <ListAnalysisPackSources />
                  </ErrorBoundary>
                </TabPanel>
              )}
            </TabPanels>
          </Box>
        </Tabs>
      </Card>
    </ErrorBoundary>
  );
};

export default compose(withSEO({ title: 'Packs' }), React.memo)(ListAnalysisPacks);
