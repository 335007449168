/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { FastField, Field, useFormikContext } from 'formik';
import { Card, Flex, FormHelperText } from 'pouncejs';
import { LogTypeAccessKind } from 'Generated/schema';
import { RoleFormValues } from 'Components/forms/RoleForm';
import { useListAvailableLogTypes } from 'Source/graphql/queries';
import Radio from 'Components/fields/Radio';
import MultiComboBox from 'Components/fields/MultiComboBox';

const LogTypeAccessPanel: React.FC = () => {
  const { data } = useListAvailableLogTypes();
  const {
    values: { logTypeAccessKind },
  } = useFormikContext<RoleFormValues>();

  return (
    <Card p={2} variant="dark">
      <Flex direction="column">
        <FastField
          as={Radio}
          name="logTypeAccessKind"
          value={LogTypeAccessKind.AllowAll}
          label="Full access to logs"
        />
        <FastField
          as={Radio}
          name="logTypeAccessKind"
          value={LogTypeAccessKind.Allow}
          label="Allow access to selected Log Types"
        />
        {logTypeAccessKind === LogTypeAccessKind.Allow && (
          <Flex direction="column" spacing={2} ml={42} mr={6} mb={2}>
            <Field
              as={MultiComboBox}
              name="logTypeAccess"
              searchable
              aria-describedby="log-type-access-description"
              itemToGroup={item => item.split('.')[0]}
              items={data?.listAvailableLogTypes?.logTypes || []}
              label="Select Log Types"
              placeholder="Select the Log Types that should be accessible by this role"
            />
            <FormHelperText id="log-type-access-description">
              You will be able to query and see results for all the tables related to the Log Types
              selected above
            </FormHelperText>
          </Flex>
        )}
        <FastField
          as={Radio}
          name="logTypeAccessKind"
          value={LogTypeAccessKind.Deny}
          label="Deny access to selected Log Types"
        />
        {logTypeAccessKind === LogTypeAccessKind.Deny && (
          <Flex direction="column" spacing={2} ml={42} mr={6} mb={2}>
            <Field
              as={MultiComboBox}
              name="logTypeAccess"
              searchable
              aria-describedby="log-type-access-description"
              itemToGroup={item => item.split('.')[0]}
              items={data?.listAvailableLogTypes?.logTypes || []}
              label="Select Log Types"
              placeholder="Select the Log Types that shouldn't be accessible by this role"
            />
            <FormHelperText id="log-type-access-description">
              You will be able to query and see results for all the tables except the ones related
              to the Log Types selected above
            </FormHelperText>
          </Flex>
        )}
      </Flex>
    </Card>
  );
};

export default LogTypeAccessPanel;
