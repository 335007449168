/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../../../__generated__/schema';

import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetAlertMetricsVariables = {
  input: Types.MetricsInput;
};

export type GetAlertMetrics = {
  getMetrics: Pick<Types.MetricsResponse, 'intervalMinutes'> & {
    alertsBySeverity?: Types.Maybe<
      Pick<Types.LongSeriesData, 'timestamps'> & {
        series: Array<Pick<Types.LongSeries, 'label' | 'values'>>;
      }
    >;
    totalAlertsDelta?: Types.Maybe<Array<Pick<Types.LongSerie, 'label' | 'value'>>>;
    alertsByRuleID?: Types.Maybe<
      Array<Pick<Types.LongSeriesWithEntityID, 'label' | 'value' | 'entityId'>>
    >;
  };
};

export const GetAlertMetricsDocument = gql`
  query GetAlertMetrics($input: MetricsInput!) {
    getMetrics(input: $input) {
      alertsBySeverity {
        series {
          label
          values
        }
        timestamps
      }
      totalAlertsDelta {
        label
        value
      }
      alertsByRuleID {
        label
        value
        entityId
      }
      intervalMinutes
    }
  }
`;

/**
 * __useGetAlertMetrics__
 *
 * To run a query within a React component, call `useGetAlertMetrics` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertMetrics` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertMetrics({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAlertMetrics(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetAlertMetrics, GetAlertMetricsVariables>
) {
  return ApolloReactHooks.useQuery<GetAlertMetrics, GetAlertMetricsVariables>(
    GetAlertMetricsDocument,
    baseOptions
  );
}
export function useGetAlertMetricsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAlertMetrics, GetAlertMetricsVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetAlertMetrics, GetAlertMetricsVariables>(
    GetAlertMetricsDocument,
    baseOptions
  );
}
export type GetAlertMetricsHookResult = ReturnType<typeof useGetAlertMetrics>;
export type GetAlertMetricsLazyQueryHookResult = ReturnType<typeof useGetAlertMetricsLazyQuery>;
export type GetAlertMetricsQueryResult = ApolloReactCommon.QueryResult<
  GetAlertMetrics,
  GetAlertMetricsVariables
>;
export function mockGetAlertMetrics({
  data,
  variables,
  errors,
}: {
  data: GetAlertMetrics;
  variables?: GetAlertMetricsVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetAlertMetricsDocument, variables },
    result: { data, errors },
  };
}
