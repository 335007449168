/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Card, Grid } from 'pouncejs';
import TimeSeriesChart from 'Components/charts/TimeSeriesChart';
import { useMetricsFiltersContext } from 'Components/utils/MetricsFiltersContext';
import { LongSeriesData } from 'Generated/schema';
import VolumeSummary from '../../VolumeSummary';
import ChartTooltip from './ChartTooltip';

interface EventVolumeTabProps {
  volumeSeries: LongSeriesData;
}

const EventVolumeTab: React.FC<EventVolumeTabProps> = ({ volumeSeries }) => {
  const {
    filters: { fromDate, toDate },
    resultProjectionStartDate,
  } = useMetricsFiltersContext();

  const volumeChartData = React.useMemo(() => {
    // Only one line should be displayed in the bytes processed chart, containing
    // the sum of all log types. When the user hovers over a specific time in the chart
    // he can see a breakdown in the popup tooltip.
    return {
      // Calculate the sum of all bytes per log type
      series: [
        {
          label: 'Total',
          values: volumeSeries.timestamps.map((t, index) =>
            volumeSeries.series.reduce((prev, current) => prev + current.values[index], 0)
          ),
          color: 'red-300' as const,
        },
      ],
      timestamps: volumeSeries.timestamps,
      // Store the original bytes per log type in metadata to use them in the tooltip
      metadata: volumeSeries.timestamps.map((t, index) =>
        volumeSeries.series
          .filter(s => s.values[index] !== 0)
          .map(s => ({ label: s.label, value: s.values[index] }))
      ),
    };
  }, [volumeSeries]);

  const totalBytes = React.useMemo(() => {
    return volumeChartData.series[0].values.reduce((prev, current) => prev + current);
  }, [volumeChartData]);

  return (
    <Grid templateColumns="1fr 3fr" gap={4}>
      <VolumeSummary
        title="Total Volume Processed"
        total={totalBytes}
        color="red-300"
        fromDate={fromDate}
        toDate={toDate}
      />
      <Card variant="dark" height={289} py={5} pl={4}>
        <TimeSeriesChart
          projectionStartDate={resultProjectionStartDate}
          chartId="event-volume"
          data={volumeChartData}
          zoomable
          hideLegend
          units="bytes"
          scaleControls={false}
          tooltipComponent={ChartTooltip}
        />
      </Card>
    </Grid>
  );
};

export default React.memo(EventVolumeTab);
