/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Flex, Text, Box, SimpleGrid, Tooltip, Icon } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import Panel from 'Components/Panel';
import ErrorBoundary from 'Components/ErrorBoundary';
import { useGetDataMetrics } from 'Source/graphql/queries/getDataMetrics.generated';
import { useMetricsFiltersContext } from 'Components/utils/MetricsFiltersContext';
import DataOverviewSkeleton from './Skeleton';
import OverviewFilters from '../OverviewFilters/OverviewFilters';
import EventAnalyticsSection from './EventAnalyticsSection';
import EventsByLatency from './EventsByLatency';
import DataVolumeSection from './DataVolumeSection';

const DataOverview: React.FC = () => {
  const { filters } = useMetricsFiltersContext();
  const { loading, previousData, data = previousData, error } = useGetDataMetrics({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        metricNames: [
          'eventsProcessed',
          'bytesProcessed',
          'eventsLatencyPeriod',
          'bytesIngested',
          'bytesQueried',
        ],
        ...filters,
      },
    },
  });

  if (loading && !data) {
    return <DataOverviewSkeleton />;
  }

  if (error) {
    return (
      <Alert
        variant="error"
        title="We can't display this content right now"
        description={extractErrorMessage(error)}
      />
    );
  }

  const {
    eventsProcessed,
    bytesProcessed,
    bytesIngested,
    bytesQueried,
    eventsLatencyPeriod,
  } = data?.getMetrics;

  return (
    <ErrorBoundary>
      <Box as="article" mb={6} position="relative">
        <OverviewFilters />
        <SimpleGrid columns={1} spacingY={4}>
          <EventAnalyticsSection
            eventsProcessed={eventsProcessed}
            bytesProcessed={bytesProcessed}
          />
          <DataVolumeSection bytesIngested={bytesIngested} bytesQueried={bytesQueried} />
          <Panel
            title={
              <Flex align="center" spacing={3}>
                <Text>Average Data Latency by Log Type</Text>
                <Tooltip
                  content={
                    <Flex direction="column" spacing={2} maxWidth={420}>
                      <Text>
                        This is the delay between when an event happened and when Panther processed
                        it.
                      </Text>
                      <Text>
                        It is calculated by taking the difference in the event time and the parse
                        time and it includes the latency introduced by the system sending the data
                        to Panther.
                      </Text>
                    </Flex>
                  }
                >
                  <Icon type="info" size="medium" />
                </Tooltip>
              </Flex>
            }
          >
            <EventsByLatency latencies={eventsLatencyPeriod} />
          </Panel>
        </SimpleGrid>
      </Box>
    </ErrorBoundary>
  );
};

export default DataOverview;
