/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { PutUserSchemaInput, S3PrefixLogTypes } from 'Generated/schema';
import { Box, Collapse, Flex, IconButton, Text } from 'pouncejs';
import { useFormikContext } from 'formik';
import { S3PrefixLogTypesFormValues } from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagement';
import FlatBadge from 'Components/badges/FlatBadge';
import LogTypeBadge from 'Pages/Integrations/LogSources/S3/SchemasManagement/S3PrefixLogTypesForm/LogTypeBadge';
import LimitItemDisplay from 'Components/LimitItemDisplay';

export interface PrefixCard extends S3PrefixLogTypes {
  index: number;
  loadingSchema: string;
  onEditSchema: (schema: PutUserSchemaInput) => void;
}

const PrefixCard: React.FC<PrefixCard> = ({
  prefix,
  excludedPrefixes,
  logTypes,
  index,
  loadingSchema,
  onEditSchema,
}) => {
  const [open, setOpen] = React.useState(true);
  const { initialValues } = useFormikContext<S3PrefixLogTypesFormValues>();

  const toggle = React.useCallback(() => setOpen(v => !v), [setOpen]);

  const existingPrefix = initialValues.s3PrefixLogTypes.find(
    ({ prefix: initialPrefix }) => initialPrefix === prefix
  );

  return (
    <React.Fragment>
      <Flex p={4} px={6} justify="space-between" align="center">
        <Flex spacing={4} align="center">
          <Box minWidth={36}>
            <IconButton
              variant="solid"
              variantBorderStyle="circle"
              variantColor="blue-300"
              icon={open ? 'caret-up' : 'caret-down'}
              onClick={toggle}
              size="medium"
              aria-label={open ? 'Hide prefixes' : 'Show prefixes'}
            />
          </Box>
          <Text> {prefix.length ? prefix : '*'} </Text>
          {Boolean(excludedPrefixes.length) && (
            <>
              <Text fontStyle="italic" fontSize="medium">
                - Exclusions:
              </Text>
              <Flex maxWidth="70%" spacing={2} gap={2} align="center" wrap="wrap">
                <LimitItemDisplay limit={4}>
                  {excludedPrefixes.map(excludedPrefix => {
                    return (
                      <Box
                        my={1}
                        key={excludedPrefix}
                        borderColor="navyblue-300"
                        backgroundColor="navyblue-400"
                        borderWidth={2}
                        borderStyle="solid"
                        px={2}
                        fontSize="small"
                      >
                        {excludedPrefix}
                      </Box>
                    );
                  })}
                </LimitItemDisplay>
              </Flex>
            </>
          )}
          {!existingPrefix && (
            <FlatBadge backgroundColor="navyblue-700" color="blue-300">
              NEW
            </FlatBadge>
          )}
        </Flex>
      </Flex>

      <Collapse open={open}>
        {logTypes.length ? (
          <Flex mx={4} wrap="wrap">
            {logTypes.map(logType => {
              return (
                <LogTypeBadge
                  prefix={prefix}
                  key={`${index}-${logType}`}
                  logType={logType}
                  isNewPrefix={!existingPrefix}
                  loading={loadingSchema === logType}
                  onEditSchema={onEditSchema}
                />
              );
            })}
          </Flex>
        ) : (
          <Flex backgroundColor="navyblue-400" justify="center" align="center" p={4} mx={6} mb={2}>
            <Text fontStyle="italic" color="gray-400">
              No schemas for prefix
            </Text>
          </Flex>
        )}
      </Collapse>
    </React.Fragment>
  );
};

export default PrefixCard;
