/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { AbstractButton, Alert, Box, Flex, FormHelperText, IconButton, Link } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import { FastField, Field, FieldArray, FormikErrors, useFormikContext } from 'formik';
import FormikTextInput from 'Components/fields/TextInput';
import FormikMultiCombobox from 'Components/fields/MultiComboBox';
import { WizardPanel } from 'Components/Wizard';
import logo from 'Assets/logProviders/s3.svg';
import FormikCombobox from 'Components/fields/ComboBox';
import { LogStreamTypeEnum } from 'Generated/schema';
import { S3_BUCKET_DETAILS_DOC_URL } from 'Source/constants';
import EditIntegrationActions from 'Pages/Integrations/components/EditIntegrationActions';
import useModal from 'Hooks/useModal';
import { S3LogSourceWizardValues } from '../S3LogSourceWizard';
import S3StreamTypeHelperModal from './S3StreamTypeHelperModal';

export const s3PrefixLogTypesInitialValues = {
  prefix: '',
  excludedPrefixes: [],
  logTypes: [],
};

const streamTypesDescription: Record<LogStreamTypeEnum, string> = {
  [LogStreamTypeEnum.CloudWatchLogs]: 'Events are delivered to S3 from CloudWatch Logs.',
  [LogStreamTypeEnum.JsonArray]: 'Events are in JSON Array format.',
  [LogStreamTypeEnum.Lines]: 'Events are line delimited.',
};

const EMPTY_ARRAY = [];

const canContinueWizard = (errors: FormikErrors<S3LogSourceWizardValues>) => {
  if (
    errors.integrationLabel ||
    errors.awsAccountId ||
    errors.s3Bucket ||
    errors.logStreamType ||
    errors.kmsKey ||
    (Array.isArray(errors?.s3PrefixLogTypes) &&
      errors.s3PrefixLogTypes.some(
        s3PrefixLogTypesObject =>
          s3PrefixLogTypesObject?.logTypes ||
          s3PrefixLogTypesObject?.prefix ||
          s3PrefixLogTypesObject?.excludedPrefixes
      ))
  ) {
    return false;
  }
  return true;
};

const logStreamTypeEnumItems = Object.values(LogStreamTypeEnum);

const S3SourceConfigurationPanel: React.FC = () => {
  const { initialValues, values, dirty, errors, status, setFieldValue } = useFormikContext<
    S3LogSourceWizardValues
  >();
  const { showModal } = useModal();
  const editMode = !!initialValues.integrationId;

  const logStreamTypeText = streamTypesDescription[values.logStreamType];

  return (
    <WizardPanel>
      <Box width={655} m="auto">
        <WizardPanel.Heading
          title={editMode ? 'Update your source' : 'Configure your source'}
          subtitle={
            editMode
              ? 'Feel free to make any changes to your log source'
              : 'We need to know where to get your logs from'
          }
          logo={logo}
          divider={null}
          subtitleProps={{ color: 'gray-300', fontWeight: 'normal' }}
        />

        <ErrorBoundary>
          <Flex direction="column" spacing={4}>
            <Field
              name="integrationLabel"
              as={FormikTextInput}
              label="Name"
              placeholder="A nickname for this log analysis source"
              required
            />
            <Field
              name="awsAccountId"
              as={FormikTextInput}
              label="AWS Account ID"
              placeholder="The AWS Account ID that the S3 log bucket lives in"
              disabled={editMode}
              required
            />
            <Field
              name="s3Bucket"
              as={FormikTextInput}
              label="Bucket Name"
              placeholder="The name of the S3 bucket that holds the logs"
              disabled={editMode}
              required
            />
            <Field
              name="kmsKey"
              as={FormikTextInput}
              label="KMS Key (optional)"
              placeholder="For encrypted logs, add the KMS ARN for decryption"
            />
            <Box as="fieldset">
              <Field
                name="logStreamType"
                as={FormikCombobox}
                items={logStreamTypeEnumItems}
                showClearSelectionControl={false}
                label="Stream Type"
                aria-describedby="logStreamType"
              />
              {logStreamTypeText && (
                <FormHelperText color="white" id="logStreamType" mt={2}>
                  {logStreamTypeText} See
                  <AbstractButton
                    aria-label="Toggle log stream type example"
                    color="blue-200"
                    fontWeight="medium"
                    mx={1}
                    onClick={() =>
                      showModal(<S3StreamTypeHelperModal streamType={values.logStreamType} />, {
                        showCloseButton: true,
                      })
                    }
                  >
                    an example.
                  </AbstractButton>
                  Need more help? Check{' '}
                  <Link external href={S3_BUCKET_DETAILS_DOC_URL}>
                    our documentation
                  </Link>
                </FormHelperText>
              )}
            </Box>
          </Flex>

          <Flex direction="column" spacing={4} pt={6}>
            {!editMode && (
              <Alert
                title="You can add S3 Prefixes, Exclusion filters and Schemas now or you can add them later"
                variant="info"
              />
            )}
            <FieldArray
              name="s3PrefixLogTypes"
              render={arrayHelpers => {
                return values.s3PrefixLogTypes.map((_, index, array) => {
                  return (
                    <Flex
                      key={index}
                      p={4}
                      position="relative"
                      backgroundColor="navyblue-500"
                      spacing={4}
                      direction="column"
                    >
                      <Flex
                        position="absolute"
                        left="100%"
                        top={0}
                        bottom={0}
                        align="center"
                        my={0}
                        spacing={2}
                        ml={5}
                      >
                        {array.length > 1 && (
                          <IconButton
                            size="medium"
                            icon="close-outline"
                            variantColor="blue-300"
                            variantBorderStyle="circle"
                            aria-label={`Remove prefix ${index}`}
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        )}
                        {index + 1 === array.length && (
                          <IconButton
                            size="medium"
                            icon="add-circle"
                            variantColor="blue-300"
                            variantBorderStyle="circle"
                            aria-label="Add prefix"
                            onClick={() =>
                              setFieldValue(
                                `s3PrefixLogTypes.${index + 1}`,
                                s3PrefixLogTypesInitialValues
                              )
                            }
                          />
                        )}
                      </Flex>

                      <Field
                        name={`s3PrefixLogTypes.${index}.prefix`}
                        label="S3 Prefix Filter (leave empty to allow all)"
                        placeholder="Limit logs to objects that start with matching characters"
                        as={FormikTextInput}
                      />
                      <FastField
                        name={`s3PrefixLogTypes.${index}.excludedPrefixes`}
                        label="S3 Prefix Exclusion Filter"
                        placeholder="Exclude logs to objects that start with matching characters"
                        items={EMPTY_ARRAY}
                        as={FormikMultiCombobox}
                        allowAdditions
                        searchable
                      />

                      <Field
                        name={`s3PrefixLogTypes.${index}.logTypes`}
                        label="Log Types"
                        placeholder="The types of logs that are collected"
                        items={status.availableLogTypes}
                        itemToGroup={(item: string) => item.split('.')[0]}
                        as={FormikMultiCombobox}
                        searchable
                        required
                      />
                    </Flex>
                  );
                });
              }}
            />
          </Flex>
        </ErrorBoundary>
      </Box>
      {editMode ? (
        <EditIntegrationActions />
      ) : (
        <WizardPanel.Actions>
          <WizardPanel.ActionNext disabled={!dirty || !canContinueWizard(errors)}>
            Continue Setup
          </WizardPanel.ActionNext>
        </WizardPanel.Actions>
      )}
    </WizardPanel>
  );
};

export default S3SourceConfigurationPanel;
