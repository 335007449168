/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Box, Flex, Heading, Card, Collapse, IconButton } from 'pouncejs';

interface CollapsablePanelProps {
  title: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
}

const CollapsablePanel: React.FC<CollapsablePanelProps> = ({
  title,
  actions,
  children,
  ...rest
}) => {
  const [open, setOpen] = React.useState(true);
  const toggle = React.useCallback(() => setOpen(v => !v), [setOpen]);
  return (
    <Card as="section" width={1} {...rest}>
      <Flex
        p={6}
        borderBottom="1px solid"
        borderColor={children ? 'navyblue-300' : 'transparent'}
        justify="space-between"
        align="center"
        maxHeight={80}
      >
        <Flex align="center">
          <IconButton
            variant="solid"
            variantColor="transparent"
            icon={open ? 'caret-up' : 'caret-down'}
            onClick={toggle}
            size="medium"
            aria-label="Toggle Editor visibility"
          />
          <Heading size="x-small" as="h4">
            {title}
          </Heading>
        </Flex>
        {actions}
      </Flex>
      <Collapse open={open}>{children && <Box p={6}>{children}</Box>}</Collapse>
    </Card>
  );
};

export default React.memo(CollapsablePanel);
