/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import storage from 'Helpers/storage';
import { ANALYTICS_CONSENT_STORAGE_KEY, PANTHER_CONFIG } from 'Source/constants';
import md5 from 'crypto-js/md5';
import useAuth from 'Hooks/useAuth';
import useModal from 'Hooks/useModal';
import usePrevious from 'Hooks/usePrevious';
import ConfirmModal from 'Components/modals/ConfirmModal';
import { useGetAnalyticsConsent } from './graphql/getAnalyticsConsent.generated';

// You might believe this would be better as a hook. Hooks though have a component-wide scope where
// they are used. By making it a component we can include it at a particular "place" in a JSX render
// of a component, so that it only gets included in certain cases.
const AnalyticsTracker: React.FC = () => {
  const { userInfo } = useAuth(); // prettier-ignore
  const { data } = useGetAnalyticsConsent();
  const { showModal, hideModal } = useModal();

  // We initially use the localStorage value to boot with and then replace it with the latest stored
  const hasConsentedToAnalytics =
    data?.generalSettings.analyticsConsent ??
    storage.local.read<boolean>(ANALYTICS_CONSENT_STORAGE_KEY);

  const hadPreviouslyConsentedToAnalytics = usePrevious(hasConsentedToAnalytics);
  const pendoVisitorId = React.useMemo(() => userInfo && md5(userInfo.email).toString(), [
    userInfo,
  ]);

  React.useEffect(() => {
    if (pendoVisitorId && hasConsentedToAnalytics) {
      // The first time the pendo getss imported, it will self-execute and download pendo.js
      // Future invocations will bypass the snippet code execution, so the snippet *WILL NEVER*
      // be loaded more than once (that's how webpack dynamic imports work)
      // Also, it's safe to call `.initialize(...)` multiple times, since it will simply re-initialize
      // pendo with the new options
      // https://developers.pendo.io/docs/?bash#installation
      import(/* webpackChunkName: "pendo-snippet" */ 'Public/snippets/pendo').then(() => {
        window.pendo.initialize({
          visitor: { id: pendoVisitorId },
          account: { id: PANTHER_CONFIG.AWS_ACCOUNT_ALIAS },
        });
      });
    }

    if (!hasConsentedToAnalytics && hadPreviouslyConsentedToAnalytics) {
      // When a user that was previously opted in, wants to opt-out, then a full refresh is needed.
      // We can't tell pendo.io to dynamically stop tracking. We have to fully reload the app so
      // that the pendo snippet never loads
      // https://support.pendo.io/hc/en-us/articles/360046272771#AdditionalConfigurations
      showModal(
        <ConfirmModal
          variant="destructive"
          subtitle="In order for certain changes to take effect, the application will need to be refreshed"
          onClose={hideModal}
          onConfirm={() => {
            window.location.reload();
          }}
        />,
        { title: 'Tracking preferences have been updated' }
      );
    }
    // `hadPreviouslyConsentedToAnalytics` is a React Ref so it shouldn't contribute here. We are
    // 100% safe since this only updates when `hasConsentedToAnalytics` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasConsentedToAnalytics, pendoVisitorId, showModal, hideModal]);

  return null;
};

export default AnalyticsTracker;
