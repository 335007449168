/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Box, Flex, Heading, Img } from 'pouncejs';
import CelebrateSVG from 'Assets/illustrations/celebrate.svg';
import urls from 'Source/urls';

const EmptyDataFallback: React.FC<{ sourceId: string }> = ({ sourceId }) => {
  return (
    <Flex spacing={5} direction="column" align="center">
      <Img src={CelebrateSVG} nativeWidth={167} nativeHeight={126} alt="Two people celebrating" />
      <Heading as="h3" size="small" fontWeight="medium">
        Add your first Schema
      </Heading>
      <Box
        as={RRLink}
        px={4}
        py={3}
        borderRadius="medium"
        fontSize="large"
        backgroundColor="blue-300"
        aria-label="link to source schema management"
        to={`${urls.integrations.logSources.schemasManagement(sourceId)}`}
      >
        Edit Schemas
      </Box>
    </Flex>
  );
};

export default EmptyDataFallback;
