/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import TestReport from 'Pages/Integrations/LogSources/S3/SchemasManagement/TestReport';
import EditSchemaForm from 'Pages/Integrations/LogSources/S3/SchemasManagement/EditSchemaForm';
import { Flex } from 'pouncejs';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess/RoleRestrictedAccess';
import { Permission, PutUserSchemaInput } from 'Generated/schema';
import TestSchemasSection from 'Pages/Integrations/LogSources/S3/SchemasManagement/TestSchemasSection/TestSchemasSection';
import S3PrefixLogTypesForm from 'Pages/Integrations/LogSources/S3/SchemasManagement/S3PrefixLogTypesForm';
import ViewRawData from 'Pages/Integrations/LogSources/S3/SchemasManagement/ViewRawData';
import { useFormikContext } from 'formik';
import { ENABLE_HOLDING_TANK } from 'Source/constants';

const SchemasManagementBase: React.FC<{ sourceLabel: string; savingSchemaName: string }> = ({
  sourceLabel,
  savingSchemaName,
}) => {
  const [editingSchema, setEditingSchema] = React.useState<PutUserSchemaInput>(null);
  const [showTestReport, setShowTestReport] = React.useState(false);
  const { status } = useFormikContext();

  if (!ENABLE_HOLDING_TANK) {
    return (
      <Flex direction="column" spacing={6}>
        <S3PrefixLogTypesForm
          loadingSchema={savingSchemaName}
          onEditSchema={setEditingSchema}
          sourceLabel={sourceLabel}
        />
        <RoleRestrictedAccess allowedPermissions={[Permission.LogSourceRawDataRead]}>
          <ViewRawData />
        </RoleRestrictedAccess>
      </Flex>
    );
  }

  if (showTestReport) {
    return <TestReport onBack={() => setShowTestReport(false)} />;
  }
  if (editingSchema) {
    return (
      <EditSchemaForm
        editingSchema={editingSchema}
        availableLogTypes={status.availableLogTypes}
        onDoneEditing={() => {
          setEditingSchema(null);
        }}
      />
    );
  }

  return (
    <Flex direction="column" spacing={6}>
      <RoleRestrictedAccess allowedPermissions={[Permission.LogSourceRawDataRead]}>
        <TestSchemasSection onShowTestReport={() => setShowTestReport(true)} />
      </RoleRestrictedAccess>
      <S3PrefixLogTypesForm
        loadingSchema={savingSchemaName}
        onEditSchema={setEditingSchema}
        sourceLabel={sourceLabel}
      />
      <RoleRestrictedAccess allowedPermissions={[Permission.LogSourceRawDataRead]}>
        <ViewRawData />
      </RoleRestrictedAccess>
    </Flex>
  );
};

export default SchemasManagementBase;
