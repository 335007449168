/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import { useSnackbar } from 'pouncejs';
import { extractErrorMessage } from 'Helpers/utils';
import { useGetRunningTask } from './graphql/getRunningTask.generated';

const useGetSourceRunningTask = (sourceId?: string) => {
  const { pushSnackbar } = useSnackbar();
  const { data } = useGetRunningTask({
    fetchPolicy: 'network-only',
    skip: !sourceId,
    variables: {
      input: {
        sourceId,
      },
    },
    onError: error => {
      pushSnackbar({
        variant: 'error',
        title: 'Something went wrong!',
        description: extractErrorMessage(error),
      });
    },
  });

  const job = data?.getRunningTask;

  return {
    job,
  };
};

export default useGetSourceRunningTask;
