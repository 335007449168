/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */
import React from 'react';
import { useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import { Box, Button, Text, Flex } from 'pouncejs';
import useModal from 'Hooks/useModal';
import SetS3PrefixesModal from 'Components/modals/SetS3PrefixesModal';
import Breadcrumbs from 'Components/Breadcrumbs';
import SubmitButton from 'Components/buttons/SubmitButton';
import useRouter from 'Hooks/useRouter';
import { PutUserSchemaInput, JobStatus } from 'Generated/schema';
import { appendDataSchemaPrefix } from 'Helpers/utils';
import CollapsablePanel from 'Components/CollapsablePanel';
import { useSchemasManagementContext } from 'Pages/Integrations/LogSources/S3/SchemasManagement/SchemasManagementContext';
import PrefixCard from 'Pages/Integrations/LogSources/S3/SchemasManagement/S3PrefixLogTypesForm/PrefixCard';
import { S3PrefixLogTypesFormValues } from '../SchemasManagement';
import EmptyDataFallback from '../EmptyDataFallback';
import { s3PrefixLogTypesInitialValues } from '../../S3LogSourceWizard';
import InferJobOverlay from './InferJobOverlay';

const S3PrefixLogTypesForm: React.FC<{
  loadingSchema: string;
  sourceLabel: string;
  onEditSchema: (schema: PutUserSchemaInput) => void;
}> = ({ loadingSchema, onEditSchema, sourceLabel }) => {
  const { showModal } = useModal();
  const { history } = useRouter();
  const { values, setValues } = useFormikContext<S3PrefixLogTypesFormValues>();
  const { schemaTestJob, inferSchemaJob } = useSchemasManagementContext();
  const onEdit = React.useCallback(() => {
    const onModalSubmit = (submittedValues: S3PrefixLogTypesFormValues) => {
      setValues(submittedValues);
    };
    showModal(
      <SetS3PrefixesModal
        draftSchemaNames={values.draftSchemas.map(s => appendDataSchemaPrefix(s.name))}
        initialValues={values}
        onSuccess={onModalSubmit}
      />,
      {
        title: 'Stream Type & Schemas',
        showCloseButton: true,
      }
    );
  }, [showModal, values, setValues]);

  const isTestJobRunning = schemaTestJob?.status === JobStatus.Running;
  const isInferJobRunning = inferSchemaJob?.status === JobStatus.Running;

  return (
    <Box position="relative">
      {isInferJobRunning && <InferJobOverlay />}
      <CollapsablePanel
        title={
          <Text>
            Schemas -{' '}
            <Text as="span" fontWeight="bold">
              {sourceLabel}
            </Text>
          </Text>
        }
        actions={
          <Button
            disabled={isTestJobRunning}
            variant="solid"
            onClick={onEdit}
            size="large"
            aria-label="Edit prefixes"
          >
            Edit Schemas & Stream Type
          </Button>
        }
      >
        {isEqual(values.s3PrefixLogTypes, [s3PrefixLogTypesInitialValues]) ? (
          <EmptyDataFallback onEditPrefixes={onEdit} />
        ) : (
          values.s3PrefixLogTypes.map((s3PrefixLogTypesElement, i) => {
            return (
              <Box backgroundColor="navyblue-500" key={i} pb={4} mb={4}>
                <PrefixCard
                  {...s3PrefixLogTypesElement}
                  loadingSchema={loadingSchema}
                  onEditSchema={onEditSchema}
                  index={i}
                />
              </Box>
            );
          })
        )}
        <Breadcrumbs.Actions>
          <Flex spacing={4} justify="flex-end">
            <Button
              variantColor="gray-600"
              icon="close-outline"
              aria-label="Cancel editing"
              onClick={history.goBack}
            >
              Cancel
            </Button>
            <SubmitButton variantColor="green-400" icon="save" aria-label="Save editing">
              Save
            </SubmitButton>
          </Flex>
        </Breadcrumbs.Actions>
      </CollapsablePanel>
    </Box>
  );
};

export default S3PrefixLogTypesForm;
