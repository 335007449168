/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, SimpleGrid, Heading } from 'pouncejs';
import ErrorBoundary from 'Components/ErrorBoundary';
import urls from 'Source/urls';
import useUrlParams from 'Hooks/useUrlParams';
import { extractErrorMessage } from 'Helpers/utils';
import withRoleRestrictedAccess from 'Hoc/withRoleRestrictedAccess';
import { Permission } from 'Generated/schema';
import RoleRestrictedAccess from 'Components/utils/RoleRestrictedAccess';
import LinkButton from 'Components/buttons/LinkButton';
import Page403 from 'Pages/403';
import { compose } from 'Helpers/compose';
import withSEO from 'Hoc/withSEO';
import Panel from 'Components/Panel';
import RoleFilters, { RoleFilterValues } from './RoleFilters';
import ListRolesPageEmptyDataFallback from './EmptyDataFallback';
import { useListRoles } from './graphql/listRoles.generated';
import ListRolesPageSkeleton from './Skeleton';
import RoleItem from './RoleItem';

const ListRolesPage: React.FC = () => {
  const { urlParams, updateUrlParams } = useUrlParams<RoleFilterValues>();

  const queryParams = React.useMemo(
    () => ({
      sortDir: urlParams.sortDir,
      nameContains: urlParams.nameContains,
    }),
    [urlParams]
  );

  const initialFilterValues = React.useMemo(
    () => ({
      sortDir: urlParams.sortDir || null,
      nameContains: queryParams.nameContains || '',
    }),
    // FIXME: Look into missing effect dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryParams]
  );

  const { loading, previousData, data = previousData, error, variables } = useListRoles({
    errorPolicy: 'all',
    variables: {
      sortDir: queryParams.sortDir,
      nameContains: queryParams.nameContains,
    },
  });

  const hasActiveFilters = Object.values(variables).some(Boolean);
  if (loading && !data) {
    return <ListRolesPageSkeleton />;
  }

  if (error) {
    return (
      <Alert
        variant="error"
        title="Couldn't load roles"
        description={
          extractErrorMessage(error) ||
          'There was an error when performing your request, please contact support@runpanther.io'
        }
      />
    );
  }

  const noRolesFound = !data.roles.length;
  if (noRolesFound && !hasActiveFilters && !loading) {
    return <ListRolesPageEmptyDataFallback />;
  }

  return (
    <Box mb={6}>
      <Panel
        data-tracking-page="list-roles"
        title="Roles"
        actions={
          <RoleRestrictedAccess allowedPermissions={[Permission.UserModify]}>
            <LinkButton
              title="Create new Role"
              data-tid="create-new-role"
              to={urls.settings.roles.create()}
            >
              Create New
            </LinkButton>
          </RoleRestrictedAccess>
        }
      >
        <ErrorBoundary>
          <Box mb={5}>
            <RoleFilters initialValues={initialFilterValues} onSubmit={updateUrlParams} />
          </Box>
          {noRolesFound && hasActiveFilters && (
            <Heading textAlign="center" my={50}>
              No roles found
            </Heading>
          )}
          <SimpleGrid columns={2} spacing={3}>
            {data.roles.map(role => (
              <RoleItem key={role.id} role={role} />
            ))}
          </SimpleGrid>
        </ErrorBoundary>
      </Panel>
    </Box>
  );
};

export default compose(
  withSEO({ title: 'Roles' }),
  withRoleRestrictedAccess({
    allowedPermissions: [Permission.UserRead, Permission.UserModify],
    fallback: <Page403 />,
  })
)(ListRolesPage);
