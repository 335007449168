/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import useRouter from 'Hooks/useRouter';
import { LocationDescriptor } from 'history';
import { omit } from 'lodash';
import queryString from 'query-string';
import {
  AsyncQueryContext,
  initialState as defaultInitialState,
  reducer,
  Actions,
  State,
} from './AsyncQueryContext';

interface BrowserHistoryAsyncQueryContextProviderProps {
  initialState?: State;
}

export const BrowserHistoryAsyncQueryContextProvider: React.FC<BrowserHistoryAsyncQueryContextProviderProps> = ({
  children,
  initialState = defaultInitialState,
}) => {
  const { history, location } = useRouter<Pick<State, 'queryId'>, State>();

  const initialStateFromUrl: State = React.useMemo(() => {
    const { queryId } = queryString.parse(location.search);
    return queryId ? { ...initialState, queryId: queryId as string } : initialState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = React.useCallback(
    (action: Actions) => {
      const urlParams = queryString.parse(location.search);
      const currentState = location.state;
      const newState = reducer(currentState, action);
      const newLocation: LocationDescriptor<any> = {
        ...location,
        search: queryString.stringify({
          // It's important to remove the `execute` parameter when we begin query
          // execution, otherwise it's possible to spin off into an infinite loop.
          ...omit(urlParams, 'execute'),
          queryId: newState.queryId ?? undefined,
        }),
        state: newState,
      };

      const shouldPushToHistoryStack = action.type === 'QUERY_PROVISIONING';
      if (shouldPushToHistoryStack) {
        history.push(newLocation);
      } else {
        history.replace(newLocation);
      }
    },
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.state, location.search]
  );

  const contextValue = React.useMemo(
    () => ({ state: location.state ?? initialStateFromUrl, dispatch }),
    // FIXME: look into hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.state, dispatch]
  );

  return <AsyncQueryContext.Provider value={contextValue}>{children}</AsyncQueryContext.Provider>;
};

export default BrowserHistoryAsyncQueryContextProvider;
