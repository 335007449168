/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { AbstractButton, Flex, Img, Box } from 'pouncejs';
import { useFormikContext } from 'formik';
import SuccessStatus from 'Assets/statuses/illustration-success.svg';
import useCopyToClipboard from 'Hooks/useCopyToClipboard';
import { useWizardContext, WizardPanel } from 'Components/Wizard';
import { SqsLogSourceIntegration } from 'Generated/schema';
import EventThresholdAlarmConfigurator from 'Components/EventThresholdAlarmConfigurator';
import { FailingPanel, PendingPanel } from 'Components/SourceVerificationPanels';
import FinishSourceSetupActions from 'Pages/Integrations/components/FinishSourceSetupActions';
import { SqsLogSourceWizardValues } from '../SqsSourceWizard';

const ValidationPanel: React.FC = () => {
  const copyToClipboard = useCopyToClipboard();
  const [errorMessage, setErrorMessage] = React.useState('');
  const result = React.useRef<SqsLogSourceIntegration | Error>(null);
  const { currentStepStatus, setCurrentStepStatus, setNavigationAvailability } = useWizardContext();
  const { initialValues, submitForm } = useFormikContext<SqsLogSourceWizardValues>();

  React.useEffect(() => {
    (async () => {
      setNavigationAvailability(false);
      result.current = await (submitForm() as Promise<any>);
      if (result.current instanceof Error) {
        setErrorMessage(result.current.message);
        setCurrentStepStatus('FAILING');
        setNavigationAvailability(true);
      } else {
        setErrorMessage('');
        setCurrentStepStatus('PASSING');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (currentStepStatus === 'PASSING') {
    const sqsLogSourceIntegration = result.current as SqsLogSourceIntegration;
    return (
      <WizardPanel>
        <Flex align="center" direction="column" mx="auto" width={620}>
          <Box width={400}>
            <WizardPanel.Heading
              title={
                initialValues.integrationId
                  ? 'Everything looks good!'
                  : 'An SQS Queue has been created for you!'
              }
              subtitle={
                initialValues.integrationId
                  ? 'Your SQS source was successfully updated'
                  : 'Panther will now automatically process any events you send to this queue'
              }
            />
          </Box>
          <Img
            nativeWidth={157}
            nativeHeight={118}
            alt="Stack deployed successfully"
            src={SuccessStatus}
          />
          <AbstractButton
            my={6}
            p={1}
            color="blue-200"
            fontSize="medium"
            _hover={{ color: 'blue-100' }}
            onClick={() => copyToClipboard(sqsLogSourceIntegration.sqsConfig.queueUrl)}
          >
            Copy SQS Queue URL
          </AbstractButton>
          <EventThresholdAlarmConfigurator logSource={sqsLogSourceIntegration} />
          <WizardPanel.Actions>
            <FinishSourceSetupActions
              integrationId={sqsLogSourceIntegration.integrationId}
              hasSchemas={sqsLogSourceIntegration.logTypes.length > 0}
            />
          </WizardPanel.Actions>
        </Flex>
      </WizardPanel>
    );
  }

  if (currentStepStatus === 'FAILING') {
    return <FailingPanel errorMessage={errorMessage} />;
  }

  return <PendingPanel />;
};

export default ValidationPanel;
