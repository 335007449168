/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Alert, Box, Card, SimpleGrid } from 'pouncejs';
import omit from 'lodash/omit';
import { extractErrorMessage } from 'Helpers/utils';
import { ListAnalysisPackSourcesInput } from 'Generated/schema';
import NoResultsFound from 'Components/NoResultsFound';
import ErrorBoundary from 'Components/ErrorBoundary';
import { TableControlsPagination } from 'Components/utils/TableControls';
import useRequestParamsWithPagination from 'Hooks/useRequestParamsWithPagination';
import { DEFAULT_SMALL_PAGE_SIZE } from 'Source/constants';
import PackSourceCard from 'Components/cards/PackSourceCard';
import { useListAnalysisPackSources } from './graphql/listAnalysisPackSources.generated';
import ListPacksFilters from './ListPackSourcesFilters';
import ListPackSourceSkeleton from './Skeleton';

const ListAnalysisPackSources = () => {
  const { updatePagingParams, requestParams } = useRequestParamsWithPagination<
    ListAnalysisPackSourcesInput
  >();

  const strippedParams = React.useMemo(() => omit(requestParams, ['section', 'sortBy']), [
    requestParams,
  ]);

  const { loading, error, previousData, data = previousData } = useListAnalysisPackSources({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: { ...strippedParams, pageSize: DEFAULT_SMALL_PAGE_SIZE },
    },
  });

  if (loading && !data) {
    return <ListPackSourceSkeleton />;
  }

  if (error) {
    return (
      <Box mb={6}>
        <Alert
          variant="error"
          title="Couldn't load pack sources"
          description={
            extractErrorMessage(error) ||
            'There was an error when performing your request, please contact support@runpanther.io'
          }
        />
      </Box>
    );
  }

  // Get query results while protecting against exceptions
  const packSourceItems = data?.listAnalysisPackSources.packs;
  const pagingData = data?.listAnalysisPackSources.paging;

  return (
    <ErrorBoundary>
      <ListPacksFilters />
      <Card as="section" position="relative" pt={4}>
        <Box position="relative">
          {packSourceItems.length ? (
            <SimpleGrid columns={2} spacing={3}>
              {packSourceItems.map(packSource => (
                <PackSourceCard key={packSource.id} packSource={packSource} />
              ))}
            </SimpleGrid>
          ) : (
            <Box my={8}>
              <NoResultsFound title="No Pack Sources found" />
            </Box>
          )}
        </Box>
      </Card>
      <Box my={5}>
        <TableControlsPagination
          page={pagingData.thisPage}
          totalPages={pagingData.totalPages}
          onPageChange={updatePagingParams}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default ListAnalysisPackSources;
