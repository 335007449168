/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import * as Types from '../../../__generated__/schema';

import { S3LogIntegrationDetails } from '../fragments/S3LogIntegrationDetails.generated';
import { EventbridgeLogIntegrationDetails } from '../fragments/EventbridgeLogIntegrationDetails.generated';
import { OktaLogIntegrationDetails } from '../fragments/OktaLogIntegrationDetails.generated';
import { DuoLogIntegrationDetails } from '../fragments/DuoLogIntegrationDetails.generated';
import { SalesforceLogIntegrationDetails } from '../fragments/SalesforceLogIntegrationDetails.generated';
import { GithubLogIntegrationDetails } from '../fragments/GithubLogIntegrationDetails.generated';
import { ZendeskLogIntegrationDetails } from '../fragments/ZendeskLogIntegrationDetails.generated';
import { GsuiteLogIntegrationDetails } from '../fragments/GsuiteLogIntegrationDetails.generated';
import { BoxLogIntegrationDetails } from '../fragments/BoxLogIntegrationDetails.generated';
import { SlackLogIntegrationDetails } from '../fragments/SlackLogIntegrationDetails.generated';
import { CrowdstrikeLogIntegrationDetails } from '../fragments/CrowdstrikeLogIntegrationDetails.generated';
import { M365LogIntegrationDetails } from '../fragments/M365LogIntegrationDetails.generated';
import { OnePasswordLogIntegrationDetails } from '../fragments/OnePasswordLogIntegrationDetails.generated';
import { ZoomLogIntegrationDetails } from '../fragments/ZoomLogIntegrationDetails.generated';
import { AtlassianLogIntegrationDetails } from '../fragments/AtlassianLogIntegrationDetails.generated';
import { AsanaLogIntegrationDetails } from '../fragments/AsanaLogIntegrationDetails.generated';
import { WorkdayLogIntegrationDetails } from '../fragments/WorkdayLogIntegrationDetails.generated';
import { SnykLogIntegrationDetails } from '../fragments/SnykLogIntegrationDetails.generated';
import { SqsLogSourceIntegrationDetails } from '../fragments/SqsLogSourceIntegrationDetails.generated';
import { CloudWatchLogIntegrationDetails } from '../fragments/CloudwatchLogIntegrationDetails.generated';
import { GcsLogSourceIntegrationDetails } from '../fragments/GcsLogIntegrationDetails.generated';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type GetLogIntegrationVariables = {
  id: Types.Scalars['ID'];
};

export type GetLogIntegration = {
  getLogIntegration:
    | S3LogIntegrationDetails
    | CloudWatchLogIntegrationDetails
    | EventbridgeLogIntegrationDetails
    | SqsLogSourceIntegrationDetails
    | GcsLogSourceIntegrationDetails
    | (OktaLogIntegrationDetails &
        DuoLogIntegrationDetails &
        SalesforceLogIntegrationDetails &
        GithubLogIntegrationDetails &
        ZendeskLogIntegrationDetails &
        GsuiteLogIntegrationDetails &
        BoxLogIntegrationDetails &
        SlackLogIntegrationDetails &
        CrowdstrikeLogIntegrationDetails &
        M365LogIntegrationDetails &
        OnePasswordLogIntegrationDetails &
        ZoomLogIntegrationDetails &
        AtlassianLogIntegrationDetails &
        AsanaLogIntegrationDetails &
        WorkdayLogIntegrationDetails &
        SnykLogIntegrationDetails);
};

export const GetLogIntegrationDocument = gql`
  query GetLogIntegration($id: ID!) {
    getLogIntegration(id: $id) {
      ... on S3LogIntegration {
        ...S3LogIntegrationDetails
      }
      ... on EventBridgeIntegration {
        ...EventbridgeLogIntegrationDetails
      }
      ... on LogPullingIntegration {
        ...OktaLogIntegrationDetails
        ...DuoLogIntegrationDetails
        ...SalesforceLogIntegrationDetails
        ...GithubLogIntegrationDetails
        ...ZendeskLogIntegrationDetails
        ...GsuiteLogIntegrationDetails
        ...BoxLogIntegrationDetails
        ...SlackLogIntegrationDetails
        ...CrowdstrikeLogIntegrationDetails
        ...M365LogIntegrationDetails
        ...OnePasswordLogIntegrationDetails
        ...ZoomLogIntegrationDetails
        ...AtlassianLogIntegrationDetails
        ...AsanaLogIntegrationDetails
        ...WorkdayLogIntegrationDetails
        ...SnykLogIntegrationDetails
      }
      ... on SqsLogSourceIntegration {
        ...SqsLogSourceIntegrationDetails
      }
      ... on CloudWatchLogIntegration {
        ...CloudWatchLogIntegrationDetails
      }
      ... on GcsLogSourceIntegration {
        ...GcsLogSourceIntegrationDetails
      }
    }
  }
  ${S3LogIntegrationDetails}
  ${EventbridgeLogIntegrationDetails}
  ${OktaLogIntegrationDetails}
  ${DuoLogIntegrationDetails}
  ${SalesforceLogIntegrationDetails}
  ${GithubLogIntegrationDetails}
  ${ZendeskLogIntegrationDetails}
  ${GsuiteLogIntegrationDetails}
  ${BoxLogIntegrationDetails}
  ${SlackLogIntegrationDetails}
  ${CrowdstrikeLogIntegrationDetails}
  ${M365LogIntegrationDetails}
  ${OnePasswordLogIntegrationDetails}
  ${ZoomLogIntegrationDetails}
  ${AtlassianLogIntegrationDetails}
  ${AsanaLogIntegrationDetails}
  ${WorkdayLogIntegrationDetails}
  ${SnykLogIntegrationDetails}
  ${SqsLogSourceIntegrationDetails}
  ${CloudWatchLogIntegrationDetails}
  ${GcsLogSourceIntegrationDetails}
`;

/**
 * __useGetLogIntegration__
 *
 * To run a query within a React component, call `useGetLogIntegration` and pass it any options that fit your needs.
 * When your component renders, `useGetLogIntegration` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLogIntegration({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLogIntegration(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetLogIntegration, GetLogIntegrationVariables>
) {
  return ApolloReactHooks.useQuery<GetLogIntegration, GetLogIntegrationVariables>(
    GetLogIntegrationDocument,
    baseOptions
  );
}
export function useGetLogIntegrationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLogIntegration, GetLogIntegrationVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetLogIntegration, GetLogIntegrationVariables>(
    GetLogIntegrationDocument,
    baseOptions
  );
}
export type GetLogIntegrationHookResult = ReturnType<typeof useGetLogIntegration>;
export type GetLogIntegrationLazyQueryHookResult = ReturnType<typeof useGetLogIntegrationLazyQuery>;
export type GetLogIntegrationQueryResult = ApolloReactCommon.QueryResult<
  GetLogIntegration,
  GetLogIntegrationVariables
>;
export function mockGetLogIntegration({
  data,
  variables,
  errors,
}: {
  data: GetLogIntegration;
  variables?: GetLogIntegrationVariables;
  errors?: GraphQLError[];
}) {
  return {
    request: { query: GetLogIntegrationDocument, variables },
    result: { data, errors },
  };
}
