/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

/*
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSelect } from 'Components/utils/SelectContext';
import useRequestParamsWithoutPagination from 'Hooks/useRequestParamsWithoutPagination';
import { AlertListingType, AlertsInput, AlertType } from 'Generated/schema';
import { ListAlertsFiltersKeys } from 'Pages/ListAlerts/ListAlertFilters/ListAlertFilters';
import ListAlertSearchUpdater from './ListAlertSearchUpdater';
import ListAlertDefaultUpdater from './ListAlertDefaultUpdater';

import ListAlertFilters from '../ListAlertFilters';

interface ListAlertsActionsProps {
  updater:
    | React.ReactElement<typeof ListAlertDefaultUpdater>
    | React.ReactElement<typeof ListAlertSearchUpdater>;
}

const ListAlertsActions: React.FC<ListAlertsActionsProps> = ({ updater }) => {
  const { selection } = useSelect();
  const { requestParams } = useRequestParamsWithoutPagination<AlertsInput>();

  const excludedFields: ListAlertsFiltersKeys[] = ['types'];
  if (
    !requestParams.types?.includes(AlertType.SystemError) &&
    requestParams.type !== AlertListingType.SystemError
  ) {
    excludedFields.push('logSources');
  }

  return selection.length ? updater : <ListAlertFilters excludedFields={excludedFields} />;
};

export default React.memo(ListAlertsActions);
