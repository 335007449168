/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { useSourceMetrics } from 'Source/graphql/queries/GetSourceMetrics.generated';
import { SourceMetricsInput } from 'Generated/schema';

const useCalculatedSourceMetrics = ({
  integrationId,
  filters,
}: {
  integrationId: string;
  filters: Pick<SourceMetricsInput, 'fromDate' | 'toDate' | 'intervalMinutes'>;
}) => {
  const { loading, previousData, data = previousData, error } = useSourceMetrics({
    variables: { input: { integrationId, ...filters } },
  });

  const { bytesProcessedWithDimensions, eventsProcessedWithDimensions } = data?.sourceMetrics || {};

  const totalBytes = React.useMemo(() => {
    if (!bytesProcessedWithDimensions) {
      return 0;
    }
    return bytesProcessedWithDimensions.series.reduce((acc, current) => {
      const addValues = current.values.reduce((p, c) => p + c, 0);
      return acc + addValues;
    }, 0);
  }, [bytesProcessedWithDimensions]);

  const totalEvents = React.useMemo(() => {
    if (!eventsProcessedWithDimensions) {
      return 0;
    }
    return eventsProcessedWithDimensions.series.reduce((acc, current) => {
      const addValues = current.values.reduce((p, c) => p + c, 0);
      return acc + addValues;
    }, 0);
  }, [eventsProcessedWithDimensions]);

  return React.useMemo(
    () => ({
      totalBytes,
      totalEvents,
      bytesProcessed: bytesProcessedWithDimensions,
      eventsProcessed: eventsProcessedWithDimensions,
      hasPreviousData: Boolean(previousData),
      loading,
      error,
    }),
    [
      bytesProcessedWithDimensions,
      error,
      eventsProcessedWithDimensions,
      loading,
      previousData,
      totalBytes,
      totalEvents,
    ]
  );
};

export default useCalculatedSourceMetrics;
