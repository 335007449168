/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import {
  AWS_ACCOUNT_ID_REGEX,
  LogTransportMethodsEnum,
  ROLE_ARN_REGEX,
  S3_BUCKET_NAME_REGEX,
} from 'Source/constants';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Wizard } from 'Components/Wizard';
import {
  getArnRegexForService,
  yupIntegrationLabelValidation,
  yupS3PrefixLogTypesValidation,
} from 'Helpers/utils';
import { S3LogIntegrationDetails } from 'Source/graphql/fragments/S3LogIntegrationDetails.generated';
import { LogStreamTypeEnum, S3PrefixLogTypes } from 'Generated/schema';
import useFindSourcesPerMethod from 'Hooks/useFindSourcesPerMethod';
import SelectIAMRoleCreationMethodPanel, {
  IAMRoleCreationMethods,
} from 'Components/wizards/common/SelectIAMRoleCreationMethodPanel';
import CancelIntegrationAction from 'Pages/Integrations/components/CancelIntegrationAction';
import { ConsoleMethod, CustomMethod, TemplateMethod } from './IamRoleCreationMethods';
import ConfigureNotificationsPanel from './ConfigureNotificationsPanel';
import S3SourceConfigurationPanel from './S3SourceConfigurationPanel';
import ValidationPanel from './ValidationPanel';

interface S3LogSourceWizardProps {
  initialValues: S3LogSourceWizardValues;
  availableLogTypes: string[];
  onSubmit: (values: S3LogSourceWizardValues) => Promise<S3LogIntegrationDetails | Error>;
}

export interface S3LogSourceWizardValues {
  // for updates
  integrationId?: string;
  initialStackName?: string;
  // common for creation + updates
  awsAccountId: string;
  integrationLabel: string;
  s3Bucket: string;
  kmsKey: string;
  s3PrefixLogTypes: Omit<S3PrefixLogTypes, '__typename'>[];
  managedBucketNotifications: boolean;
  logProcessingRole: string;
  logStreamType: LogStreamTypeEnum;
}

const methodComponents: Record<IAMRoleCreationMethods, React.ElementType> = {
  console: ConsoleMethod,
  custom: CustomMethod,
  template: TemplateMethod,
};

const getValidationSchema: ({
  initialIntegrationLabel,
  existingS3Sources,
}: {
  initialIntegrationLabel: string;
  existingS3Sources: S3LogIntegrationDetails[];
}) => Yup.SchemaOf<S3LogSourceWizardValues> = ({ initialIntegrationLabel, existingS3Sources }) =>
  Yup.object().shape({
    integrationId: Yup.string(),
    initialStackName: Yup.string(),
    integrationLabel: yupIntegrationLabelValidation.test(
      'mutex',
      'You already have a source with the same label for this AWS account id',
      // eslint-disable-next-line func-names
      function (newIntegrationLabel) {
        if (!this.parent.awsAccountId) {
          return true;
        }
        return !existingS3Sources?.find(
          ({ awsAccountId, integrationLabel }) =>
            // We check if there another S3 with AWS accountId and integrationLabel
            awsAccountId === this.parent.awsAccountId &&
            // but checking if the new label is different from the initial value for the edit flow
            newIntegrationLabel !== initialIntegrationLabel &&
            integrationLabel === newIntegrationLabel
        );
      }
    ),
    awsAccountId: Yup.string()
      .matches(AWS_ACCOUNT_ID_REGEX, 'Must be a valid AWS Account ID')
      .required(),
    s3Bucket: Yup.string().matches(S3_BUCKET_NAME_REGEX, 'Must be valid S3 Bucket name').required(),
    s3PrefixLogTypes: yupS3PrefixLogTypesValidation(),
    kmsKey: Yup.string().matches(getArnRegexForService('KMS'), 'Must be a valid KMS ARN'),
    managedBucketNotifications: Yup.boolean().required(),
    logProcessingRole: Yup.string()
      .matches(
        ROLE_ARN_REGEX,
        'Must be a valid IAM ARN i.e. arn:aws:iam::{ACCOUNT_ID}:role/{ROLENAME}'
      )
      .required('Please fill in the Role ARN to continue with the source setup'),
    logStreamType: Yup.string().oneOf(Object.values(LogStreamTypeEnum)),
  });

const S3LogSourceWizard: React.FC<S3LogSourceWizardProps> = ({
  initialValues,
  onSubmit,
  availableLogTypes,
}) => {
  const editMode = !!initialValues.integrationId;

  const [selectedMethod, setSelectedMethod] = React.useState<IAMRoleCreationMethods>('template');
  const formStatus = React.useMemo(() => ({ availableLogTypes }), [availableLogTypes]);

  const existingS3Sources = useFindSourcesPerMethod(
    LogTransportMethodsEnum.s3
  ) as S3LogIntegrationDetails[];

  const mergedValidationSchema = getValidationSchema({
    initialIntegrationLabel: initialValues.integrationLabel,
    existingS3Sources,
  });
  const SelectedMethod = methodComponents[selectedMethod] || ConsoleMethod;

  return (
    <Formik<S3LogSourceWizardValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={mergedValidationSchema}
      onSubmit={onSubmit}
      initialStatus={formStatus}
    >
      <Form>
        <Wizard headerTitle="Connecting a new Log Source" enableAllNavigationSteps={editMode}>
          <Wizard.Step
            title={editMode ? 'Edit Source Details' : 'Setup your Source'}
            description={
              editMode
                ? 'Edit account information and preferences for this log source'
                : 'Provide account information and set preferences to connect the log source to Panther'
            }
          >
            <S3SourceConfigurationPanel />
          </Wizard.Step>
          <Wizard.StepGroup
            title={editMode ? 'Edit IAM Role' : 'Setup IAM Role'}
            description={
              editMode
                ? 'Edit the IAM role in your account to give Panther the right permissions to collect logs from the source'
                : 'Create or use an existing IAM role in your account to give Panther the right permissions to collect logs from the source'
            }
          >
            <Wizard.Step title="Choose Method">
              <SelectIAMRoleCreationMethodPanel onSelectMethod={setSelectedMethod} />
            </Wizard.Step>
            <Wizard.Step title={editMode ? 'Edit Role' : 'Finalize Role Setup'}>
              <SelectedMethod />
            </Wizard.Step>
          </Wizard.StepGroup>
          {selectedMethod === 'custom' && (
            <Wizard.Step
              title="Configure Notifications"
              description="Grant Panther permissions to read from your S3 bucket"
            >
              <ConfigureNotificationsPanel />
            </Wizard.Step>
          )}
          <Wizard.Step
            title="Setup Verification"
            description="Confirm that your log source has been successfully onboarded or troubleshoot any issues"
            navigationDisabled={editMode}
          >
            <ValidationPanel />
          </Wizard.Step>
        </Wizard>
        {!editMode && <CancelIntegrationAction />}
      </Form>
    </Formik>
  );
};

export default S3LogSourceWizard;
