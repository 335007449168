/**
 * Copyright (C) 2022 Panther Labs Inc
 *
 * Panther Enterprise is licensed under the terms of a commercial license available from
 * Panther Labs Inc ("Panther Commercial License") by contacting contact@runpanther.com.
 * All use, distribution, and/or modification of this software, whether commercial or non-commercial,
 * falls under the Panther Commercial License to the extent it is permitted.
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Flex, Box } from 'pouncejs';
import FormikDateRangeInput from 'Components/fields/DateRangeInput';
import FormikCombobox from 'Components/fields/ComboBox';
import FormikAutosave from 'Components/utils/Autosave';
import { minutesToString } from 'Helpers/utils';
import { useMetricsFiltersContext, MetricsFilters } from 'Components/utils/MetricsFiltersContext';

const intervalMinutesOptions = [15, 30, 60, 180, 720, 1440];

const AlertsOverviewFilters = () => {
  const { filters, setFilters } = useMetricsFiltersContext();
  return (
    <Flex
      justify="flex-end"
      position="absolute"
      right={0}
      pb={6}
      zIndex={10}
      transform="translateY(-100%)"
    >
      <Formik<MetricsFilters> initialValues={filters} onSubmit={setFilters}>
        <Form>
          <FormikAutosave threshold={50} />
          <Flex spacing={4} maxWidth={500}>
            <Box maxWidth={100}>
              <Field
                data-tid="alerts-overview-interval"
                as={FormikCombobox}
                variant="solid"
                label="Interval"
                name="intervalMinutes"
                items={intervalMinutesOptions}
                itemToString={minutesToString}
              />
            </Box>
            <FormikDateRangeInput
              alignment="right"
              withPresets
              withTime
              disableReset
              variant="solid"
              format="MM/DD/YYYY HH:mm"
              labelStart="Date Start"
              labelEnd="Date End"
              placeholderStart="MM/DD/YY HH:mm"
              placeholderEnd="MM/DD/YY HH:mm"
              nameStart="fromDate"
              nameEnd="toDate"
            />
          </Flex>
        </Form>
      </Formik>
    </Flex>
  );
};

export default React.memo(AlertsOverviewFilters);
